import { useEffect, useRef } from 'react'

/**
 * Hook to close listener
 * @author ehernandez
 * @param {funtion} onClose function to be called when the listener is closed
 * @param {boolean} esc if true, close on esc
 * @returns {array}
 */
const useListenerClose = (onClose, esc) => {
  const ref = useRef()

  /**
   * when the listener is closed, call the onClose function
   */
  const toggle = () => {
    onClose && onClose()
  }

  /**
   * when click outside the listener, call the onClose function
   * @author ehernandez
   * @param {event} e event target
   */
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      toggle(e)
    }
  }
  /**
   * when press esc, call the onClose function
   * @author ehernandez
   * @param {event} e event target
   */
  const handleKey = (e) => {
    if (esc && e.key === 'Escape') {
      toggle(e)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, { passive: true })
    if (esc) {
      document.addEventListener('keydown', handleKey, { passive: true })
    }

    return () => {
      document.removeEventListener('mousedown', handleClick, { passive: true })
      if (esc) {
        document.removeEventListener('keydown', handleKey, { passive: true })
      }
    }
  }, [])

  return [ref, toggle]
}

export default useListenerClose
