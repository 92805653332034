import { SET_COOKIES } from '../constants/cookies'

const initialState = {
  cookies: false
}

/**
 * @author ehernandez
 * @param {object} state state of the application
 * @param {object} action action to be executed
 * @returns {object}
 */
const cookiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COOKIES:
      return {
        cookies: true
      }

    default:
      return state
  }
}

export default cookiesReducer
