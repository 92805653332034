import React from 'react'
import Paragraphs from '../Paragraphs'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import IconArrow from '../../infrastructure/assets/icons/arrow-icon-breadcrumb.png'

const Breadcrumb = ({ links }) => {
  if (Array.isArray(links) && links.length > 1) {
    return <nav className="tw-rounded-md md:tw-mt-0 tw-mt-5 tw-mb-5">
      <ol className="tw-list-reset tw-flex tw-flex-wrap tw-max-w-[1560px] tw-mx-auto tw-px-7">
        {links?.map((i, index) => (
          <div className='tw-flex' key={index}>
            <Link to={`${i.href}`}><li>
              <div className="tw-text-gray-500 tw-cursor-pointer">
                <Paragraphs
                  className={'breadcrump__btn tw-text-left'}
                  weight="bold"
                  size="xxs"
                  transform='uppercase'
                >
                  {i.name}
                </Paragraphs>
              </div>
             </li>
            </Link>
            {index < links.length - 1 &&
              (
              <li className='tw-flex tw-px-2 tw-items-center'>
                  <img src={IconArrow} />
              </li>
              )}
          </div>
        ))}
      </ol>
    </nav>
  }
  return (
    null
  )
}

Breadcrumb.propTypes = {
  links: PropTypes.array,
  search: PropTypes.string
}

Breadcrumb.defaultProps = {
  links: []
}

export default Breadcrumb
