import React from 'react'
import IconLink from '../IconLink/IconLink'
import Logo from '../Logo'
import Nav from '../Nav'
import Paragraphs from '../Paragraphs'
import PropTypes from 'prop-types'
import pkg from '../../../package.json'
import { useNavigate } from 'react-router-dom'

const links = [
  {
    id: 1,
    label: 'Aviso Legal y Términos de uso',
    href: '/aviso-legal-y-terminos-de-uso'
  },
  {
    id: 2,
    label: 'Política de Privacidad',
    href: '/politica-de-privacidad'
  },
  {
    id: 3,
    label: 'Política de Cookies',
    href: '/politica-de-cookies'
  }
]

/**
 * @author ehernandez
 */
const Footer = ({ image }) => {
  const navigate = useNavigate()

  const onClickLink = (i) => {
    navigate(i)
  }

  return (
    <div className="footer tw-relative tw-bg-tertiary tw-p-8 tw-text-white ">
      <div className= " tw-justify-between tw-mx-auto tw-max-w-[1560px]">
      <div className="footer__descktop tw-flex tw-w-full">
      <Nav onClick={onClickLink} colorHover={'primary'} color="white" links={links} className="tw-w-full">
        <Logo image={image}/>
      </Nav>
      </div>
      <div className="md:tw-flex tw-items-center tw-justify-between tw-py-4">
        <div className="tw-flex tw-gap-x-6">
          <IconLink icon='icon-ic_footer_mail' text="info@repofarma.com" href='mailto:info@repofarma.com' />
          <IconLink icon='icon-ic_footer_telefono' href='tel:34699699699' text="+34 963440009" />
        </div>

        <div className=" tw-pt-4 md:tw-pt-0">
          <Paragraphs size="xxs" className="tw-opacity-60 hover:tw-opacity-100">
            © Copyright 2022 Repofarma S.L.
          </Paragraphs>
        </div>
      </div>
      <div className='version tw-absolute tw-bottom-4 tw-right-10 tw-z-30'>
      <Paragraphs size='xxs' className='tw-text-white'>{pkg.version}</Paragraphs>
        </div>
        </div>
    </div>
  )
}

Footer.propTypes = {
  image: PropTypes.string
}

export default Footer
