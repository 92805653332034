import React from 'react'
import PropTypes from 'prop-types'
import useInput from './hooks/useInput'

/**
 * Input component DO NOT PASS CHILDREN PROPS TO THIS COMPONENT.
 * @author @jstarsky
 * @param {*} props
 * @return {JSX.Element} .
 */
const Input = React.forwardRef(
  (
    {
      test,
      autoFocus,
      disabled,
      name,
      onBlur,
      onChange,
      placeholder,
      register,
      fontSize,
      type,
      alignment,
      onFocus
    },
    ref
  ) => {
    const { internalClassName } = useInput({ alignment, fontSize, type })
    return (
      <input
        data-testid={test ? `${test.replace('.', '_')}` : undefined}
        ref={ref}
        onFocus={onFocus}
        autoFocus={autoFocus}
        className={internalClassName}
        disabled={disabled}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        {...register}
      />
    )
  }
)
Input.propTypes = {
  alignment: PropTypes.oneOf(['center', 'end', 'start']),
  test: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  register: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  required: PropTypes.bool,
  type: PropTypes.oneOf(['email', 'password', 'tel', 'text']),
  fontSize: PropTypes.oneOf(['xs', 'xl'])
}
Input.displayName = 'textinput-input'
export default Input
