import React from 'react'
import PropTypes from 'prop-types'

/**
 * @author ehernandez
 * @returns {React.Component}
 */
const Body = ({ className, children, reference }) => {
  return (
    <div ref={reference} className={`tw-p-4 ${className} tw-flex-1`}>
      {children}
    </div>
  )
}

Body.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  reference: PropTypes.any
}

export default Body
