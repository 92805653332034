import logo1 from '../assets/images/logosPartner/logo1.png'
import logo2 from '../assets/images/logosPartner/logo2.png'
import logo3 from '../assets/images/logosPartner/logo3.png'
import logo5 from '../assets/images/logosPartner/logo5.png'
import logo6 from '../assets/images/logosPartner/logo6.png'
import logo8 from '../assets/images/logosPartner/logo8.png'
import logo9 from '../assets/images/logosPartner/logo9.png'
import logo10 from '../assets/images/logosPartner/logo10.png'
import logo11 from '../assets/images/logosPartner/logo11.png'
import logo12 from '../assets/images/logosPartner/logo12.png'
import logo13 from '../assets/images/logosPartner/logo13.png'
import logo14 from '../assets/images/logosPartner/logo14.png'
import logo15 from '../assets/images/logosPartner/logo15.png'
import logo16 from '../assets/images/logosPartner/logo16.png'
import logo17 from '../assets/images/logosPartner/logo17.png'
import logo18 from '../assets/images/logosPartner/logo18.png'
import logo19 from '../assets/images/logosPartner/logo19.png'
import logo20 from '../assets/images/logosPartner/logo20.png'

export const LOGOS_PARTNERS = [

  {
    id: 1,
    href: '',
    logo: logo1
  },
  {
    id: 2,
    href: '',
    logo: logo2
  },
  {
    id: 3,
    href: '',
    logo: logo3
  },
  {
    id: 5,
    href: '',
    logo: logo5
  },
  {
    id: 6,
    href: '',
    logo: logo6
  },
  {
    id: 8,
    href: '',
    logo: logo8
  },
  {
    id: 9,
    href: '',
    logo: logo9
  },
  {
    id: 10,
    href: '',
    logo: logo10
  },
  {
    id: 11,
    href: '',
    logo: logo11
  },
  {
    id: 12,
    href: '',
    logo: logo12
  },
  {
    id: 13,
    href: '',
    logo: logo13
  },
  {
    id: 14,
    href: '',
    logo: logo14
  },
  {
    id: 15,
    href: '',
    logo: logo15
  },
  {
    id: 16,
    href: '',
    logo: logo16
  },
  {
    id: 17,
    href: '',
    logo: logo17
  },
  {
    id: 18,
    href: '',
    logo: logo18
  },
  {
    id: 19,
    href: '',
    logo: logo19
  },
  {
    id: 20,
    href: '',
    logo: logo20
  }

]
