import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs'

const FormacionCard = ({ image, author, title, body, location, start_date: startDate }) => {
  return (
    <main
    className="
    tw-flex-col
    md:tw-flex-row
    lg:tw-flex-row
    xl:tw-flex-row
    formacion-card
    tw-flex
    tw-rounded-[6px]
    tw-cursor-pointer
    hover:-tw-translate-y-1
    tw-shadow-lg
    tw-transition
    tw-duration-150
    tw-ease-out
    tw-gap-6
    tw-min-h-[250px]
    tw-p-6
    hover:ease-in">
      <div className="tw-flex tw-w-auto tw-p-1  tw-border lg:tw-w-[200px] md:tw-w-[200px]  tw-items-center  tw-rounded-[6px] tw-justify-center">
        <img className="tw-rounded-md tw-max-w-full" src={image} alt={title} />
      </div>
      <section className="tw-flex tw-flex-1 tw-justify-between tw-flex-col tw-py-2">
        <div className="tw-flex tw-flex-col tw-gap-y-2">
          <Paragraphs
            className={'tw-text-left tw-text-primary'}
            weight="bold"
            size="sm"
            transform="uppercase"
          >
            {author}
          </Paragraphs>
          <Paragraphs
            className={'tw-text-left tw-flex-1'}
            weight="bold"
            size="sm"
            transform="uppercase"
          >
            {title}
          </Paragraphs>
          <p
            className={'tw-text-left tw-text-sm'}
            dangerouslySetInnerHTML={{ __html: body }}
          >
          </p>
          </div>
        <div className="tw-flex tw-gap-4">
          <div className='tw-flex tw-items-center tw-gap-1'>
        <span className='icon-ic_ubicacion tw-text-primary' style={{ fontSize: 20 }} />
          <Paragraphs className={'tw-text-left tw-text-primary'} weight="light" size="xs">
            {location}
          </Paragraphs>
          </div>
         {startDate && <div className='tw-flex tw-items-center tw-gap-2'>
          <span className='icon-ic_calendario tw-text-primary ' style={{ fontSize: 20 }} />
          <Paragraphs className={'tw-text-left tw-text-primary'} weight="light" size="xs">
            {startDate}
          </Paragraphs>
          </div>}
        </div>
      </section>
    </main>
  )
}

FormacionCard.propTypes = {
  image: PropTypes.string,
  author: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  location: PropTypes.string,
  start_date: PropTypes.string
}

export default FormacionCard
