export const TEXTS = {
  login: {
    title: '¡Bienvenido a farmapolis!',
    subtitle:
      'Inicia tu sesión o regístrate para acceder sin límites a todo el contenido disponible.',
    button: 'Iniciar sesión',
    farmacloudButton: 'Entrar con Farmacloud',
    link: '¿No tienes un usuario todavia? Registrate'
  },
  register: {
    title: 'Registro de usuario',
    subtitle: '',
    button: 'Finalizar el registro',
    link: '¿Ya estás registrado? Inicia tu sesión'
  }
}
