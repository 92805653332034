import React, { useEffect, useState } from 'react'
import Page from '../../components/Page/Page'
import { useNotices } from '../../infrastructure/hooks/useNoticies/useNoticies'
import { useUser } from '../../infrastructure/hooks/useUser/useUser'
import BlockNews from './components/BlockNews'
import SectionWelcome from '../../components/Homev5/SectionWelcome'
import SectionDestacados from '../../components/Homev5/SectionDestacados'
// import SectionPartners from '../../components/Homev5/SectionPartners/SectionPartners'
// import { ContainerPartners } from './style'
import BgDots from '../../infrastructure/assets/images/home/background-dots-2.png'
import styled from 'styled-components'
// import { useTheme } from '../../infrastructure/context/ThemeContext/ThemeContext'
import Cookies from 'universal-cookie'
import { FARMACLOUD_URL } from '../../infrastructure/constants/configurations'
import Modal from '../../components/Modal/Modal'
import VectorPopUp from '../../infrastructure/assets/images/home/vector-popup.png'
import VectorHome from '../../infrastructure/assets/images/home/v-vector.png'
import LogoFarma from '../../infrastructure/assets/images/logos/ic_farmapolis_color.svg'
import PopUp from '../../components/Homev5/components/PopUp'

export const BgroundDots = styled.div`
  background-image: url(${BgDots});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`
/**
 * @author cperez
 * @description new design
 * @returns {React.Component}
 */
const HomeScreen = (props) => {
  const [showPopup, setShowPopup] = React.useState(false)

  const { token } = useUser()
  // const { theme } = useTheme()
  const cookies = new Cookies()
  const [currentCookie] = useState(cookies.get('avoid_sso_1'))

  const { notices, getNotices } = useNotices()

  useEffect(() => {
    const redirect = () => {
      setTimeout(() => {
        // eliminar variable currentUrl y condiciones cuando se despliegue en produccion
        const currentUrl = window.location.href
        if (currentUrl?.includes('localhost') || currentUrl?.includes('dev.farmapolis.es')) {
          location.href = window.location.href
          window.location.href = FARMACLOUD_URL + 'referrer' // no activar hasta que se tenga el dominio
        }
      }, 10)
    }
    if (token) {
      getNotices(8)
      return
    }
    setTimeout(() => {
      cookies.remove('avoid_sso_1', { path: '/' })
    }, 500)
    if (currentCookie === 'true') return
    cookies.set('avoid_sso_1', true, { path: '/' })
    redirect()
  }, [token])

  return (
    <Page.Container id="home-screen" name="home-screen">
        <div id='home-welcome'>
           <SectionWelcome/>
        </div>
        <div id='home-destacados' className='tw-mb-10'>
           <SectionDestacados/>
        </div>
        {token && notices?.items.length > 0 && <div className="home__block_new tw-relative tw-mt-[230px]  tw-w-full tw-bg-dots tw-pb-28 tw-mx-auto tw-px-2 tw-py-8" cy="BlockNews">
          <BlockNews data={notices} />
          <BgroundDots className=' tw-absolute tw-left-0 tw-h-[300px] -tw-top-[260px] -tw-z-20 tw-flex tw-w-full' />
        </div>}
        {/* {!theme?.is_lab &&
        <ContainerPartners className='tw-flex tw-h-[550px] tw-gap-20  tw-max-w-[90%] tw-items-center tw-mx-auto tw-flex-col tw-justify-center'>
           <SectionPartners id='slider-home-partners'/>
        </ContainerPartners>} */}
        {showPopup && !token &&
          <Modal onRequestClose={() => setShowPopup(false)}>
            <PopUp
              onClose={() => setShowPopup(false)}
              logoBackground= {VectorHome}
              logoFarma = {LogoFarma}
              vector = {VectorPopUp}
            />
          </Modal>
          }

    </Page.Container>
  )
}

export default HomeScreen
