module.exports = {

  alert: '#D8004D',
  primary: '#FF274C',
  secondary: '#F0F0F0',
  tertiary: '#2C2C2C',
  black: '#2C2C2C',
  border: '#E8E8E8',
  input: '#F7F7F7',
  white: '#FFFFFF',
  dots: '#EFEFEF',
  premios:'#04758d'
}
