import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../Modal/Modal'
import Paragraphs from '../Paragraphs'
import SliderImages from '../SliderImages'

const PreviewImage = ({ cn, name, images, onRequestClose, type }) => {
  return (
    <Modal
      onRequestClose={onRequestClose}
      title={
        <>
          <Paragraphs
            size="xs"
            weight="bold"
            className="tw-text-primary tw-inline tw-mr-1"
          >
            {cn}
          </Paragraphs>
          <Paragraphs size="xs" weight="bold" className="tw-inline">
            {name}
          </Paragraphs>
        </>
      }
      id="preview-image"
    >
      {
        type === 'video'
          ? <iframe width="560" height="315" src={images[0]} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

          : <SliderImages
        className="tw-max-w-[400px] tw-max-h-[500px] tw-relative"
        images={images}
      />
    }
    </Modal>
  )
}

PreviewImage.propTypes = {
  cn: PropTypes.string,
  name: PropTypes.string,
  images: PropTypes.array,
  visible: PropTypes.bool,
  onRequestClose: PropTypes.func,
  type: PropTypes.string
}

export default PreviewImage
