/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import Content from '../Content'
import EcommerceCardItem from '../EcommerceCardItem/EcommerceCardItem'
import Paragraphs from '../Paragraphs'
import { format } from 'date-fns'

const EcommerceCard = ({
  isEcommerce, extra_data_last_updated, extra_data_format_image,
  logo_url: logoUrl, items, extra_data_products_count, onSelected, onClickDownload
}) => {
  return (

    <Content
      transition
      className='ecommerce-card tw-w-full tw-max-w-full tw-p-6 tw-flex tw-flex-col tw-justify-between tw-relative tw-h-full'
    >
      <div className={'ecommerce-card__img tw-flex tw-justify-center tw-items-center tw-h-[100px]  tw-rounded-[6px]'}>
        <img width={130} src={logoUrl} alt="ecommerce" className="" />
      </div>
      {items && (
        <>
        <div className="ecommerce-card__content tw-flex tw-flex-col tw-overflow-auto tw-max-h-[180px] tw-justify-center ">
            {items.length > 0
              ? items?.map((item, index) => (
                <EcommerceCardItem onClickDownload={() => onClickDownload(item.id)} onSelected={onSelected} key={index} {...item} />
              ))

              : <Paragraphs className={'tw-text-slate-500 tw-text-center'} >No se encontraron documentos</Paragraphs> }
          </div>
           {isEcommerce && <div className='tw-mt-2'>
              <Paragraphs weight={'regular'} size={'xs'}>{`Nº productos: ${extra_data_products_count}`}</Paragraphs>
              {extra_data_format_image && <Paragraphs weight={'regular'} size={'xs'}>{`Formato imágenes: ${extra_data_format_image}`}</Paragraphs> }
              {extra_data_last_updated && <Paragraphs weight={'regular'} size={'xs'}>{`Última actualización: ${format(new Date(extra_data_last_updated), 'dd/MM/yyyy')} `}</Paragraphs>}
            </div>}
        </>
      )}
    </Content>

  )
}

EcommerceCard.propTypes = {
  isEcommerce: PropTypes.bool,
  logo_url: PropTypes.string,
  items: PropTypes.array,
  extra_data_products_count: PropTypes.number,
  onSelected: PropTypes.func,
  onClickDownload: PropTypes.func,
  extra_data_last_updated: PropTypes.string,
  extra_data_format_image: PropTypes.string
}

export default EcommerceCard
