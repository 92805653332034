import styled from 'styled-components'
import {
  SIZE_HEADER,
  SIZE_NAV
} from '../../../../infrastructure/components/Layout/Layout'

// check over workflow --> HEIGHT_FOOTER_PAGE <-> HEIGH_HEADER

export const ContentContainer = styled.div`
  min-height: calc(100vh - ${SIZE_HEADER}px - ${SIZE_NAV}px);
`
