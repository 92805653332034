import React from 'react'
import PropTypes from 'prop-types'
import { ContentContainer } from './styles'
import { useLocation } from 'react-router-dom'
import Page from '../../Page'

/**
 * @author ehernandez
 * @returns {React.Component}
 */
const Container = ({ id, name, className, children, title, subTitle, opacity, type, description, bgLine, urlLogo, novedades, removeContainer, textEcommerce }) => {
  const location = useLocation()
  return (
    <>
   {title && <Page.HeaderPage textEcommerce={textEcommerce} opacity={opacity} novedades={novedades} type={type} url={urlLogo} subTitle= {subTitle} bgLine={bgLine} title={title} description= {description}/>}

    <ContentContainer
      id={id}
      className={`tw-flex ${location?.pathname === '/' ? null : removeContainer ? '' : 'tw-max-w-[90%]'} tw-flex-col  tw-mx-auto ${name} ${className}`}
    >
      {children}
    </ContentContainer>
    </>
  )
}

Container.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  className: PropTypes.string,
  opacity: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  active: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  type: PropTypes.oneOf(['title,banner']),
  bgLine: PropTypes.bool,
  description: PropTypes.string,
  urlLogo: PropTypes.string,
  novedades: PropTypes.bool,
  removeContainer: PropTypes.bool,
  textEcommerce: PropTypes.string
}

export default Container
