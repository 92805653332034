import React from 'react'
import PropTypes from 'prop-types'
import Links from '../Links/Links'
import { useLocation } from 'react-router-dom'

/**
 *  @authro ehernandez
 * @param {bool} border
 * @param {Array} links
 * @param {node} children
 * @returns
 */
const Nav = ({ colorHover, links, children, color, onClick, className }) => {
  const location = useLocation()

  const getActive = (item) => {
    let active = location.pathname === item.href
    if (item?.items) {
      item.items.forEach((subItem) => {
        if (location.pathname === subItem.href) {
          active = true
        }
      })
    }

    return active
  }
  return (
    <div className={`nav tw-border-b tw-hidden  tw-border-border lg:tw-flex tw-flex-col lg:tw-flex-row tw-items-start ${children ? 'md:tw-justify-between' : 'md:tw-justify-center'} ${className}`}>
      {children && <div className="nav__children">{children}</div>}
      <div className="nav__link tw-flex tw-justify-center tw-gap-8  tw-w-full ">
        {links?.map((link, index) => {
          const active = getActive(link)
          return <Links href={link.href} name = {link?.name} onClick={(e) => e.href && onClick(e.href + (e.search || ''))} colorHover={colorHover} active={active} color={color} key={index} {...link} />
        })}
      </div>
    </div>
  )
}

Nav.propTypes = {
  border: PropTypes.bool,
  children: PropTypes.node,
  links: PropTypes.array,
  color: PropTypes.string,
  colorHover: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string
}

Nav.defaultProps = {
  links: [],
  color: 'secondary'
}

export default Nav
