/**
 * useWrapperInput
 * @author @jstarsky
 * @param {*} props
 * @return {Object}
 */
const useWrapperInput = ({
  id,
  error,
  flex,
  disabled,
  hidden,
  hiddenStyles,
  button,
  paragraphsSize,
  type,
  background,
  inputSize
}) => {
  /**
   * flexModeClassName set classes tailwind.
   * @author @jstarsky
   * @param {*} input Flex
   * @return {string}
   */
  const flexModeClassName = (input) => {
    switch (input) {
      case 'row':
        return 'tw-flex tw-items-center tw-w-full tw-gap-2'
      case 'between':
        return 'tw-flex tw-items-center tw-justify-between tw-w-full tw-gap-2'
      default:
        return type === 'pin_field' ? 'tw-flex tw-grow' : ''
    }
  }

  /**
   * labelModeClassName classes tailwind.
   * @author @jstarsky
   * @param {*} input label
   * @return {string}
   */
  const labelModeClassName = (input) => {
    switch (input) {
      case 'row':
        return ''
      case 'between':
        return ''
      default:
        return ' tw-pb-0'
    }
  }

  /**
   * errorModeClassName classes tailwind.
   * @author @jstarsky
   * @param {*} input description
   * @return {string}
   */
  const errorModeClassName = (input) => {
    if (input) {
      const { type: t, hidden, inputType } = input
      if (hidden) return ''
      switch (t) {
        case 'alert':
          return ` tw-border-b tw-border-alert${
            inputType === 'button_checkbox' ? 'tw-flex tw-flex-1' : ''
          }`
        case 'warning':
          return ' tw-border-b tw-border-warning'
        case 'required':
          return ' tw-border-b tw-border-alert'
        case 'validate':
          return ' tw-border-b tw-border-alert'
        case 'pattern':
          return ' tw-border-b tw-border-alert'
        default:
          return ' tw-border-b tw-border-transparent'
      }
    }
    return ' tw-border-b tw-border-transparent'
  }

  /**
   * disableModeClassName classes tailwind.
   * @author @jstarsky
   * @param {*} input
   * @return {string}
   */
  const disabledModeClassName = (input, toogle) => {
    if (!input && !toogle && type !== 'pin_field_wrapper') {
      return ' tw-bg-input'
    }
    if (!input && toogle) {
      return ' tw-bg-transparent'
    }
    return ' tw-bg-input'
  }

  /**
   * emptyParagraphs
   * @description Funtion allow to insert a paragraphs componnet in order to keep the input fix to the sizes
   * @author @jstarsky
   * @param {*} input
   * @return {string}
   */
  // const c = () => {
  //   return (
  //     <Paragraphs size={paragraphsSize} weight="bold">
  //       <span className="tw-text-transparent tw-leading-none"/>
  //     </Paragraphs>
  //   )
  // }

  /**
   * backgroundModeClassName set clases.
   * @author jstarsky
   * @return {string}
   */
  const backgroundModeClassName = (input) => {
    switch (input) {
      case 'primary':
        return ' tw-bg-primary tw-transition tw-duration-200'
      case 'secondary':
        return ' tw-bg-secondary tw-transition tw-duration-200'
      case 'tertiary':
        return ' tw-bg-tertiary tw-transition tw-duration-200'
      case 'white':
        return ' tw-bg-transparent tw-transition tw-duration-200'
      case 'black':
        return ' tw-bg-black tw-transition tw-duration-200'
      case 'transparent':
        return ' tw-bg-transparent tw-transition tw-duration-200'
      default:
        // TODO -Create a custom backgrond and parametricide the border color , the tw-border-gray-200 seens not working
        return ''
    }
  }

  /**
   * setSize
   * @author @jstarsky
   * @param {String} input
   * @return {String}
   * https://tailwindcss.com/docs/font-size#header
   */
  const setSize = (input, i) => {
    switch (input) {
      case 'xs':
        return ` ${i ? '' : 'tw-w-[24px] '}tw-h-[32px]${
          type === 'pin_field' ? ' tw-flex' : ''
        }`
      case 'xl':
        return ` ${i ? '' : 'tw-w-[32px] '}tw-h-[42px]${
          type === 'pin_field' ? ' tw-flex' : ''
        }`
      case '2xl':
        return ` ${i ? '' : 'tw-w-[56px] '}tw-h-[74px]${
          type === 'pin_field' ? ' tw-flex' : ''
        }`

      default:
        return ` tw-h-[32px]${type === 'pin_field' ? ' tw-flex' : ''}`
    }
  }
  return {
    internalClassName: `${id}${hidden ? ' tw-hidden' : ''}  ${
      hiddenStyles ? ' tw-pb-4' : ''
    }${type === 'pin_field_wrapper' ? ' tw-w-fit' : ''}`,
    disabledModeClassName: `${disabledModeClassName(
      disabled,
      type === 'checkbox' ||
        type === 'button_checkbox' ||
        type === 'button_radio' ||
        type === 'radio'
    )}${type !== 'pin_field' ? '' : ' tw-w-flex tw-flex-1'}`,
    flexModeClassName: `${flexModeClassName(flex)}`,
    labelModeClassName: `tw-leading-none${labelModeClassName(flex)}`,
    errorModeClassName: `${errorModeClassName({ ...error, inputType: type })}`,
    buttonModeClassName: `tw-flex tw-items-center tw-justify-center${
      button ? ' tw-cursor-pointer' : ''
    }${button && !button.icon ? ' ' : ''}${
      button && button.className ? ` ${button.className}` : ''
    }`,
    backgroundModeClassName: `tw-w-flex${
      type !== 'pin_field' ? '' : ' tw-flex-1 tw-grow'
    }${backgroundModeClassName(background)}${setSize(inputSize)}`,
    labelSize: paragraphsSize,
    classNameHeigthSize: `${setSize(inputSize, true)}`
  }
}
export default useWrapperInput
