import React from 'react'
import Paragraphs from '../Paragraphs'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const TitleLogo = ({ url, title, description, subTitle, textEcommerce }) => {
  const { t } = useTranslation()
  return (
    <div className="tw-flex tw-flex-col tw-p-5 tw-pb-10 tw-gap-8 tw-items-center">
      <img width={80} src={url} alt="icon" />
      <Paragraphs
        fontFamily="Trade"
        size={'9xl'}
        weight="bold"
        className="tw-text-primary md:tw-text-9xl tw-text-4xl tw-text-center"
      >
        {t(title)}
      </Paragraphs>
      {subTitle && (
        <div className="md:tw-w-1/3 tw-flex tw-flex-col tw-items-center">
          <Paragraphs className="tw-text-center md:tw-text-4xl tw-text-2xl" weight="medium">
            {t(subTitle)}
          </Paragraphs>
          <div className="tw-h-[3px] tw-mt-8 tw-w-[100px] tw-bg-gradient-to-r tw-from-primary tw-to-tertiary"/>
        </div>
      )}
      <Paragraphs
        weight="normal"
        className="tw-max-w-[700px] tw-text-base  tw-text-center"
      >
        {textEcommerce
          ? <p>Descarga gratuitamente todo el material actualizado y adaptado para tu eCommerce de los laboratorios que colaboran con Farmápolis.<br/><br/>Haz click sobre el botón de descarga del laboratorio y obtendrás un archivo Excel con todo el catálogo
actualizado y los enlaces a las imágenes de producto. ¡Actualiza tu tienda online en un click!</p>
          : t(description)}
      </Paragraphs>
    </div>
  )
}

export default TitleLogo

TitleLogo.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  subTitle: PropTypes.string,
  textEcommerce: PropTypes.string
}
