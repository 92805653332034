import React, { useEffect, useState } from 'react'
import Page from '../../components/Page/Page'
import { useNotices } from '../../infrastructure/hooks/useNoticies/useNoticies'
import NewItem from '../HomeScreen/components/NewItem'
import ListingGroups from '../../components/ListingGroups/ListingGroups'
import IconNews from '../../infrastructure/assets/images/home/news-logo.png'
import { useTheme } from '../../infrastructure/context/ThemeContext/ThemeContext'
import Modal from '../../components/Modal/Modal'
import Button from '../../components/Button/Button'
import Paragraphs from '../../components/Paragraphs'

const NewsScreen = () => {
  const { notices, getNotices } = useNotices()
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const { theme } = useTheme()

  useEffect(() => {
    setLoading(true)
    getNotices().then(() => setLoading(false))
  }, [])

  const onClickNews = (url) => {
    setShowModal(url)
  }

  return (
    <Page.Container id='news-screen' name='new-screen' className='formation-screen'
    description={theme?.is_lab ? null : 'Mantente puntualmente informado con las últimas noticias de los laboratorios y sus productos'} title={'Noticias'} urlLogo={IconNews}

    >
      {showModal &&
      <Modal onRequestClose={() => setShowModal(false)}>
        <div className='tw-p-5 tw-flex tw-flex-col tw-gap-5 tw-justify-center tw-items-center'>
        <Paragraphs>Vas a salir de la web de Farmápolis.</Paragraphs>
        <Button
          className='tw-mt-4'
          label='Aceptar'
          size={'medium'}
          onClick={() => {
            window.open(showModal, '_blank')
            setShowModal(false)
          }}
        />
        </div>

      </Modal>}
    <Page.Body className='tw-flex tw-flex-1 tw-flex-wrap tw-gap-y-6'>
      <ListingGroups className='tw-flex tw-flex-wrap  tw-w-full' loading={loading} getData={getNotices} paginatorInfo={notices?.data}>
      <div className="product-listing-screen__content__product tw-w-[90%] tw-items-center sm:tw-grid tw-flex tw-flex-wrap tw-justify-center  md:tw-grid-cols-2 lg:tw-grid-cols-3  xl:tw-grid-cols-4 tw-mx-auto tw-gap-5 ">
        {
          notices?.items?.map((item) => (
            <a key={item.id} onClick={() => onClickNews(item.url)} className='tw-p-2 tw-w-[300px]'>
                <NewItem className=' tw-w-full tw-bg-slate-50 ' {...item} />
            </a>
          ))
        }
        </div>
      </ListingGroups>

    </Page.Body>
</Page.Container>
  )
}

NewsScreen.propTypes = {}

export default NewsScreen
