/* eslint-disable no-const-assign */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import BlockAlert from '../../components/BlockAlert'
// import { useSearchParams } from 'react-router-dom'
import Page from '../../components/Page/Page'
import ProductCard from '../../components/ProductCard/ProductCard'
import useProducts from '../../infrastructure/hooks/useProducts'
import { useUser } from '../../infrastructure/hooks/useUser/useUser'
import { useTheme } from '../../infrastructure/context/ThemeContext/ThemeContext'
import { optionsOrder } from '../../infrastructure/constants/optionsOrderBy'
import ListingGroups from '../../components/ListingGroups/ListingGroups'
import IconCatalogo from '../../infrastructure/assets/images/logos/catalogo.png'
import IconNovedades from '../../infrastructure/assets/images/logos/novedades.png'

/**
 * @author ehernandez
 * @returns
 */
const ProductsListingScreen = () => {
  const { token } = useUser()
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    getProducts,
    products,
    addRemoveFav,
    downloadAll,
    loader
  } = useProducts()

  const [order, setOrder] = React.useState(
    // optionsOrder.find((obj) => obj.default === true).orderBy
  )
  const { theme } = useTheme()
  const [openFilters, setOpenFilter] = React.useState(false)
  const search = searchParams.get('search')
  const laboratories = searchParams.get('laboratories')
  const categories = searchParams.get('categories')
  const news = searchParams.get('news')
  const page = searchParams.get('page')
  const [queries, setQueries] = useState(null)
  const [filters] = useState(null)
  const [title, setTitle] = useState('')
  const [logo, setLogo] = useState('')
  const [description, setDesctiption] = useState()
  const [loading, setLoading] = useState(false)

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const labs = laboratories
      ? laboratories?.split(',').map((i) => Number(i))
      : []
    const cats = categories ? categories?.split(',').map((i) => Number(i)) : []
    setQueries({
      search: search || '',
      laboratories: labs,
      categories: cats,
      news: news || null,
      page: page || null
    })
  }, [search, categories, laboratories, news])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    if (queries) {
      setLoading(true)
      getProducts({ ...queries, order }).then(() => setLoading(false))
    }
  }, [queries, token, order])

  const onChangeOrder = (id) => {
    const orderSelected = optionsOrder.find((obj) => obj.id === Number(id))
    setOrder(orderSelected?.orderBy)
  }

  const onSubmitSearch = ({ search }) => {
    const values = {
      news: queries.news || 'null',
      search,
      categories: categories || 'null',
      laboratories: laboratories || 'null',
      page: 1 || 'null'
    }
    Object.keys(values).forEach((i) => {
      if (!values[i] || values[i] === 'null') {
        delete values[i]
      }
    })
    setSearchParams(values)
  }

  const handleSetFilter = async (key, value) => {
    const newQueries = queries?.[key] || []
    if (newQueries.includes(value)) {
      newQueries.splice(newQueries.indexOf(value), 1)
    } else {
      newQueries.push(value)
    }

    const values = {
      news: queries.news || '',
      search: queries.search,
      categories: categories || '',
      laboratories: laboratories || '',
      page: 1 || '',
      [key]: newQueries.join(',')
    }
    Object.keys(values).forEach((i) => {
      if (!values[i] || values[i] === 'null') {
        delete values[i]
      }
    })
    setSearchParams(values)
  }

  const onResetFilters = () => {
    setSearchParams({
      search: queries.search || ''
    })
  }

  // se acciona cuando cambias de pagina y cuando cambia de orden
  const getProductList = async (page) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setLoading(true)
    const values = {
      news: queries.news || '',
      search: queries.search,
      categories: categories || '',
      laboratories: laboratories || '',
      page: page || ''
    }
    Object.keys(values).forEach((i) => {
      if (!values[i] || values[i] === 'null') {
        delete values[i]
      }
    })
    setSearchParams(values)
    getProducts({ ...queries, page, order }).then(() => setLoading(false))
  }

  useEffect(() => {
    if (location) {
      if (location.pathname === '/product-list') {
        setLogo(IconCatalogo)
        setTitle('Catálogo')
        setDesctiption('Busca información de un producto, categoría o laboratorio en  concreto, de entre más de 35.000 referencias y sin tener que acudir a Google. ​')
      } else {
        setLogo(IconNovedades)
        setTitle('Novedades')
        setDesctiption('Conoce las novedades de producto que salen al mercado para hacer el pedido al laboratorio y ofrecerlo a tus clientes.')
      }
    }
  }, [location])

  useEffect(() => {
  // detect if we press back button in browser
    window.onpopstate = () => {
      const url = new URL(window.location.href)
      const page = url.searchParams.get('page')
      setLoading(true)
      getProducts({ ...queries, page, order }).then(() => setLoading(false))
    }
  }, [])

  return (
     <>
     <Page.Container name="product-listing-screen" id="product-listing-screen"

title={title} urlLogo={logo} description={ !theme?.is_lab ? description : null}>
  <Page.Body >
   <ListingGroups
      onSubmitSearch={onSubmitSearch}
      search={search}
      orderBy={{
        onChangeOrder,
        options: optionsOrder
      }}
      filters={{
        data: filters,
        visible: openFilters,
        handleSetFilter,
        onResetFilters,
        onClose: () => setOpenFilter(false),
        selecteds: queries
      }}
      loading={loading}
      getData={getProductList}
      currentPage={page || 1}
      paginatorInfo={products?.data}
    >
      <div className="product-listing-screen__content__product tw-w-full tw-flex-1 tw-flex tw-flex-wrap">
        {products?.items?.map((i, index) => (
          <div
            data-cy= { `product-card-${index}` }
            key={i.id}
            className="tw-w-full tw-pb-2 sm:tw-w-6/12 md:tw-w-6/12 lg:tw-w-4/12 xl:tw-w-3/12 tw-mb-4 md:tw-p-2"
          >
            <ProductCard
              className={'tw-h-[400px]'}
              loader={loader}
              onClickDownload={() => downloadAll(i.id)}
              onClickFav={(active) => {
                addRemoveFav(i.id, active)
              }}
              onClick={(event) => {
                if (event.target.className.includes('laboratorie')) {
                  return navigate(`/laboratorios/${i?.lab?.id}`)
                }
                token && navigate('/product-list/' + i.id)
              }
              }
              isLogged={!!token}
              {...i}
              i={i}
              image={i?.product_images[0]}
            />
          </div>
        ))}
      </div>
    </ListingGroups>
    <div className="tw-z-30 tw-bottom-10 tw-left-0 tw-w-full tw-px-4">
      {!token && <BlockAlert />}
    </div>
  </Page.Body>
</Page.Container>
     </>
  )
}

ProductsListingScreen.propTypes = {}

export default ProductsListingScreen
