import styledComponents from 'styled-components'

export const LinkContainer = styledComponents.div`
    & .links-items{
        display: none;
    }

    &:hover .links-items{
        display: block;
    }
`
