import styled from 'styled-components'
import Dots from '../../../../infrastructure/assets/images/home/background-dots-1.png'

export const Container = styled.div`
  background-image: url(${Dots});
  background-size: cover;
  background-repeat: no-repeat;
  /* z-index: -100;
  position: absolute;
  width: 100%;
  left: 0;
  top: 190px; */
   

  
`
