import React, { useEffect } from 'react'
import Paragraphs from '../../../../components/Paragraphs'
import PropTypes from 'prop-types'
import { ACCORDEON_LIST } from '../../constans/accordeon-list'
import { TextContainer } from './style'

const AccordeonDetail = ({ shortDescription, longDescription, posology, precautions, data }) => {
  const [active, setActive] = React.useState([])
  const [info, setInfo] = React.useState(null)
  const [hideAccordeon, setHideAccordeon] = React.useState(true)

  const onClick = (index) => {
    // if the index is already in the array, remove it
    if (active.includes(index)) {
      setActive(active.filter((item) => item !== index))
    } else {
      // if the index is not in the array, add it
      setActive([...active, index])
    }
  }
  useEffect(() => {
    // add description each object ACCORDEON_LIST
    setInfo(ACCORDEON_LIST.map((item, index) => {
      if (index === 0) {
        item.description = longDescription
      } else if (index === 1) {
        item.description = posology
      } else if (index === 2) {
        item.description = precautions
      }
      return item
    }))
  }, [data])

  useEffect(() => {
    // if description === '' then remove item
    if (info) {
      const newData = info.filter(item => item.description !== '')
      // set loading false if newData.length > 0
      if (newData.length > 0) {
        setHideAccordeon(false)
      }
      setInfo(newData)
    }
  }, [data])

  return (
    <>
    {shortDescription &&
    <div className="tw-px-4 tw-mt-5 tw-w-full">
      <TextContainer id='text'>
      <Paragraphs weight={'medium'} size={'lg'}>{'Descripción Corta'}</Paragraphs>
                    <Paragraphs weight={'regular'} size={'xs'}>{shortDescription}</Paragraphs>
                </TextContainer>
    </div>
      }
    { !hideAccordeon && <div id="accordeon-list" className="tw-px-4 tw-mt-5 tw-w-full">
        {info?.map((item, index) => (
            <div key={index} onClick={() => onClick(index, item) } className="tw-flex tw-flex-col tw-relative tw-cursor-pointer tw-py-3 tw-border-slate-200 tw-border-t-[1px] tw-w-full">
                <div className='tw-w-full tw-justify-between tw-flex'>
                <Paragraphs weight={'medium'} size={'lg'}>{item.title}</Paragraphs>
                <span className="tw-text-lg tw-cursor-pointer tw-font-semibold">{active.includes(index) ? '-' : '+'}</span>
                </div>
                { active.includes(index) &&
                <TextContainer id='text'>
                    <Paragraphs weight={'regular'} size={'xs'}>{item?.description}</Paragraphs>
                </TextContainer>}
            </div>
        ))}
    </div>}
    </>
  )
}

export default AccordeonDetail
AccordeonDetail.propTypes = {
  shortDescription: PropTypes.string,
  longDescription: PropTypes.string,
  posology: PropTypes.string,
  precautions: PropTypes.string,
  data: PropTypes.array
}
