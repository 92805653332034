import { format } from 'date-fns'
import React from 'react'
import { useParams } from 'react-router-dom'
import EcommerceCardItem from '../../../../components/EcommerceCardItem/EcommerceCardItem'
import Paragraphs from '../../../../components/Paragraphs'
import { BASE } from '../../../../infrastructure/constants/configurations'
import propTypes from 'prop-types'

const DocumentsSection = ({ info }) => {
  const { id } = useParams()
  const onClickDownloadSingle = (idItem) => {
    const a = document.createElement('a')
    a.href = BASE + `labs/${id}/documents_download?documents=${idItem}`
    a.download = 'FILENAME'
    a.target = '_blank'
    a.click()
  }

  return (
        <div className='tw-flex tw-flex-col tw-gap-5 tw-justify-between tw-h-full tw-max-h-full tw-overflow-auto lg:tw-w-1/3 tw-w-full'>
        {info?.ecommerce_documents.length > 0 && <div className='tw-flex md:tw-px-20 tw-px-5 tw-py-5 tw-flex-col tw-bg-secondary tw-gap-5'>
            <Paragraphs weight={'bold'} size= '2xl'>Material eCommerce</Paragraphs>
            <div className='tw-flex tw-flex-col tw-gap-3'>
            {info?.ecommerce_documents.map((item, index) => (
              <EcommerceCardItem onClickDownload={() => onClickDownloadSingle(item.id)} key={index} {...item} />
            ))}
            </div>
            <div>
              <Paragraphs weight={'regular'} size={'xs'}>
                {`Nº productos: ${info?.extra_data_products_count}`}
              </Paragraphs>
              <Paragraphs weight={'regular'} size={'xs'}>
                Formato imágenes: {info?.extra_data_format_image}
              </Paragraphs>
              { info?.extra_data_last_updated && <Paragraphs weight={'regular'} size={'xs'}>
                {`Última actualización: ${format(new Date(info?.extra_data_last_updated), 'dd/MM/yyyy')} `}
              </Paragraphs>}
            </div>
        </div>}
        {info?.tariff_documents.length > 0 && <div className='tw-flex md:tw-px-20 tw-px-5 tw-py-5 tw-flex-col tw-bg-secondary tw-gap-5'>
            <Paragraphs weight={'bold'} size= '2xl'>Tarifas</Paragraphs>
            <div className='tw-flex tw-flex-col tw-gap-3'>
              {info?.tariff_documents.map((item, index) => (
                <EcommerceCardItem onClickDownload={() => onClickDownloadSingle(item.id)} key={index} {...item} />
              ))}
            </div>
        </div>}
      </div>
  )
}

export default DocumentsSection

DocumentsSection.propTypes = {
  info: propTypes.object
}
