import React from 'react'
import { SpinnerContainer } from './styles'

const Spinner = () => {
  return (
<SpinnerContainer className="lds-facebook spinner"><div></div><div></div><div></div></SpinnerContainer>)
}

Spinner.propTypes = {}

export default Spinner
