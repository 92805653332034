import { useDispatch, useSelector } from 'react-redux'
import { LOGIN, REGISTER, FORGOT_PASSWORD } from '../../constants/api'
import { actionSetCookies } from '../../redux/actions/cookies'
import { actionSetTokenSuccess, actionUpdateUser } from '../../redux/actions/user'
import { useMutation } from '../useMutation/useMutation'

/**
 * get info user
 * @author   ehernandez
 * @param    {Boolean}   updateStorage   update redux storage
 * @return  {Object}
 */
export const useUser = () => {
  const { user, token } = useSelector((state) => state.userReducer)
  const cookies = useSelector((state) => state.cookiesReducer?.cookies) || sessionStorage.getItem('cookies')

  const [fetchEmail] = useMutation('opt-in')
  const [fetchUser] = useMutation('auth/me')
  const [fetchRegister] = useMutation(REGISTER)
  const [fetchLogin] = useMutation(LOGIN)
  const [fetchForgotPassword] = useMutation(FORGOT_PASSWORD)
  const [fetchResetPassword] = useMutation('auth/reset-password')
  const dispatch = useDispatch()
  const onRegister = async (values) => {
    const { data, success, error } = await fetchRegister({
      method: 'POST',
      data: values
    })
    if (success) {
      dispatch(
        actionSetTokenSuccess(data?.result?.access_token)
      )
      return {
        success,
        data: data?.result
      }
    } else {
      return {
        success,
        data,
        error
      }
    }
  }

  const onLogin = async (values) => {
    const { data, success, error } = await fetchLogin({
      method: 'POST',
      data: values
    })
    if (success) {
      dispatch(
        actionSetTokenSuccess(data?.result?.access_token, data?.result?.user)
      )
      return {
        success,
        data: data?.result
      }
    } else {
      return {
        success,
        data,
        error
      }
    }
  }

  const onAutologin = (token) => {
    dispatch(actionSetTokenSuccess(token))
  }

  const setCookies = () => {
    dispatch(actionSetCookies())
    sessionStorage.setItem('cookies', true)
  }

  const onCloseSession = () => {
    dispatch(actionSetTokenSuccess(null, null))
  }

  const getInfoUser = async () => {
    const { data } = await fetchUser({
      method: 'POST'
    })
    if (data && data?.result) {
      dispatch(actionUpdateUser(data?.result?.user))
    }
  }

  const getInfoUserOnAutologin = async ({ authToken }) => {
    const { data } = await fetchUser({
      method: 'POST',
      authToken
    })
    if (data && data?.result) {
      dispatch(actionUpdateUser(data?.result?.user))
    }
  }

  const onSendEmail = async (values) => {
    const response = await fetchEmail({
      method: 'POST',
      data: { ...values, optin: true }
    })
    return {
      success: response?.success,
      error: response?.error?.errors?.email[0]
    }
  }

  const onForgotPassword = async (values) => {
    const { data, success, error } = await fetchForgotPassword({
      method: 'POST',
      data: values
    })
    if (success) {
      return {
        success,
        data: data?.result
      }
    } else {
      return {
        success,
        data,
        error
      }
    }
  }

  const onSendNewPassword = async (values, token, email) => {
    if (token) {
      values.token = token
    }
    if (email) {
      values.email = email
    }
    delete values.city_id
    const { data, success, error } = await fetchResetPassword({
      method: 'POST',
      data: values
    })
    if (success) {
      return {
        success,
        data: data?.result
      }
    } else {
      return {
        success,
        data,
        error
      }
    }
  }

  return {
    user,
    token,
    onRegister,
    onLogin,
    setCookies,
    cookies,
    onCloseSession,
    getInfoUser,
    onAutologin,
    getInfoUserOnAutologin,
    onSendEmail,
    onForgotPassword,
    onSendNewPassword
  }
}
