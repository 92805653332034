import React from 'react'
import Paragraphs from '../../Paragraphs'
import SliderPartner from '../../SliderPartner'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
/**
 *@author cperez
 *@return {React.JSX}
 */
const SectionPartners = ({ title, logos, id }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="tw-flex  tw-justify-center tw-flex-col  tw-items-center">
        <Paragraphs className='tw-text-center' weight="medium" size="4xl">
         {t(title)}
        </Paragraphs>
        <div className="bg-radient-line"></div>
      </div>
      <div className="tw-max-w-[100%] tw-flex tw-items-center  tw-mx-auto">
        <SliderPartner id={id} logos={logos} />
      </div>
    </>
  )
}

SectionPartners.propTypes = {
  title: PropTypes.string,
  logos: PropTypes.array,
  id: PropTypes.string
}

SectionPartners.defaultProps = {
  title: 'Ellos ya confian en nosotros'
}

export default SectionPartners
