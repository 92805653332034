import { useState } from 'react'
import { NOTICES } from '../../constants/api'
import useMutation from '../useMutation'

export const useNotices = () => {
  const [fetchNotices] = useMutation(NOTICES)
  const [notices, setNotices] = useState()
  const [loading, setLoading] = useState(false)
  const getNotices = async (prePage = 500) => {
    setLoading(true)
    const { data } = await fetchNotices({
      method: 'POST',
      data: {
        elements: {
          per_page: prePage
        }
      }

    })
    setLoading(false)
    if (data.result) {
      setNotices(data.result)
      return {
        data
      }
    }
  }

  return {
    getNotices,
    notices,
    loading
  }
}
