import React from 'react'
import { useNavigate } from 'react-router-dom'
import Content from '../Content'
import PropTypes from 'prop-types'

const FilterFavoriteBtn = ({ size }) => {
  const navigate = useNavigate()

  return (
            <Content onClick={() => navigate('/favoritos')} className={`${size || 'tw-h-[60px] tw-w-[60px]'} tw-bg-white  tw-flex tw-items-center tw-justify-center tw-cursor-pointer`}>
                <span data-cy='btn-fav' className='icon-ic_favorito_selected tw-text-alert' style={{ fontSize: 20 }} />
            </Content>
  )
}

export default FilterFavoriteBtn

FilterFavoriteBtn.propTypes = {
  size: PropTypes.string
}
