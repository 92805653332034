import React, { useEffect, useMemo, useRef, useState } from 'react'
import Page from '../../components/Page/Page'
import ProductCode from '../../components/ProductCode'
import ProductName from '../../components/ProductName'
import TextInput from '../../components/TextInput/TextInput'
import { INPUTS } from './constans/inputs'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'
import useProducts from '../../infrastructure/hooks/useProducts'
import SliderImages from '../../components/SliderImages'
import PreviewImage from '../../components/PreviewImage'
import ResourcesContainer from '../../components/ResourcesContainer/ResourcesContainer'
import useResources from '../../infrastructure/hooks/useResources'
import { useTranslation } from 'react-i18next'
import AccordeonDetail from './components/AccordeonDetail'

const options = [
  {
    id: null,
    name: 'Todos'
  },

  {
    id: 'prod_custom_img',
    name: 'Imagenes Laboratorio'
  },
  {
    id: 'rrss',
    not_type_label: 'rrss_tem_img',
    name: 'Imagenes RRSS'
  },
  {
    id: 'video',
    name: 'Videos'
  },
  {
    id: 'custom_file',
    name: 'Archivos'
  }
]

/**
 * @author ehernandez
 * @returns
 */
const ProductsDetailScreen = () => {
  const refDescription = useRef(null)
  const [heightContent, setHeightContent] = useState(null)
  const refContainer = useRef(null)
  const { register, reset } = useForm()
  const { id } = useParams()
  const navigate = useNavigate()

  const { t } = useTranslation()

  const [openModal, setOpenModal] = useState(false)
  const { getProduct /* downloadAll, loader */ } = useProducts()

  const { getResources, resources, loading } = useResources()

  const [data, setData] = useState(null)

  const getData = async () => {
    const { data } = await getProduct(id)
    setData(data)
    reset({ ...data })
  }

  const openModalPreview = (event, item, index) => {
    setOpenModal(true)
  }

  useEffect(() => {
    const { height } = refContainer.current.getBoundingClientRect()

    setHeightContent(height)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    getData()
  }, [])

  useEffect(() => {
    getResources({ typeLablel: options?.[0]?.id, productId: id })
  }, [])

  const resourcesOptions = useMemo(() => {
    return options.map((item) => {
      return {
        ...item,
        name: t(item.name)
      }
    })
  }, [])

  const images = useMemo(() => {
    return data?.product_images?.map((item, index) => {
      return {
        id: index,
        value: item,
        public_name: item + index
      }
    })
  }, [data])

  return (
    <Page.Container name="product-detail-screen" id="product-detail-screen">
      {openModal && (
        <PreviewImage
          onRequestClose={() => setOpenModal(false)}
          images={[{ value: data?.product_images?.[0], id: 1 }]}
          cn={data?.cn_codes}
          name={data?.public_name}
        />
      )}
      <Page.Body reference={refContainer} className="tw-relative">
        <div className="tw-flex-1 t1w-justify-center  tw-flex tw-relative lg:w-absolute tw-w-full tw-flex-wrap lg:tw-flex-nowrap tw-mb-10">
           <SliderImages
            style={{ height: heightContent }}
            onClick={openModalPreview}
            images={images}
            className=" md:tw-max-h-[600px] tw-max-h-[500px] product-detail-screen__images tw-w-full tw-flex  lg:tw-w-5/12 tw-relative  tw-justify-center "
          />
          <div className="product-detail-screen__images tw-pb-50 tw-max-h-[600px] tw-flex-1 tw-flex tw-flex-col tw-gap-4">
            <div className=" product-detail-screen__product__name tw-p-4 tw-flex tw-flex-col tw-gap-y-1 ">
              <ProductCode code={data?.cn_codes} />
              <ProductName
                onClickManufacturer={() =>
                  navigate(`/laboratorios/${data?.lab?.id}`)
                }
                name={data?.public_name}
                manufacturer={data?.lab_name}
              />
            </div>
            <div
              ref={refDescription}
              className="tw-flex-1 tw-max-h-full tw-overflow-auto  product__description"
              style={{ maxHeight: heightContent - 220 || 'auto' }}
            >
              {heightContent && (
                <><div className="tw-flex tw-flex-wrap tw-px-2 tw-gap-y-2">
                  {INPUTS.map((i) => (
                    <div key={i.id} className={`${i.className} tw-px-2`}>
                      <TextInput
                        // value={data?.[i.name]}
                        name={i.name}
                        register={register(i.name)}
                        type={i.type}
                        label={i.label}
                        disabled={i.disabled} />
                    </div>
                  ))}
                </div>
                <AccordeonDetail
                  data={data}
                  shortDescription={data?.short_description}
                  longDescription={data?.long_description}
                  posology={data?.posology}
                  precautions={data?.precautions}
                />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="tw-py-10">
          <ResourcesContainer
            id={data?.id}
            loading={loading}
            imageToRemplace={data?.product_images?.[0]}
            name={data?.public_name}
            items={resources?.items}
            menu={resourcesOptions}
            onClickLink={(item) => {
              getResources({ typeLablel: item.id, productId: id, notTypeLabel: item?.not_type_label })
            }}
          />
        </div>
      </Page.Body>
    </Page.Container>
  )
}

ProductsDetailScreen.propTypes = {}

export default ProductsDetailScreen
