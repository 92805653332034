/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs/Paragraphs'
import Badge from '../Badge/Badge'
import BtnFavorite from '../BtnFavorite'
import Content from '../Content'
import { useNavigate } from 'react-router-dom'
import Spinner from '../Spinner/Spinner'
import { useUser } from '../../infrastructure/hooks/useUser/useUser'

/**
 *
 * @param {} props
 * @returns
 */
const ProductCard = ({
  i,
  public_name:
  publicName,
  image,
  cn_codes: code, lab_name: manufacturer,
  isLogged,
  onClick,
  is_favorite: isFav,
  onClickFav,
  onClickDownload,
  isNew,
  loader,
  className
}) => {
  const { user } = useUser()

  return (
    <Content transition className={` ${className} tw-relative product-card ${loader && i?.id === loader ? 'tw-opacity-50' : null} `}>
    {isLogged && (
      <>
       {loader && i?.id === loader &&
      <div className='tw-absolute tw-z-50  tw-top-1/2 tw-left-1/2 tw-transform -tw-translate-x-1/2 -tw-translate-y-1/2 '>
        <Spinner/>
      </div>
      }
      <div className="tw-absolute product-card__badges hover:tw-text-white tw-text-black  tw-z-10 tw-w-[40px] tw-h-[40px] tw-flex tw-flex-col tw-cursor-pointer tw-right-4 tw-top-4 tw-bg-white hover:tw-bg-primary tw-rounded-[5px]">
       <BtnFavorite onClick={onClickFav} isFav={isFav} />
        {/* <Badge onClick={onClickDownload}>
           <span className="icon-ic_download  " />
        </Badge> */}
      </div>
     {isNew && <div className=' tw-w-[100px]'>
        <Badge bgColor='primary' color= 'secondary' size = ''>Novedad</Badge>
      </div>}
      </>
    )}
    <div onClick={onClick} className="product-card__content">
      <div className="product-card__image tw-p-5 tw-relative tw-h-[270px] tw-flex tw-items-center tw-justify-center">
        <img className='tw-max-h-full' src={image} alt={publicName} />
      </div>
      <div className="product-card__content tw-p-4">
        <Paragraphs
          weight="bold"
          size="xxs"
          className="product-card__code tw-text-primary"
        >
          {code}
        </Paragraphs>
        <Paragraphs weight="bold" className="product-card__name">
          {publicName}
        </Paragraphs>
        <Paragraphs
          weight="light"
          transform="uppercase"
          size="xs"
          className=" tw-max-w-min laboratorie product-card__manufacturer hover:tw-border-b-[1px] tw-border-slate-800 tw-z-10 tw-mt-2 tw-truncate "
        >
          {manufacturer}
        </Paragraphs>
      </div>
    </div>
    </Content>
  )
}

ProductCard.propTypes = {
  public_name: PropTypes.string,
  image: PropTypes.string,
  cn_codes: PropTypes.string,
  lab_name: PropTypes.string,
  isLogged: PropTypes.bool,
  onClick: PropTypes.func,
  is_favorite: PropTypes.bool,
  onClickFav: PropTypes.func,
  onClickDownload: PropTypes.func,
  className: PropTypes.string
}

export default ProductCard
