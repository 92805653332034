import { useState } from 'react'
import { PRODUCT_RESOURCES } from '../../constants/api'
import useMutation from '../useMutation'

export const useResources = () => {
  const [fetchResources] = useMutation(PRODUCT_RESOURCES)
  const [loading, setLoading] = useState(false)
  const [resources, setResources] = useState(null)
  const getResources = async (values = {}) => {
    const { productId, typeLablel, page = 1, perPage = 500, notTypeLabel } = values
    const value = {
      attributes: {
      },
      elements: {
        page,
        per_page: perPage
      }
    }
    if (productId) {
      value.attributes.product_id = productId
    }
    if (typeLablel) {
      value.attributes.type_label = typeLablel
    }

    if (notTypeLabel) {
      value.attributes.not_type_label = notTypeLabel
    }
    setLoading(true)
    const { data } = await fetchResources({
      method: 'POST',
      data: value
    })
    setLoading(false)

    if (data?.result) {
      setResources({ ...data.result })
    }
    return data
  }
  return { getResources, resources, loading }
}
