/**
 * useLabel
 * @author @jstarsky
 * @param {*} input
 * @return {Object}
 */
const useLabel = ({ id, color, alignment, extendClassName }) => {
  /**
   * setColor
   * @author @jstarsky
   * @param {String} input color
   * @return {String}
   * https://tailwindcss.com/docs/font-family#header
   */
  const setColor = (input) => {
    switch (input) {
      case 'alert':
        return ' tw-text-alert'
      case 'primary':
        return ' tw-text-primary'
      case 'secondary':
        return ' tw-text-secondary'
      case 'tertiary':
        return ' tw-text-tertiary'
      case 'success':
        return ' tw-text-success'
      case 'warning':
        return ' tw-text-warning'
      case 'transparent':
        return ' tw-text-transparent'
      case 'white':
        return ' tw-text-white'
      case 'black':
        return ' tw-text-black'
      case 'green':
        return ' tw-text-green'
      case 'required':
        return ' tw-text-required'
      case 'validate':
        return ' tw-text-validate'
      default:
        return ''
    }
  }

  /**
   * setAlingment
   * @author @jstarsky
   * @param {String} input alingment "center", "end", "start"
   * @return {String}
   * https://tailwindcss.com/docs/font-family#header
   */
  const setAlingment = (input) => {
    switch (input) {
      case 'start':
        return ' tw-justify-start'
      case 'center':
        return ' tw-justify-center'
      case 'end':
        return ' tw-justify-end'
      default:
        return ''
    }
  }
  return {
    internalClassName: `${id}${setColor(color)}${
      extendClassName ? ` ${extendClassName}` : ''
    }`,
    alingmentClassName: `${setAlingment(alignment)}`
  }
}

export default useLabel
