import { useDispatch, useSelector } from 'react-redux'
import { actionOpenLogin, actionCloseLogin } from '../../redux/actions/layoutActions'
import { useLocation, useNavigate } from 'react-router-dom'

export const useModalLogin = () => {
  const { modal } = useSelector(state => state.layoutReducer)
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const openModalLogin = () => {
    dispatch(actionOpenLogin())
  }
  const closeModalLogin = () => {
    dispatch(actionCloseLogin())
    navigate(location.pathname)
  }
  return {
    openModalLogin, closeModalLogin, modal
  }
}
