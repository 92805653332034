
import { SET_COOKIES } from '../constants/cookies'

/**
 * @author ehernandez
 * @param {object} user object to be saved
 * @returns {object}
 */
export const actionSetCookies = () => ({
  type: SET_COOKIES
})
