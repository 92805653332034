import React, { } from 'react'
import Paragraphs from '../../../Paragraphs'
import PropTypes from 'prop-types'
import TextInput from '../../../TextInput/TextInput'
import Label from '../../../Label/Label'
import Button from '../../../Button/Button'
import EtiquetaPremio from '../../../../infrastructure/assets/images/logos/logo-premios2.png'
import { useUser } from '../../../../infrastructure/hooks/useUser/useUser'

const PopUp = ({ logoBackground, vector, onClose }) => {
  const [email, setEmail] = React.useState('')
  const [showMessage, setShowMessage] = React.useState(false)
  const [checked, setChecked] = React.useState(false)
  const { onSendEmail } = useUser()

  const handleSendEmail = async (e) => {
    e.preventDefault()
    if (!checked) {
      return
    }
    const { error, success } = await onSendEmail({ email })
    if (success) {
      // show message only 3 seconds
      setShowMessage({
        message: 'Email enviado correctamente',
        type: 'tw-text-green-500'
      })
      setTimeout(() => {
        setShowMessage(false)
        onClose()
      }, 2500)
      // save data in localstorage
      localStorage.setItem('email', email)
      setEmail('')
    } else {
      setShowMessage({
        message: error,
        type: 'tw-text-red-500'
      })
      setTimeout(() => {
        setShowMessage(false)
      }, 3000)
    }
  }

  const handleCheckBox = (e) => {
    setChecked(!checked)
  }
  return (
    <div className=" tw-overflow-auto tw-max-h-[85vh]">
      <div className='tw-flex tw-flex-col sm:tw-items-end sm:tw-px-10'>
      <img
        src={logoBackground}
        className=" sm:tw-w-[800px] tw-absolute tw-top-0 tw-left-1/2 tw-transform -tw-translate-x-1/2 "
      />
      <div className="tw-flex   tw-gap-5  lg:tw-flex-nowrap tw-flex-wrap-reverse ">
        <div id="text-banner" className="sm:tw-p-5 tw-w-[580px] tw-max-w-[90%]">
          <Paragraphs fontFamily={'Trade'} weight={'medium'} size='7xl' className='tw-text-primary tw-leading-normal'>
            ¡Te damos la bienvendia a Farmápolis!
          </Paragraphs>
          <Paragraphs
            size=""
            weight={''}
            className="sm:tw-w-[550px] tw-w-[250px] tw-mt-8"
          >
            Estamos ultimando los detalles para que puedas seguir disfrutando al máximo de la experiencia  de Farmápolis. Deja aqui tu email y nos pondremos en contacto contigo tan pronto como el registro esté disponible de nuevo.
          </Paragraphs>
          <form onSubmit={handleSendEmail} className='tw-w-[450px] tw-max-w-[95%] tw-py-5 tw-flex tw-flex-col tw-gap-2'>
          <Label size={'sm'} weight={'medium'} color={'tertiary'} className={'tw-px-1'} label='Email'/>
          <TextInput
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
          />
          {showMessage && <Paragraphs className={showMessage.type}>{showMessage.message}</Paragraphs>}
          <div className='tw-flex tw-items-center tw-gap-2'>
          <input
             className='tw-cursor-pointer'
             label={'Acepto la política de privacidad y condociones de uso'}
             type={'checkbox'}
             onChange={handleCheckBox}
             checked={checked}
          />
          <Label
            label={'Acepto la política de privacidad y condiciones de uso'}
            size={'xs'}
          />
          </div>
          </form>
          <div className='tw-w-[132px] tw-mb-5'>
            <Button
                label='Enviar'
                mode={'gradient'}
                uppercase
                size={'mini'}
                type={'submit'}
                onClick={handleSendEmail}
                disabled={!checked}
            />
           </div>
           <Paragraphs>¡Muchas gracias y nos vemos en breve!</Paragraphs>
        </div>
        <div id="vector-banner">
          <img
            className="xl:tw-w-[600px] tw-w-[300px]"
            src={vector}
            alt="vector-city"
          />
        </div>
      </div>
      <div className=' tw-mt-5 tw-mb-5 tw-w-[400px] tw-max-w-[90%] tw-flex tw-bottom-5 tw-items-center tw-justify-center tw-gap-5 tw-px-5 tw-border-[2px] tw-border-gray-300  sm:tw-right-5 tw-right-5'>
            <Paragraphs weight={'medium'} className='tw-text-premios tw-py-5 tw-w-[120px] tw-border-r-[2px] tw-border-gray-300'>Premio a la Transformación Digital</Paragraphs>
            <img src={EtiquetaPremio} alt='etiqueta-premio' className='sm:tw-w-[200px] tw-w-[100px]'/>
        </div>
        </div>
    </div>
  )
}

export default PopUp

PopUp.propTypes = {
  logoBackground: PropTypes.string,
  logoFarma: PropTypes.string,
  vector: PropTypes.string,
  onClose: PropTypes.func
}
