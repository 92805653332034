import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import SliderImages from '../../../../components/SliderImages'

const BannerLaboratory = ({ laboratorieScreen, images, onSubmitSearch, onClickAllProducts, placeholder, titleAction }) => {
  const imgs = useMemo(() => {
    return images?.map(img => {
      img.value = img.url
      return img
    })
  }, [images])

  return (
    <div className='banner-laboratory tw-flex tw-w-full tw-mx-auto tw-flex-col tw-gap-4'>
      {
        imgs && imgs.length > 0 && <div className='banner-laboratory__carousell'>
        <SliderImages onClick={null} images={imgs} className='product-detail-screen__images tw-h-[220px] tw-overflow-hidden tw-rounded-[6px] tw-relative'
         positionPagination='inside'
         hideNavigation={true}
        />
        </div>
      }
    </div>
  )
}

BannerLaboratory.propTypes = {
  img: PropTypes.string,
  onSubmitSearch: PropTypes.func,
  onClickAllProducts: PropTypes.func,
  onClickFavorites: PropTypes.func,
  images: PropTypes.array,
  placeholder: PropTypes.string,
  titleAction: PropTypes.string,
  laboratorieScreen: PropTypes.bool
}

export default BannerLaboratory
