import React from 'react'
import PropTypes from 'prop-types'
import { ContentFooter } from './styles'

/**
 * @author pparra
 * @param {Array} buttons buttons inside the footer
 * @returns {React.Component}
 */
const Footer = ({ children }) => {
  return (
    <ContentFooter className='tw-bg-primary tw-bg-opacity-10 tw-w-full tw-flex tw-items-center tw-gap-4 tw-px-4'>
        { children }
    </ContentFooter>

  )
}

Footer.propTypes = {
  children: PropTypes.node
}

export default Footer
