import React from 'react'
import Paragraphs from '../../Paragraphs'
import VectorCity from '../../../infrastructure/assets/images/home/vector-city.png'
import VectorHome from '../../../infrastructure/assets/images/home/v-vector.png'
import { useTheme } from '../../../infrastructure/context/ThemeContext/ThemeContext'
import LogoFarma from '../../../infrastructure/assets/images/logos/ic_farmapolis_color.svg'
import BannerWelcome from '../components/BannerWelcome'

/**
 *@author cperez
 *@return {React.JSX}
 */
const SectionWelcome = () => {
  const { theme } = useTheme()

  return (
    <>
        <BannerWelcome
          logoBackground= {VectorHome}
          logoFarma = {LogoFarma}
          vector = {VectorCity}
          sizeVector='tw-w-[800px]'
        />
        {!theme?.is_lab &&
         <div
         id="banner-2"
         className="tw-bg-gradient-to-r tw-from-primary tw-to-tertiary tw-mt-20 md:tw-gap-0 tw-gap-5 tw-text-secondary sm:tw-p-14 tw-p-5 tw-flex-wrap  tw-w-full tw-justify-evenly  tw-flex  tw-left-0"
       >
         <Paragraphs weight='medium' size="4xl" className="tw-w-[460px]">
           Si quieres digitalizar tu farmacia de una manera cómoda, fácil y rápida, éste es tu lugar.
         </Paragraphs>
         <div className="tw-w-[600px] tw-max-w-[100%] tw-mt-4 lg:tw-mt-0 lg:tw-px-3">
           <Paragraphs size="lg">
             Farmápolis es un innovador y potente punto de encuentro entre laboratorios y farmacias, donde puedes consultar y descargar todo tipo de información de más de 35.000 referencias de parafarmacia y de decenas de laboratorios, ¡Y mucho más!
           </Paragraphs>
           <div className="bg-line-normal"></div>
         </div>
       </div>
        }
        </>
  )
}

export default SectionWelcome
