import {
  SET_TOKEN_SUCCESS,
  SET_USER_SUCCESS,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  BLOCK_SESSION,
  UPDATE_USER
} from '../constants/user'

const initialState = {
  user: null,
  token: null,
  isLoading: false,
  isError: false,
  blockSession: false
}

/**
 * @author ehernandez
 * @param {object} state state of the application
 * @param {object} action action to be executed
 * @returns {object}
 */
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        user: action.user,
        token: action.token
      }

    case SET_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        user: action.user
      }

    case USER_LOGOUT:
      return initialState

    case SET_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.token,
        user: action.user
      }

    case BLOCK_SESSION:
      return {
        ...state,
        blockSession: !state.blockSession
      }
    case UPDATE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.data
        }
      }
    default:
      return state
  }
}

export default userReducer
