import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { OffcanvasContent } from './styles'
import useListenerClose from '../../infrastructure/hooks/useListenerClose/useListenerClose'
import ReactDOM from 'react-dom'

/**
 * @author ehernandez
 * @description fade background for offcanvas
 * @param {bool} open
 * @returns
 */
const Offcanvas = ({
  open,
  onRequestClose,
  align,
  children,
  width,
  height,
  showIconClose
}) => {
  const [ref] = useListenerClose(onRequestClose, true)
  const isOpen = useMemo(() => {
    return open || false
  }, [open])
  const direction = useMemo(() => {
    switch (align) {
      case 'left':
        return 'tw-left-0 tw-top-0'
      case 'right':
        return 'tw-right-0 tw-top-0'
      case 'top':
        return 'tw-top-0'
      case 'bottom':
        return 'tw-bottom-0 tw-left-0'
      default:
        return 'tw-right-0'
    }
  }, [align])

  if (!isOpen) {
    return null
  }

  const heightContent = height === 'auto' ? '' : 'tw-h-full'
  const content = (
    <div className="tw-fixed tw-bg-black tw-bg-opacity-40 tw-w-screen tw-h-screen tw-top-0 tw-left-0 tw-z-50">
      <OffcanvasContent
        ref={ref}
        align={align}
        className={`tw-absolute tw-flex-col tw-flex tw-h-screen  tw-w-full tw-shadow ${direction} ${heightContent}`}
        style={{ maxWidth: width }}
      >
      {showIconClose && <div
          data-cy= 'close-modal-login'
          onClick={() => onRequestClose()}
          className="offcanvvas__close tw-flex tw-absolute tw-right-[20px] tw-top-0 tw-justify-end tw-w-[80px] tw-h-[80px]  tw-items-center  tw-cursor-pointer tw-bg-white"
        >
          <span className='icon-ic_cerrar_grande' style={{ fontSize: 20 }} />
        </div>}
        <div className="offcanvvas__content tw-flex-1 tw-overflow-hidden">{children}</div>
      </OffcanvasContent>
    </div>
  )

  return ReactDOM.createPortal(
    content,
    document.getElementById('modal-offcanvas')
  )
}

Offcanvas.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onRequestClose: PropTypes.func,
  align: PropTypes.oneOf(['left', 'right', 'bottom']),
  children: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOf(['auto', 'full']),
  showIconClose: PropTypes.bool
}

Offcanvas.defaultProps = {
  align: 'right',
  width: 450,
  showIconClose: true

}

export default Offcanvas
