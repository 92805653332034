import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Navigation, Pagination } from 'swiper'

const SliderProducts = ({
  children,
  className,
  id,
  navigation,
  slidesPerView,
  breakpoints,
  hideNavigation,
  spaceBetween
}) => {
  if (!Array.isArray(children)) {
    return null
  }

  const isMobile = useMemo(() => {
    return window.innerWidth < 768
  }, [])

  return (
    <div id={id} className={`slider-products ${className}`}>
        {!hideNavigation && !isMobile && <button
          className={`swiper-button-prev-${id} tw-rotate-180 hover:-tw-translate-x-2 hover:tw-ease-in tw-transition tw-top-0 tw-text-black after:tw-hidden tw-relative flex tw-items-center justify-center  text-center border-0 hover:outline-none hover:no-underline  tw-mr-5 focus:outline-none focus:no-underline`}
          type="button"
          data-bs-target={'#' + 'product-list'}
          data-bs-slide="prev"
        >
          <span
            style={{ fontSize: 50 }}
            className='icon-ic_arrow_right inline-block '
            aria-hidden="true"
          ></span>
        </button>}
        <div
          style={{ position: 'relative', bottom: 0 }}
          className={
            'swiper-pagination tw-flex tw-gap-1 tw-cursor-pointer tw-relative tw-text-center  tw-text-sm  tw-text-gray-400 tw-justify-center tw-items-center tw-flex-1 tw-bottom-0'
          }
        />
      <Swiper
        modules={[Navigation, Pagination]}
        pagination={isMobile ? { dynamicBullets: true } : false}
        spaceBetween={spaceBetween}
        navigation={{
          nextEl: '.swiper-button-next-' + id,
          prevEl: '.swiper-button-prev-' + id
        }}
        breakpoints={breakpoints}
        // pagination={{
        //   clickable: true,
        //   el: '.swiper-pagination',
        //   bulletClass:
        //     'tw-rounded-none tw-h-2 tw-w-2 tw-bg-white tw-block tw-px-1',
        //   bulletActiveClass: '!tw-bg-primary'
        // }}
        // scrollbar={{ draggable: true }}
        // slidesPerView={!breakpoints ? slidesPerView : null}
        className="tw-w-full  tw-flex tw-items-center "
      >
        {children?.map((i, index) => {
          return <SwiperSlide className='tw-my-auto' key={index}>{i}</SwiperSlide>
        })}
      </Swiper>
      { !hideNavigation && !isMobile && <div
        className={
          'navigation-bar tw-h-[60px]    tw-flex tw-gap-8 tw-items-center '
        }
      >

        <button
          className={`swiper-button-next-${id} tw-ml-5 tw-right-0 hover:tw-translate-x-2 hover:tw-ease-in tw-transition tw-top-0 tw-h-auto tw-text-black after:tw-hidden tw-relative tw-justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline`}
          type="button"
          data-bs-target={'#' + 'product-list'}
          data-bs-slide="next"
        >
          <span
            style={{ fontSize: 50 }}
            className=' icon-ic_arrow_right  inline-block '
            aria-hidden="true"
          />
        </button>
      </div>}
    </div>
  )
}

SliderProducts.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  navigation: PropTypes.bool,
  slidesPerView: PropTypes.number,
  breakpoints: PropTypes.object,
  spaceBetween: PropTypes.number,
  hideNavigation: PropTypes.bool
}

SliderProducts.defaultProps = {
  slidesPerView: 5,
  spaceBetween: 30
}

export default SliderProducts
