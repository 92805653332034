import {
  SET_TOKEN_SUCCESS,
  SET_USER_SUCCESS,
  USER_LOGOUT,
  BLOCK_SESSION,
  UPDATE_USER
} from '../constants/user'

/**
 * @author ehernandez
 * @param {object} user object to be saved
 * @returns {object}
 */
export const actionSetLoginSuccess = ({ access_token: accessToken, user }) => ({
  type: SET_TOKEN_SUCCESS,
  user,
  accessToken
})

/**
 * @author ehernandez
 * @param {object} user object to be saved
 * @returns {object}
 */
export const actionSetUserSuccess = (user) => ({
  type: SET_USER_SUCCESS,
  user
})

/**
 * @author ehernandez
 * @returns {object}
 */
export const actionUserLogout = () => ({
  type: USER_LOGOUT
})

/**
 * @author ehernandez
 * @param {string} token token user
 * @param {string} user id user
 * @param {string} isAdmin if user backoffice or is user sfera
 * @returns {object}
 */
export const actionSetTokenSuccess = (token, user) => ({
  type: SET_TOKEN_SUCCESS,
  token,
  user
})

/**
 * @author ehernandez
 * @returns {object}
 */
export const actionBlockSession = () => ({
  type: BLOCK_SESSION
})

/**
 *
 * @param {*} data
 * @returns
 */
export const actionUpdateUser = (data) => ({
  type: UPDATE_USER,
  data
})
