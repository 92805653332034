import styledComponents, { keyframes } from 'styled-components'

const translate = {
  left: keyframes`
        from {
          transform: translateX(-100%);
        }
      
        to {
          transform: translateX(0);
        }
      `,
  right: keyframes`
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
`,
  bottom: keyframes`
from {
  transform: translateY(100%);
}

to {
  transform: translateY(0);
}
`
}

export const OffcanvasContent = styledComponents.div`
animation: ${(props) => translate[props.align]} .5s ease forwards;
transform: translateX(-100%);
`
