import catalogoIcon from '../assets/images/home/catalogo.png'
import novedadesIcon from '../assets/images/home/Recurso-7.png'
import rsIcon from '../assets/images/home/rs.png'
import eCommerceIcon from '../assets/images/home/ecommerce.png'
import formacionesIcon from '../assets/images/home/formaciones.png'
import tarifasIcon from '../assets/images/home/tarifas.png'
import { format } from 'date-fns'

export const LINKS_HEADER = [
  {
    name: 'link-home',
    label: 'Inicio',
    href: '/'
  },
  {
    name: 'link-novedades',
    label: 'Novedades',
    href: '/product-news'
  },
  {
    name: 'link-catalogo',
    label: 'Catálogo',
    href: '/product-list'
  },
  {
    name: 'link-rss',
    label: 'Contenido RRSS',
    href: '/redes-sociales?month=' + format(new Date(), 'yyyy-MM-01')
  },
  {
    name: 'link-ecommerce',
    label: 'Material Ecom',
    href: '/ecommerce'
  },

  {
    name: 'link-formaciones',
    label: 'Formaciones',
    href: '/formaciones'
  },
  {
    name: 'link-tarifas',
    label: 'Tarifas',
    href: '/tarifas'
  },
  {
    name: 'link-contacto',
    label: 'Noticias',
    href: '/noticias'
  }
]

export const CARDS_HOME = [

  {
    label: 'Novedades',
    text: 'Entérate de las últimas novedades de producto que salen al mercado',
    href: 'https://hub.farmapolis.es/',
    search: '?news=latest',
    img: novedadesIcon
  },
  {
    label: 'Catálogo',
    text: 'Busca y descarga información de producto entre más de 34.000 referencias​',
    href: 'https://hub.farmapolis.es/',
    img: catalogoIcon
  },
  {
    label: 'Contenido RRSS',
    text: 'Enriquece tus Redes Sociales con contenido gratuito de calidad​',
    img: rsIcon,
    href: 'https://hub.farmapolis.es/'
  },
  {
    label: 'Material Ecom',
    img: eCommerceIcon,
    href: 'https://hub.farmapolis.es/',
    text: 'Actualiza tu e-commerce con contenido profesional'
  },
  {
    label: 'Formaciones',
    img: formacionesIcon,
    href: 'https://hub.farmapolis.es/',
    text: 'Estate al corriente, de una manera sencilla y ordenada, de próximas formaciones y Webinars​'
  },
  {
    label: 'Tarifas',
    href: 'https://hub.farmapolis.es/',
    img: tarifasIcon,
    text: 'Dispón de las tarifas PVL de los laboratorios actualizadas​'
  }

]
