import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs/Paragraphs'
import { useTranslation } from 'react-i18next'
import Content from '../Content'
import { LinkContainer } from './styles'

/**
 * @author ehernandez
 */
const Links = ({ label, color, colorHover, onClick, name, items, href, active, search }) => {
  const { t } = useTranslation()

  const activeItem = active ? 'before:tw-opacity-100 before:tw-bg-primary' : 'before:tw-opacity-0 hover:before:tw-opacity-100 before:tw-bg-black'
  return (
    <LinkContainer
      data-cy= {name}
      className="links tw-cursor-pointer tw-relative md:tw-flex "
    >
      <div
        onClick={() => onClick({ href, search })}
        className={`before:md:tw-left-0 tw-py-4 tw-flex tw-justify-between  tw-relative before:tw-absolute before:tw-w-full before:tw-h-[3px]  ${activeItem} before:-tw-bottom-0.5`}
      >
        <Paragraphs className={'tw-text-' + color} weight="bold">
          {t(label)}
        </Paragraphs>
        <div className='tw-text-right md:tw-hidden'>
          <span className='icon-ic_play_video' />
        </div>
      </div>
     {items && <div
        className="links-items tw-absolute tw-left-0 tw-top-10 tw-pt-2 tw-z-30 tw-min-w-full tw-overflow-none tw-bg-transparent"
      >
        <Content border={false} className=" tw-bg-white tw-rounded-[6px]">
          {items?.map((item) => (
            <div
              onClick={() => onClick(item)}
              className="tw-min-w-[200px]"
              key={item.id}
            >
              <Paragraphs size='xs' className={'hover:tw-underline tw-p-4'}>
                {t(item.label)}
              </Paragraphs>
            </div>
          ))}
        </Content>
      </div>}
    </LinkContainer>
  )
}

Links.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  colorHover: PropTypes.string,
  onClick: PropTypes.func,
  items: PropTypes.array,
  href: PropTypes.string,
  active: PropTypes.bool,
  search: PropTypes.string,
  name: PropTypes.string
}

Links.defaultProps = {
  color: 'secondary',
  colorHover: 'gray-800'
}

export default Links
