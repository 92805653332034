import Header from './components/Header'
import Container from './components/Container'
import Body from './components/Body'
import Footer from './components/Footer'
import HeaderPage from './components/HeaderPage/HeaderPage'
const Page = {
  Header,
  Container,
  Body,
  Footer,
  HeaderPage
}

export default Page
