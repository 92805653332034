import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import BlockAlert from '../../../components/BlockAlert'
import { useUser } from '../../hooks/useUser/useUser'
import Page from '../../../components/Page/Page'

/**
 *
 * @returns {React.Component}
 */
const AuthContext = ({ children, isPrivate }) => {
  const { token, getInfoUser } = useUser()

  useEffect(() => {
    if (token) { getInfoUser() }
  }, [token])

  if (isPrivate && !token) return <Page.Container className='tw-p-4 md:tw-p-0 md:tw-w-8/12 tw-mx-auto'><BlockAlert/></Page.Container>

  return children
}

AuthContext.propTypes = {
  children: PropTypes.node,
  isPrivate: PropTypes.bool
}

export default AuthContext
