import React from 'react'
import PropTypes from 'prop-types'
import useError from './hooks/useError'
import Paragraphs from '../../../Paragraphs'

/**
 * Error component.
 * This component is part of WrapperInput.
 * @author @jstarsky
 * @param {*} props
 * @return {JSX.Element}
 */
const Error = ({ id, test, type, size, message }) => {
  const { internalClassName, icon } = useError({ id, type, size })
  return (
    <div
      data-testid={test ? `${test}` : undefined}
      className={internalClassName}
    >
      <span className={`${icon} tw-ml-2`}></span>
      <Paragraphs size='xxxs'>{message}</Paragraphs>
    </div>
  )
}

Error.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  test: PropTypes.string,
  hidden: PropTypes.bool,
  message: PropTypes.string,
  type: PropTypes.oneOf([
    'alert',
    'warning',
    'required',
    'validate',
    'pattern'
  ]),
  size: PropTypes.oneOf(['xs', 'sm'])
}

Error.defaultProps = {
  id: 'wrapperInput-error',
  size: 'xs'
}

export default Error
