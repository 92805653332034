import React from 'react'
import Paragraphs from '../Paragraphs/Paragraphs'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { actionOpenLogin, actionOpenRegister } from '../../infrastructure/redux/actions/layoutActions'

const BlockAlert = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const onClickLogin = () => {
    dispatch(actionOpenLogin())
  }

  // eslint-disable-next-line no-unused-vars
  const onClickRegister = () => {
    dispatch(actionOpenRegister())
  }

  return (
    <div className="block-alert tw-bg-secondary tw-rounded-[6px] tw-my-auto">
      <div className="block-alert__content tw-p-8">
        <div className="block-alert__title tw-flex tw-items-center tw-gap-1">
          <span className=" icon-ic_bloqueado" />
          <Paragraphs weight="bold" size="xxs" className="tw-text-gray-600">
            {t('Bloqueado')}
          </Paragraphs>
        </div>
        <div className="block-alert__description">
          <Paragraphs weight="bold" size="lg" className="tw-mt-4">
            <span className='tw-text-primary tw-cursor-pointer tw-mr-2' onClick={onClickLogin}>Inicia sesión</span>
            {/* o <span className='tw-text-primary tw-cursor-pointer' onClick={onClickRegister}>Registrarte</span>  */}
            para acceder sin límites a todo el contenido disponible
          </Paragraphs>
        </div>
      </div>
    </div>
  )
}

export default BlockAlert
