/**
 *
 * @author ehernandez
 * @param {*} mode
 * @param {*} rounded
 * @param {*} uppercase
 * @returns {React.Component}
 */
export const useBtn = ({ mode, rounded, uppercase }) => {
  /**
   * Ontienen las clases por defecto segun el modo
   * @author   ehernandez --> nombre del creador
   * @param    {boolean} disabled
   * @return   {string}
   */
  const classFromMode = (disabled) => {
    let classes =
      'tw-flex tw-items-center tw-justify-center tw-px-6 tw-items-center tw-w-full tw-h-[40px] tw-rounded-[4px] '

    switch (mode) {
      case 'primary':
        classes += `tw-bg-primary tw-opacity-90 ${
          !disabled && 'hover:tw-opacity-100'
        } tw-text-white`
        break

      case 'white':
        classes += `tw-bg-white tw-opacity-90 ${
          !disabled && 'hover:tw-opacity-100'
        } tw-text-black`
        break
      case 'secondary':
        classes += 'tw-bg-secondary tw-text-white tw-rounded-[100px] '
        break
      case 'gradient':

        classes += `tw-bg-white ${!disabled ? 'tw-text-primary' : 'tw-text-white'}  tw-w-full tw-h-full tw-transition-all  tw-rounded-full ${!disabled && 'hover:tw-text-white hover:tw-bg-gradient-to-br tw-from-primary tw-to-tertiary'}`

        break

      case 'transparent':
        classes += `tw-bg-transparent ${
          !disabled && 'hover:tw-bg-tertiary hover:tw-text-white'
        } tw-text-tertiary tw-border-2 tw-border-tertiary`
        break

      case 'transparent-border':
        classes += `tw-bg-white ${
            !disabled && 'hover:tw-bg-tertiary hover:tw-text-white'
          } tw-text-tertiary tw-border-2 tw-border-tertiary`
        break

      case 'transparent-white-border':
        classes += `tw-bg-transparent ${
          !disabled &&
          'hover:tw-bg-white hover:tw-bg-opacity-10 hover:tw-text-white'
        } tw-text-primary tw-border-2 tw-border-white`
        break

      case 'expansible':
        classes +=
          'tw-bg-transparent tw-text-primary tw-border-l tw-border-t tw-border-r tw-border-primary tw-text-secondary expansible'
        break
      case 'register':
        classes += `tw-bg-transparent ${
          !disabled && 'hover:tw-bg-primary hover:tw-text-secondary'
        } tw-text-primary tw-border-2 tw-border-primary`
        break

      default:
        break
    }

    if (rounded) {
      classes += ' tw-rounded-full'
    }

    if (disabled) {
      classes += ' tw-bg-opacity-50 tw-bg-gray-100'
    }

    if (uppercase) {
      classes += ' tw-uppercase'
    }

    return classes
  }

  return { classFromMode }
}
