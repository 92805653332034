import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Paragraphs from '../Paragraphs'

const FilterOrderBy = ({ options, onChange }) => {
  const { t } = useTranslation()
  return (
    <div className="tw-flex tw-px-4 tw-w-full">
      <Paragraphs weight='bold' className="tw-flex tw-w-[120px] tw-pr-1 tw-font-light">Ordenar por:</Paragraphs>
      <select data-cy='select-order-products' className='tw-text-sm tw-w-full tw-outline-none' onChange={(event) => onChange(event.target.value)} defaultValue={options?.find((op) => op.default)?.id}>
        {options.map((op) => <option key={op.id} value={op.id}>{t(op.name)}</option>)}
      </select>
    </div>
  )
}

FilterOrderBy.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func
}

export default FilterOrderBy
