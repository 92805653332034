import React, { useContext, useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import colors from '../../styles/colors.js'
import useMutation from '../../hooks/useMutation/index.js'
import { GlobalStyle } from './styles.js'
/**
 *  @author ehernandez
 */
const ThemeContext = React.createContext()

/**
 *  @author ehernandez
 */
const ThemeProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)

  const [fetchBranding] = useMutation('branding')

  const [theme, setTheme] = useState({
    ...colors,
    logo: require('../../assets/images/logos/ic_farmapolis_color.svg').default,
    logo_footer: require('../../assets/images/logos/ic_farmapolis_blanco.svg').default
  })
  useLayoutEffect(() => {
    getTheme()
  }, [])

  /**
 *  @author ehernandez
 */
  const getTheme = async () => {
    const { success, data } = await fetchBranding({
      method: 'GET'
    })
    try {
      if (success && data?.result) {
        setTheme({
          ...colors,
          ...data?.result,
          logo: data?.result?.logo || require('../../assets/images/logos/ic_farmapolis_color.svg').default,
          logo_footer: data?.result?.logo_footer || require('../../assets/images/logos/ic_farmapolis_blanco.svg').default

        })
      }
    } catch (error) {
      setTheme({
        ...colors,
        logo: require('../../assets/images/logos/ic_farmapolis_color.svg').default

      })
    }

    setLoading(false)
  }

  return <ThemeContext.Provider value={{ theme }}>
    <GlobalStyle theme={theme} />
   {!loading && children}
</ThemeContext.Provider>
}

ThemeProvider.propTypes = {
  children: PropTypes.node
}
/**
 *  @author ehernandez
 */
const useTheme = () => useContext(ThemeContext)
export { ThemeContext, ThemeProvider, useTheme }
