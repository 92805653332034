export const INPUTS = {
  login: [
    {
      id: 'email',
      type: 'text',
      label: 'Correo electronico',
      name: 'email'
    },
    {
      id: 'password.login',
      type: 'password',
      label: 'Contraseña',
      name: 'password'
    }
  ],
  register: [
    {
      id: 'name',
      type: 'text',
      label: 'Nombre y apellidos',
      name: 'name'
    },
    {
      id: 'email',
      type: 'text',
      label: 'Correo electrónico',
      name: 'email'
    },
    {
      id: 'password',
      type: 'password',
      label: 'Contraseña',
      name: 'password'
    },
    {
      id: 'pharmacy_name',
      type: 'text',
      label: 'Farmacia',
      name: 'pharmacy_name'
    },
    {
      id: 'postcode',
      type: 'autocomplete',
      label: 'Código postal',
      name: 'postcode'
    },
    {
      id: 'street',
      type: 'text',
      label: 'Dirección',
      name: 'street'
    },

    {
      id: 'mobile',
      type: 'text',
      label: 'Teléfono',
      name: 'mobile'
    }

  ]
}
