import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs'
import { useTranslation } from 'react-i18next'
import Button from '../Button/Button'
import { INPUTS } from './constants/inputs'
import TextInput from '../TextInput/TextInput'
import { useForm } from 'react-hook-form'

/**
 * @author ehernandez
 * @description component for show register or login form
 * @param {function} onSubmitSuccess function to call when the form is successfully submitted
 * @returns
 */
const InfoUser = ({ onCloseSession, user }) => {
  const { t } = useTranslation()

  const { register, formState: { errors } } = useForm({
    defaultValues: { ...user }
  })

  return (
    <form
      data-cy='info-user-form'
      className="info-user tw-py-6 tw-h-full tw-flex tw-justify-between tw-flex-col tw-h-full tw-bg-white"
    >
      <div className="login__content tw-overflow-auto">
        <div className="login__form tw-px-14">
          <div className="login__form__title tw-pt-6 tw-text-center tw-flex tw-flex-col tw-justify-center tw-items-center">
          <div className="tw-h-[40px]  tw-w-[40px] tw-bg-black tw-rounded-full tw-text-white tw-items-center tw-flex tw-justify-center">
          <Paragraphs weight="bold" transform='uppercase'>
              {user?.name?.[0] + user?.name?.[1]}
            </Paragraphs>
          </div>
            <Paragraphs weight="bold" size="2xl" className="tw-mt-2">
              {t('¡Hola {{name}}!', { name: user?.name })}
            </Paragraphs>
          </div>
          <div className="login__form__fields tw-py-4">
            {INPUTS.map((input) => {
              return (
                <TextInput
                  error={errors[input.name]}
                  key={input.id}
                  register={register(input.name)}
                  label={input.label}
                  name={input.name}
                  type={input.type}
                  disabled={true}

                />
              )
            })}
          </div>
        </div>
      </div>
      <div className="login__content__footer tw-px-14">
        <Button
          cy='close-session-btn'
          transform="uppercase"
          mode="primary"

          type='button'
          label={t('Cerrrar sesión')}
          onClick={onCloseSession}
          iconRight={ <span className='icon-ic_cerrar_sesion' /> }
        />
      </div>
    </form>
  )
}

InfoUser.propTypes = {
  onCloseSession: PropTypes.func,
  user: PropTypes.object
}

export default InfoUser
