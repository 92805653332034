import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import EcommerceCard from '../../../../components/EcommerceCard/EcommerceCard'
import useMutation from '../../../../infrastructure/hooks/useMutation'
import { BASE } from '../../../../infrastructure/constants/configurations'

const WrapperTarifasCard = (props) => {
  const { isEcommerce } = props
  const { data } = props
  const { handleCheck } = props
  const { index } = props

  const [fecthLabDetail, { loading }] = useMutation('labs')

  const [selecteds, setSelecteds] = useState([])

  const [docs, setDocs] = useState(null)
  const { id } = props

  /**
   * @author: ehernandez
   * @description when clicked on card when isEcommerce or force download
   */
  const onClickCard = async (force) => {
    const { data } = await fecthLabDetail({
      method: 'GET',
      addToURL: `/${id}/documents`
    })
    if (data?.result) {
      setDocs(data.result.items)
    }
  }

  /**
   * @author: ehernandez
   * @description when click on download button, if selected or download all
   */
  const onClickDownloadAll = async () => {
    let links = null
    if (selecteds.length > 0) {
      links = selecteds.join(',')
    } else {
      const ids = []
      docs.forEach(i => {
        ids.push(i.id)
      })
      links = ids.join(',')
    }

    const a = document.createElement('a')
    a.href = BASE + `labs/${id}/documents_download?documents=${links}`
    a.download = 'FILENAME'
    a.target = '_blank'
    a.click()
  }

  /**
   * @author: ehernandez
   * @description when clicked on laboratory
   */
  const onSelected = (item) => {
    const index = selecteds.findIndex(i => item === i)
    if (index === -1) {
      setSelecteds([...selecteds, item])
    } else {
      setSelecteds(selecteds.filter(i => item !== i))
    }
  }

  const onClickDownloadSingle = (idItem) => {
    const a = document.createElement('a')
    a.href = BASE + `labs/${id}/documents_download?documents=${idItem}`
    a.download = 'FILENAME'
    a.target = '_blank'
    a.click()
  }

  useEffect(() => {
    setSelecteds([])
    setDocs(data?.ecommerce_documents)
  }, [isEcommerce])

  return (
      <div data-cy={`ecommerce-card-${index}`} className='tw-w-[320px] tw-h-[320px]  tw-relative'>
         {loading && <div className='tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-full tw-bg-white tw-bg-opacity-90 tw-z-20' />}
            <EcommerceCard
               index={index}
               handleCheck={handleCheck}
               id={data?.id}
               isEcommerce={isEcommerce}
               onClickDownload={onClickDownloadSingle}
               onSelected={onSelected}
               onClickDownloadAll={onClickDownloadAll}
               items={docs}
               {...props}
               onClick={onClickCard} />
      </div>

  )
}

WrapperTarifasCard.propTypes = {
  props: PropTypes.object,
  id: PropTypes.number,
  isEcommerce: PropTypes.bool,
  download_csv_url: PropTypes.string,
  data: PropTypes.object,
  onSelectedEcommerce: PropTypes.func,
  handleCheck: PropTypes.func,
  index: PropTypes.number
}

export default WrapperTarifasCard
