/**
 * useError
 * @author @jstarsky
 * @param {*} input
 * @return {Object}
 */
const useShow = ({ id, type, size, show, color }) => {
  /**
   * setType
   * @author @jstarsky
   * @param {String} input
   * @return {String}
   * https://tailwindcss.com/docs/font-family#header
   */
  const setIcon = (input) => {
    switch (input) {
      case 'eye':
        if (show) return 'icon-ic_contrasena_mostrar'
        return 'icon-ic_contrasena_ocultar'
      default:
        if (show) return 'icon-ic_contrasena_mostrar'
        return 'icon-ic_contrasena_ocultar'
    }
  }
  /**
   * setColor
   * @author @jstarsky
   * @param {String} input
   * @return {String}
   * https://tailwindcss.com/docs/font-family#header
   */
  const setColor = (input) => {
    switch (input) {
      case 'primary':
        return ' tw-text-primary'
      case 'secondary':
        return ' tw-text-secondary'
      case 'tertiary':
        return ' tw-text-tertiary'
      case 'black':
        return ' tw-text-black'
      case 'white':
        return ' tw-text-white'
      default:
        return ''
    }
  }

  /**
   * setSize
   * @author @jstarsky
   * @param {String} input
   * @return {String}
   * https://tailwindcss.com/docs/font-size#header
   */
  const setSize = (input) => {
    switch (input) {
      case 'xs':
        return ' tw-text-xs'
      case 'sm':
        return ' tw-text-sm'
      default:
        return ' tw-text-sm'
    }
  }

  return {
    internalClassName: `${id} tw-leading-none tw-cursor-pointer clearfix bshadow0 tw-flex tw-items-center  pbs${setColor(
      color
    )}${setSize(size)}`,
    icon: setIcon(type)
  }
}
export default useShow
