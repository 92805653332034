import React, { useState } from 'react'
import Content from '../../components/Content'
import FilterOrderBy from '../../components/FilterOrderBy'
import Page from '../../components/Page/Page'
import ProductListFavorits from './components/ProductListFavorits'
import RssList from './components/RssList'

const MODES = [
  {
    id: 'products',
    name: 'Productos',
    value: 'products'
  },
  {
    id: 'rss',
    name: 'RSS',
    value: 'rss'
  }
]

/**
 * @author ehernandez
 * @returns
 */
const FavoritesScreen = () => {
  const [mode, setMode] = useState('products')
  const onChangeMode = (i) => {
    setMode(i)
  }

  return (
    <Page.Container name="product-listing-screen" id="product-listing-screen">
      <Page.Body className="tw-relative tw-flex tw-flex-col tw-flex-wrap lg:tw-flex-nowrap">
        <div className="product-listing-screen__content__filters tw-px-5 tw-w-full md:tw-max-w-[500px] ">
        <Content className=" tw-w-full  tw-flex tw-items-center tw-h-[60px]">
            <FilterOrderBy options={MODES} onChange={onChangeMode} />
        </Content>
        </div>
        {mode === 'products' ? <ProductListFavorits /> : <RssList />}
      </Page.Body>
    </Page.Container>
  )
}

export default FavoritesScreen
