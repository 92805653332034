import React from 'react'
import PropTypes from 'prop-types'
import useWrapperInput from './hooks/useWrapperInput'
import Error from './components/Error'
import Description from './components/Description'
import Icon from './components/Icon'
import Show from './components/Show'
import Label from '../Label/Label'
import Paragraphs from '../Paragraphs'

/**
 * WrapperInput component.
 * TODO - PROBLEMS WITH PADING FOR THIS REASON THE CLASS HAS THE PX CLASSNAME
 * TODO - PROBLEMS WITH THE SIZE OF FONT SIZE OF THE LABEL
 * @author @jstarsky
 * @param {*} props
 * @return {JSX.Element}
 */
const WrapperInput = React.forwardRef(
  (
    {
      type,
      className,
      children,
      disabled,
      flex,
      id,
      label,
      labelStyle,
      required,
      inputSize,
      error,
      description,
      hidden,
      button,
      hiddeStyles,
      background,
      show,
      securityEntry,
      setSecurityEntry
    },
    ref
  ) => {
    const {
      internalClassName,
      disabledModeClassName,
      flexModeClassName,
      labelModeClassName,
      errorModeClassName,
      buttonModeClassName,
      backgroundModeClassName,
      // emptyParagraphs,
      labelSize,
      classNameHeigthSize
    } = useWrapperInput({
      id,
      error,
      flex,
      disabled,
      hidden,
      button,
      paragraphsSize: 'xxs',
      type,
      hiddeStyles,
      background,
      inputSize
    })

    return (
      <div id={id} className={internalClassName}>
        <div className={flexModeClassName}>
          {label &&
            type !== 'checkbox' &&
            type !== 'button_checkbox' &&
            type !== 'button_radio' &&
            type !== 'pin_field' &&
            type !== 'radio' && (
              <div className="tw-flex tw-justify-between tw-items-center tw-min-h-[28px]">
                <>
                  <Label
                    htmlFor={`${id}_for_${type}`}
                    className={labelModeClassName}
                    label={label}
                    required={required}
                    {...labelStyle}
                  />
                  {error || show
                    ? (
                    <div className="tw-flex tw-gap-1">

                      {show && (
                        <Show
                          {...show}
                          test={show.id || `${id}wrapper_input_show_button`}
                        />
                      )}
                    </div>
                      )
                    : (
                    <></>
                      )}
                </>
              </div>
          )}
          <div className={`${backgroundModeClassName}`}>
            <label
              htmlFor={
                type === 'button_checkbox' || type === 'button_radio'
                  ? `${id}_for_${type}`
                  : ''
              }
              className={
                type === 'pin_field'
                  ? ' tw-flex tw-grow tw-flex-col'
                  : `${
                      type === 'button_checkbox' || type === 'button_radio'
                        ? 'tw-flex tw-flex-1 tw-flex-col'
                        : ''
                    }`
              }
            >
              <div
                ref={ref}
                className={`tw-rounded-[6px] ${
                  type !== 'pin_field' && type !== 'pin_field_wrapper'
                    ? 'tw-flex tw-flex-1 tw-content-center tw-h-full tw-w-full '
                    : `${classNameHeigthSize} `
                }${
                  `${type}` === 'checkbox' ||
                  `${type}` === 'button_checkbox' ||
                  `${type}` === 'button_radio' ||
                  `${type}` === 'radio' ||
                  `${type}` === 'pin_field_wrapper'
                    ? `tw-border tw-border-transparent tw-items-center tw-gap-1${
                        `${type}` === 'button_checkbox' ||
                        `${type}` === 'button_radio'
                          ? ' tw-cursor-pointer tw-select-none'
                          : ''
                      }`
                    : 'tw-gap-3'
                }${button && button.reverse ? ' tw-flex-row-reverse' : ''}${
                  className
                    ? ` ${className}`
                    : ' tw-py-2 tw-px-2 tw-flex-nowrap'
                }${disabledModeClassName}`}
              >
                {children}
                {type === 'password' && <div className='tw-flex tw-item-center' onClick={() => setSecurityEntry(!securityEntry)}>
                  {securityEntry ? <span className='icon-ic_mostrar_contrasea'></span> : <span className='icon-ic_ocultar_contrasea'></span>}
                </div> }
                {button &&
                  (() => {
                    if (button && button.label) {
                      return (
                        <div
                          onClick={button && button.onClick}
                          className={buttonModeClassName}
                        >
                          <Paragraphs size={labelSize} weight="bold">
                            <span className="tw-text-primary tw-leading-none">
                              {button.label}
                            </span>
                          </Paragraphs>
                        </div>
                      )
                    }
                    if (button && button.icon) {
                      return (
                        <div
                          onClick={button && button.onClick}
                          className={buttonModeClassName}
                        >
                          <Icon icon={button.icon} size="sm" />
                          {/* {emptyParagraphs} */}
                        </div>
                      )
                    }
                    return <></>
                  })()}
                {(type === 'checkbox' ||
                  type === 'button_checkbox' ||
                  type === 'button_radio' ||
                  type === 'radio') &&
                  type !== 'pin_field' &&
                  label && (
                    <div
                      className={`${
                        type === 'button_checkbox' || type === 'button_radio'
                          ? 'tw-leading-none tw-w-flex tw-flex-1'
                          : 'tw-leading-none'
                      }`}
                    >
                      <Label
                        htmlFor={`${id}_for_${type}`}
                        className={`${labelModeClassName} tw-truncate tw-cursor-pointer tw-select-none`}
                        label={label}
                        required={required}
                        {...labelStyle}
                      />
                    </div>
                )}
              </div>
              <div className="tw-relative">
                <div className={`${errorModeClassName}`} />
                <Description {...description} />
              </div>
              <div className='tw-py-1 -tw-mx-2'>
              {error && <Error {...error} />}
              </div>
            </label>
          </div>
        </div>
      </div>
    )
  }
)
WrapperInput.propTypes = {
  securityEntry: PropTypes.bool,
  setSecurityEntry: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.oneOf([
    'masked',
    'checkbox',
    'button_checkbox',
    'button_radio',
    'pin_field',
    'pin_field_wrapper',
    'radio',
    'email',
    'password',
    'tel',
    'text'
  ]),
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  disabled: PropTypes.bool,
  flex: PropTypes.oneOf(['between', 'row']),
  label: PropTypes.string,
  labelStyle: PropTypes.shape({
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'tertiary',
      'white',
      'black'
    ]),
    italic: PropTypes.bool,
    transform: PropTypes.oneOf(['uppercase', 'lowercase', 'capitalize']),
    weight: PropTypes.oneOf(['bold', 'normal'])
  }),
  required: PropTypes.bool,
  inputSize: PropTypes.oneOf(['xs', 'xl']),
  error: PropTypes.shape({
    hidden: PropTypes.bool,
    message: PropTypes.string,
    type: PropTypes.oneOf([
      'alert',
      'warning',
      'required',
      'validate',
      'pattern'
    ])
  }),
  description: PropTypes.shape({
    hidden: PropTypes.bool,
    message: PropTypes.string,
    type: PropTypes.oneOf([
      'info',
      'alert',
      'warning',
      'required',
      'validate',
      'pattern',
      'info'
    ]),
    alignment: PropTypes.oneOf(['center', 'end', 'start'])
  }),
  hidden: PropTypes.bool,
  button: PropTypes.shape({
    icon: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    reverse: PropTypes.bool,
    className: PropTypes.string
  }),
  hiddeStyles: PropTypes.bool,
  background: PropTypes.oneOf([
    'transparent',
    'primary',
    'secondary',
    'tertiary',
    'white',
    'black'
  ]),
  show: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hidden: PropTypes.bool,
    show: PropTypes.bool,
    type: PropTypes.oneOf(['eye']),
    onClick: PropTypes.func,
    size: PropTypes.oneOf(['xs', 'xl']),
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'tertiary',
      'white',
      'black'
    ])
  })
}
WrapperInput.defaultProps = {
  id: 'wrapperInput',
  labelStyle: {}
}
WrapperInput.displayName = 'WrapperInput'
export default WrapperInput
