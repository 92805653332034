import React from 'react'
import Paragraphs from '../../../../components/Paragraphs'
import SliderProducts from '../../../../components/SliderProducts'
import SocialCard from '../../../../components/SocialCard/SocialCard'
import { useFavorites } from '../../../../infrastructure/hooks/useFavorites/useFavorites'
import { useUser } from '../../../../infrastructure/hooks/useUser/useUser'
import propTypes from 'prop-types'

const SliderRrss = ({ info }) => {
  const { addRemoveFav: handleFav } = useFavorites()

  const { token } = useUser()
  return (
        <div className='tw-mx-auto md:tw-px-5 tw-px-1 tw-py-8 tw-mt-8 tw-gap-10 tw-flex tw-flex-col tw-w-full tw-bg-secondary'>
          <Paragraphs weight={'bold'} size= '2xl' className={'md:tw-pl-[110px] tw-text-center md:tw-text-left'}>Contenido RRSS</Paragraphs>
          <div id='newness' className='tw-w-full'>
            <SliderProducts
            className='tw-flex tw-mx-auto tw-items-center md:tw-w-[95%]'
            id='content-rsss'
            spaceBetween={10}
             breakpoints={{
               640: {
                 slidesPerView: 1
               },
               768: {
                 slidesPerView: 2
               },
               900: {
                 slidesPerView: 2
               },
               1200: {
                 slidesPerView: 3
               }
             }}
             navigation={true}
             hideNavigation={false}
            >

              {info?.rrss_contents.map(product => (
                <div key={product.id} className=' lg:tw-h-[350px] tw-w-[250px] tw-mx-auto tw-h-[240px]  md:tw-p-2 lg:tw-w-[370px]'>
                <SocialCard
                onClickDownload={() => {
                  const a = document.createElement('a')
                  a.href = product.download_url
                  a.download = 'FILENAME'
                  a.target = '_blank'
                  a.click()
                }}
                onClickFav={(active) => {
                  handleFav(product.id, active, 'rrss')
                }
                }
                  image={product.url}
                  isFav={product.is_favorite}
                  name={product.title}
                  manufacturer={product.subtitle}
                  isLogged={!!token}
                />
                </div>
              ))}

            </SliderProducts>
          </div>

          </div>
  )
}

export default SliderRrss

SliderRrss.propTypes = {
  info: propTypes.object
}
