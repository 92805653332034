import axios from 'axios'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BASE_URL } from '../../constants/configurations'
import { actionSetTokenSuccess } from '../../redux/actions/user'
// import { actionUserLogout } from '../../redux/actions/user'

/**
   * Hook for create fetch api rest
   *
   *
   * @author   ehernandez
   * @param    {String}   endpoint  endpoint to call
   */
export const useMutation = (endpoint) => {
  const [loading, setLoading] = useState(false)
  const { token } = useSelector(state => state.userReducer)
  const dispatch = useDispatch()

  /**
   * Custom fetch mutation for axios
   *
   *
   * @author   ehernandez
   * @param    {Object} data
   * @param    {String}   data.method   method api
   * @param    {String}   data.data     info to send to body
   * @param    {String}   data.addToURL
   */
  const fetch = async ({ method = 'post', data, addToURL = '', authToken }) => {
    let queryString = ''
    setLoading(true)
    const URL = BASE_URL
    const config = {
      method,
      url: URL + endpoint + addToURL
    }

    if (data) {
      if (method === 'get') {
        queryString = Object.keys(data).map(key => key + '=' + data[key] || '').join('%26')
        const queriesString = queryString.replace(null, '')
        config.url += '?filter=' + queriesString
      }
      config.data = data
    }

    config.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`
    }
    return await axios(config)
      .then((response) => {
        setLoading(false)
        if (response.status !== 200 && response.status !== 202) {
          return {
            success: false,
            error: response
          }
        }
        return {
          success: true,
          data: response.data
        }
      })
      .catch(err => {
        setLoading(false)
        if (!err?.response?.status) {
          return {
            success: false
          }
        }
        const { status } = err?.response
        if (status === 401) {
          dispatch(actionSetTokenSuccess(null, null))
        }

        return {
          success: false,
          error: err?.response?.data
        }
      })
  }

  return [fetch, { loading }]
}
