import React, { } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'
import Layout from './infrastructure/components/Layout'
import AuthContext from './infrastructure/context/AuthContext/AuthContext'
import { ThemeProvider } from './infrastructure/context/ThemeContext/ThemeContext'
import { persistor, store } from './infrastructure/redux/store'
import FavoritesScreen from './Pages/FavoritesScreen'
import FormationScreen from './Pages/FormationScreen'
import HomeScreen from './Pages/HomeScreen/HomeScreen'
import NewsScreen from './Pages/NewsScreen'
import ProductsDetailScreen from './Pages/ProductDetailScreen'
import ProductsListingScreen from './Pages/ProductsListingScreen'
import RSSScreen from './Pages/RSSScreen'
import TarifasScreen from './Pages/TarifasScreen'
import 'react-toastify/dist/ReactToastify.css'
import LaboratoriesScreen from './Pages/LaboratoriesScreen/LaboratoriesScreen'
import { UrlContextProvider } from './infrastructure/context/UrlParamsContext/UrlParamsContext'
import NewsProductsScreen from './Pages/NewsProductsScreen/NewsProductsScreen'
import MaterialEcommerceScreen from './Pages/MaterialEcommerceScreen'
import AuthScreen from './Pages/AuthScreen'
import AvisoLegal from './components/AvisoLegal'
import PoliticaPrivacidad from './components/PoliticaPrivacidad'
import PoliticaCookies from './components/PoliticaCookies'
import FarmacloudCodeAuth from './Pages/FarmacloudCodeAuth'
import TagManager from 'react-gtm-module'

/**
 * @type {{gtmId: string}}
 * @description GTM ID
 */
const tagManagerArgs = {
  gtmId: 'GTM-WG9487T'
}

TagManager.initialize(tagManagerArgs)

/**
 *
 * @returns {React.Component}
 */
const App = () => {
  return (
    <Provider store={store}>
      <UrlContextProvider>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <ToastContainer
            position="top-left"
            autoClose={3000}
            hideProgressBar={true}
            newestOnTop={false}
            pauseOnHover={false}
            closeButton={false}
            rtl={false}
          />
          <BrowserRouter>
            <Routes>
              <Route path="*" element={<Layout />}/>
              <Route path="/" element={<Layout />}>
                <Route
                  name="home"
                  index
                  element={
                    <AuthContext>
                      <HomeScreen />
                    </AuthContext>
                  }
                />
                <Route
                name="home"
                  index
                  element={
                    <AuthContext>
                      <HomeScreen />
                    </AuthContext>
                  }
                />
                 <Route
                path="/reset-password"
                  index
                  element={
                    <AuthContext>
                      <HomeScreen />
                    </AuthContext>
                  }
                />
                <Route
                  path="product-list"
                  element={
                    <AuthContext>
                      <ProductsListingScreen />
                    </AuthContext>
                  }
                />
               <Route
                  path="product-news"
                  element={
                    <AuthContext>
                      <NewsProductsScreen />
                    </AuthContext>
                  }
                />
                <Route
                  path="product-list/:id"
                  element={
                    <AuthContext isPrivate>
                      <ProductsDetailScreen />
                    </AuthContext>
                  }
                />
                <Route
                  path="redes-sociales"
                  element={
                    <AuthContext isPrivate>
                      <RSSScreen />
                    </AuthContext>
                  }
                />
               <Route
                  path="redes-sociales/tematicas"
                  element={
                    <AuthContext isPrivate>
                      <RSSScreen thematic />
                    </AuthContext>
                  }
                />
               <Route
                  path="formaciones"
                  element={
                    <AuthContext isPrivate>
                      <FormationScreen />
                    </AuthContext>
                  }
                />
                <Route
                  path="noticias"
                  element={
                    <AuthContext isPrivate>
                      <NewsScreen />
                    </AuthContext>
                  }
                />

                <Route
                  path="ecommerce"
                  element={
                    <AuthContext isPrivate>
                      <MaterialEcommerceScreen />
                    </AuthContext>
                  }
                />
                <Route
                  path="tarifas"
                  element={
                    <AuthContext isPrivate>
                      <TarifasScreen />
                    </AuthContext>
                  }
                />
                <Route
                  path="favoritos"
                  element={
                    <AuthContext isPrivate>
                      <FavoritesScreen />
                    </AuthContext>
                  }
                />
                 <Route
                  path="laboratorios/:id"
                  element={
                    <AuthContext isPrivate>
                      <LaboratoriesScreen />
                    </AuthContext>
                  }
                />
                <Route
                  path="aviso-legal-y-terminos-de-uso"
                  element={

                      <AvisoLegal />

                  }
                />
                <Route
                  path="politica-de-privacidad"
                  element={

                      <PoliticaPrivacidad />

                  }
                />
                 <Route
                  path="politica-de-cookies"
                  element={

                      <PoliticaCookies />

                  }
                />
              </Route>
              <Route
                path="/auth/:authToken"
                element={<AuthScreen/>}
              />
              <Route
                path="/farmacloud/code"
                element={<FarmacloudCodeAuth />}
              />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
      </UrlContextProvider>
    </Provider>
  )
}

export default App
