import React from 'react'
import PropTypes from 'prop-types'

const Badge = ({ children, onClick, className, size, bgColor, color, id }) => {
  return (
    <div
    data-cy={id}
    style={{
      height: size,
      width: size
    }}
    onClick={onClick} className={` badge tw-text-${color} tw-w-full tw-h-full tw-cursor-pointer hover:tw-bg-black hover:tw-bg-opacity-10 tw-flex tw-items-center tw-bg-${bgColor} tw-justify-center ${className}`} >
        {children}
    </div>
  )
}

Badge.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.number,
  bgColor: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'success', 'danger', 'warning', 'page']),
  color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'success', 'danger', 'warning', 'page'])
}

Badge.defaultProps = {
  size: 40
}

export default Badge
