import React from 'react'
import Paragraphs from '../Paragraphs'

const AvisoLegal = () => {
  return (
    <div
      id="avisoLegal"
      className="tw-w-[80%] tw-mx-auto tw-flex tw-flex-col tw-gap-5 tw-py-10"
    >
      <Paragraphs className={'tw-text-primary'} fontFamily={'Trade'} size="xl">
        Aviso Legal y Términos de uso
      </Paragraphs>
      <Paragraphs>
        Los términos y condiciones recogidos a continuación tienen por finalidad
        informar, establecer y regular las normas de uso de la Web
        www.farmapolis.es, entendiendo por ésta todas las páginas y sus
        contenidos a las cuales se accede a través del dominio www.farmapolis.es
        y sus subdominios.
      </Paragraphs>
      <div className='docs'>
        <Paragraphs weight={'medium'}>
          1. Datos identificativos del titular.
        </Paragraphs>
        <Paragraphs>
          El titular de la Web www.farmapolis.es (en adelante Web) es la
          sociedad REPOFARMA, S.L., con domicilio social en la Calle Miguel
          Angel nº 4, bajo 10, Madrid – 28010, con C.I.F: B-40638751 e inscrita
          en el Registro Mercantil de Madrid, Tomo 40925, Libro 0 Folio 133,
          Sección 8, Hoja M-725934 (en adelante REPOFARMA). La Web es una
          Plataforma de contenidos (entendiendo por éstos imágenes,
          ilustraciones, PDFs, software, diseños, iconos y cualquier otro
          contenido) generados por REPOFARMA o por parte de terceros que hayan
          licenciado o autorizado su contenido, u obtenido de fuentes públicas,
          que informa y provee a los USUARIOS de diversas funcionalidades y
          contenidos del sector farmacéutico (en adelante designado como
          Servicios).
        </Paragraphs>
      </div>
      <div className='docs'>
        <Paragraphs weight={'medium'}>2. Acceso a la Web.</Paragraphs>
        <Paragraphs></Paragraphs>
      </div>
      <div className='docs'>
        <Paragraphs weight={'medium'}>2.1. Generalidades</Paragraphs>
        <Paragraphs>
          El acceso a la Web, así como la utilización de los Servicios se
          realiza mediante el alta como USUARIO, lo que implica la aceptación
          plena y sin reservas de todos y cada uno de los presentes Términos y
          Condiciones de Uso. Si el USUARIO decide no aceptar, debe abstenerse
          de acceder a la Web y darse de alta como USUARIO. El USUARIO se
          compromete a usar la Web y los Servicios, de la manera y en la forma
          que en la misma se establecen, quedando obligado a no utilizar los
          mismos con fines ilícitos y/o contrarios a los establecidos en estos
          Términos y Condiciones de Uso.
        </Paragraphs>
      </div>
      <div className='docs'>
        <Paragraphs weight={'medium'}>2.2. Carácter profesional</Paragraphs>
        <Paragraphs>
          La Web y los Servicios ofrecidos en la misma, están exclusivamente
          dirigidos a profesionales del sector farmacéutico. No será por tanto
          de aplicación en la citada relación la normativa vigente en materia de
          consumidores y usuarios.
        </Paragraphs>
      </div>
      <div className='docs'>
        <Paragraphs weight={'medium'}>2.3. Registro como Usuario.</Paragraphs>
        <Paragraphs>
          Es requisito indispensable para acceder y utilizar los Servicios, que
          el USUARIO se registre previamente a través de los procedimientos
          habilitados al efecto, aceptando estos Términos y Condiciones de Uso y
          la Política de Privacidad. REPOFARMA se reserva el derecho de
          comprobar la veracidad de la información facilitada por el USUARIO al
          registrarse en la Web. El USUARIO es responsable de la veracidad,
          exactitud y actualización de la información facilitada; en este
          sentido, queda prohibida la utilización de identidades falsas y la
          suplantación, de cualquier forma, de la identidad de terceros
          (incluyendo el uso de sus datos, contraseñas o claves). El USUARIO
          deberá custodiar de forma diligente el nombre de usuario y contraseña
          de acceso, respondiendo personalmente de su uso indebido y exonerando
          a REPOFARMA de cualquier responsabilidad que pudiera derivarse de su
          pérdida o acceso ilegítimo por parte de un tercero. En esos casos, así
          como para el supuesto de olvido de la contraseña, REPOFARMA tiene
          habilitado un sistema para recuperarla.
        </Paragraphs>
      </div>
      <div className='docs'>
        <Paragraphs weight={'medium'}>3. Uso de los Servicios.</Paragraphs>
      </div>
      <div className='docs'>
        <Paragraphs weight={'medium'}>3.1. Uso autorizado.</Paragraphs>
        <Paragraphs>
          REPOFARMA permite al USUARIO acceder a los Servicios disponibles en la
          Web y utilizarlos con arreglo a estos Términos y Condiciones de Uso.
          Los Servicios son gratuitos y tienen por objeto reunir y difundir
          información del sector farmacéutico. Los Usuarios registrados podrán
          visualizar la información contenida en la Web y efectuar downloads o
          reproducciones en su sistema informático e imprimir, siempre que los
          elementos reproducidos no sean cedidos posteriormente a terceros. De
          este modo REPOFARMA concede al USUARIO un derecho de uso no exclusivo,
          individual e intransferible, revocable y limitado, para acceder y usar
          profesionalmente el Servicio y sus contenidos de acuerdo con estos
          Términos y Condiciones de Uso. El USUARIO, deberá utilizar los
          Servicios, de forma diligente, correcta y lícita, siempre y cuando no
          se elimine o modifique el contenido o cualquier mención de fuentes,
          copyright y demás datos identificativos de derechos de REPOFARMA o de
          terceros, es decir respetando su forma original. Los Servicios están
          destinados principalmente, a profesionales farmacéuticos con
          residencia en España o que accedan desde España, por lo que REPOFARMA
          declina toda responsabilidad por las consecuencias que pudieran
          derivarse del acceso efectuado desde jurisdicciones donde la
          distribución o uso de dichos contenidos pudieran ser contrarios a la
          normativa existente en ese país. El registro implica la aceptación por
          el USUARIO de las condiciones de tratamiento de sus datos de carácter
          personal que se describen en la Política de Privacidad. Dicha
          aceptación se configura como la contraprestación por los Servicios,
          por lo que la no aceptación de esas condiciones implicará la
          imposibilidad de acceder a o utilizar aquellos. No obstante lo
          anterior, REPOFARMA se reserva la posibilidad de exigir a los USUARIOS
          en el futuro el abono de alguna otra contraprestación por el acceso a
          los Servicios. Estas nuevas condiciones, en su caso, se publicarían en
          la Web con anterioridad a su entrada en vigor. En todo caso, el
          USUARIO podrá desistir de acceder a los Servicios o darse de baja. El
          precio, en su caso, sólo sería exigible a partir de la fecha de
          entrada en vigor de las nuevas condiciones. REPOFARMA realizará todos
          los esfuerzos razonables para que los Servicios ofrecidos a través de
          la Web se encuentren disponibles permanentemente, el USUARIO debe
          tener en cuenta que pueden existir interrupciones o limitaciones de
          acceso o uso derivadas de hechos accidentales e imprevistos o de la
          realización de acciones necesarias para llevar a cabo operaciones de
          mejora, mantenimiento o análogas.
        </Paragraphs>
      </div>
      <div className='docs'>
        <Paragraphs weight={'medium'}>3.2. Uso no autorizado.</Paragraphs>
        <Paragraphs>
          Teniendo en cuenta que el objetivo de los Servicios que proporciona
          REPOFARMA son con fines de información profesional, se prohíbe al
          USUARIO:
        </Paragraphs>
        <ul className="tw-ml-10 tw-mt-5 tw-flex tw-flex-col tw-gap-1 tw-w-2/3 tw-list-disc tw-font-light tw-text-sm">
          <li className='tw-list-disc'>
            realizar usos que lesionen o dañen los derechos o intereses de
            REPOFARMA, de otros USUARIOS o de terceros; en especial, derechos de
            propiedad intelectual, industrial o análogos.
          </li>
          <li>
            la difusión o gestión de contenidos que porten códigos maliciosos o
            programas informáticos que puedan interrumpir, destruir o dañar el
            funcionamiento de cualquier aplicación, equipo informático o sistema
            de telecomunicaciones.
          </li>
          <li>
            realizar actividades que dañen, deterioren o inutilicen la Web, sus
            funcionalidades o contenido y los equipos, sistemas y redes que les
            dan soporte, ni acceder o intentar acceder indebidamente a aquellos
            apartados a los que no tenga acceso ni interrumpir, interceptar,
            manipular o perjudicar el uso de los servicios por otros USUARIOS.
          </li>
          <li>
            modificar, eliminar o manipular los símbolos existentes en la Web ni
            en los contenidos, que reflejen derechos de propiedad intelectual o
            industrial de REPOFARMA o de terceros o los dispositivos técnicos
            establecidos para la identificación o protección de contenidos ni
            alterar, retirar o manipular estos en modo alguno.
          </li>
          <li>
            obtener, descargar y almacenar los contenidos o bases de datos,
            tanto si se realizasen de modo manual como a través de programas
            informáticos diseñados con dicha finalidad, como spider software
            (programas de rastreo) o análogos.
          </li>
          <li>
            copiar, reformatear, compartir y redistribuir los datos de manera
            masiva;
          </li>
          <li>efectuar raspados web;</li>
          <li>
            usar técnicas de framing o cualesquiera otras para esconder la
            marca, logo, marcas de agua u otras porciones del sitio web.
          </li>
          <li>
            realizar ingeniería inversa, o crear obras derivadas de esta Web o
            de sus Servicios
          </li>
          <li>
            manipular o usar la Web o sus Servicios para crear publicaciones y/o
            servicios de cualquier tipo y, en especial, de aquellos que puedan
            ser comparables o que compitan de cualquier forma con los Servicios
            que REPOFARMA ofrece ahora o pueda ofrecer en el futuro.
          </li>
          <li>
            desarrollar actividades que resulten contrarias a la Ley, la moral,
            el orden público o las buenas costumbres generalmente aceptadas.
          </li>
          <li>
            Alquilar, vender o ceder a terceros en forma alguna los Servicios,
            así como la inclusión de los contenidos en cualquier base de datos,
            de cualquier tipo, sin importar su formato o si son de acceso
            público o privado, de pago o gratuitas, sin autorización previa de
            REPOFARMA.
          </li>
        </ul>
      </div>
      <div className='docs'>
        <Paragraphs weight={'medium'}>3.3. Monitorización del Uso</Paragraphs>
        <Paragraphs>
          REPOFARMA se reserva el derecho a supervisar y guardar registros del
          uso que realiza el USUARIO del Servicio y su contenido, de su
          historial de búsquedas, de la IP desde la que se accede, así como
          obtener cualesquiera otros datos relacionados con el USUARIO, con el
          objetivo de mejorar el Servicio, velar por la seguridad del e
          investigar posibles violaciones de estos Términos y Condiciones.
          REPOFARMA puede cancelar el acceso al Servicio en caso que se detecten
          patrones de uso contrarios a lo previsto en estos Términos y
          Condiciones.
        </Paragraphs>
      </div>
      <div className='docs'>
        <Paragraphs weight={'medium'}>4. Propiedad Intelectual</Paragraphs>
        <Paragraphs>
          Todos los contenidos de los Servicios (incluyendo, a título
          enunciativo y no limitativo, textos, fotografías o ilustraciones,
          imágenes, contenidos audiovisuales, diseño gráfico, interfaces, código
          fuente, tecnología, software, links o cualquier otra información que
          conforma la base de datos, así como las marcas y demás signos
          distintivos) son propiedad de REPOFARMA o de terceros, no adquiriendo
          el USUARIO ninguna facultad sobre ellos por la mera utilización de los
          Servicios. REPOFARMA ha realizado la selección, diseño de la
          estructura y disposición de los Servicios, efectuando las inversiones
          necesarias para la obtención, digitalización y presentación de esta
          Web, por lo que todos los derechos de Propiedad Intelectual y
          cualquier otro derecho relacionado con los mismos le corresponde a
          REPOFARMA, salvo aquellos contenidos titularidad de terceros, estando
          todos ellos protegidos por la normativa española, comunitaria e
          internacional aplicable. En caso de que cualquier USUARIO o tercero
          considere que cualquiera de los contenidos existentes en la Web supone
          una violación de derechos de autor u otros derechos de protección de
          la propiedad intelectual, rogamos comunique a REPOFARMA dicha
          circunstancia, remitiendo notificación a la dirección de correo
          electrónico info@repofarma.com
        </Paragraphs>
      </div>
      <div className='docs'>
        <Paragraphs weight={'medium'}>5. Contenidos de terceros</Paragraphs>
        <Paragraphs>
          La inclusión en la Web y en los Servicios de elementos o contenidos
          proporcionados por terceros no implica respaldo, patrocinio o
          recomendación alguna de REPOFARMA sobre los mismos, por lo que no
          seremos responsables en absoluto respecto de su contenido y licitud.
          En el caso de que hubiera publicidad de terceros (incluyendo
          patrocinios), los anunciantes serán los únicos responsables de que el
          material publicitario cumple las leyes aplicables, no haciéndonos
          responsables de cualquier error o irregularidad que pudiera haber en
          el mismo.
        </Paragraphs>
      </div>
      <div className='docs'>
        <Paragraphs weight={'medium'}>
          6. Responsabilidad del USUARIO.
        </Paragraphs>
        <Paragraphs>El USUARIO es responsable de:</Paragraphs>
        <ul className='tw-ml-10 tw-mt-5 tw-flex tw-flex-col tw-gap-1 tw-w-2/3 tw-list-disc tw-font-light tw-text-sm'>
          <li>{'a) el Cumplimiento de estos Términos y Condiciones;'}</li>
          <li>
            {
              'b) emplear razonables esfuerzos para impedir el uso del Servicio por personas no autorizadas y notificar a REPOFARMA en caso de que estas se produzcan. El USUARIO acepta que usará los Servicios en calidad de usuario final, sin que pueda ceder su uso a otras personas u organizaciones distintas, ni de forma gratuita ni onerosa.La comunicación o cesión de los datos de acceso a otros individuos u organizaciones constituirá un incumplimiento grave del presente contrato. El USUARIO será responsable también de cualquier daño, perjuicio y pérdida, así como de los costes que se incurran por razón del mismo incluyendo los costes de asesoramiento jurídico.'
            }
          </li>
          <li>
            {
              'c) Proporcionar información de contacto completa y veraz, siendo responsable de su actualización. A todos los efectos se considerará que las comunicaciones realizadas por REPOFARMA, a los datos de contacto comunicados por el cliente tienen validez legal.El USUARIO puede, en cualquier momento que lo desee, modificar sus datos y su contraseña a través de su área personal. En caso de que REPOFARMA no pueda contactar con el USUARIO, por cualquier causa, o que los datos de contacto no sean suficientes, REPOFARMA podrá suspender temporalmente el acceso al servicio en tanto y en cuanto el USUARIO no actualice los datos de contacto.'
            }
          </li>
        </ul>
      </div>
      <div className='docs'>
        <Paragraphs weight={'medium'}>
          7. Responsabilidad de REPOFARMA
        </Paragraphs>
      </div>
      <div className='docs'>
        <Paragraphs weight={'medium'}>
          7.1. Provisión de los Servicios
        </Paragraphs>
        <Paragraphs>
          REPOFARMA realizará esfuerzos razonables para asegurar que la Web y
          los Servicios estarán disponible las 24h al día, 365 días al año con
          una calidad de servicio comparable con los estándares de la Industria,
          excepto en el caso de actuaciones planificadas comunicadas previamente
          a los USUARIOS y falta de disponibilidad derivada de circunstancias
          más allá del control razonable de REPOFARMA tales como errores de los
          proveedores de servicio en Internet, ataques informáticos, órdenes de
          gobierno, etc. 3.2.2 LED se reserva la facultad de modificar,
          suspender, cancelar o restringir el Contenido o los Servicios de las
          Plataformas Online, sus enlaces o la información obtenida a través de
          ellas, sin necesidad de previo aviso.
        </Paragraphs>
      </div>
      <div className='docs'>
        <Paragraphs weight={'medium'}>
          7.2. Cumplimiento de la Política de Privacidad.
        </Paragraphs>
        <Paragraphs>
          REPOFARMA cumplirá la Política de Protección de Datos Personales que
          puede consultarse en nuestra Política de Privacidad. En cualquier
          momento el Cliente podrá ejercitar sus derechos de acceso,
          rectificación, cancelación y oposición, así como, en su caso, el
          derecho de portabilidad y limitación del tratamiento, recogidos en el
          RGPD (UE) 216/679, dirigiéndose a la dirección de correo electrónico
          info@repofarma.com o mediante escrito Ref: LOPD, C/ Miguel Angel nº 4,
          bajo 10, Madrid – 28010. En cualquier caso, el Cliente puede dirigirse
          a la Agencia Española de Protección de Datos www.agpd.es, para iniciar
          los trámites oportunos en defensa de sus derechos.
        </Paragraphs>
      </div>
      <div className='docs'>
        <Paragraphs weight={'medium'}>8. Hiperlinks y enlaces.</Paragraphs>
        <Paragraphs>
          En el caso de que en la Web se mostrasen enlaces a otras páginas web
          mediante diferentes botones, links, banners, etc., REPOFARMA informa
          que éstos se encontrarían directamente gestionados por terceros.
          REPOFARMA no tiene facultad ni medios humanos ni técnicos para conocer
          de forma previa, controlar o aprobar toda la información, contenidos,
          productos o servicios facilitados por otras plataformas a las que se
          puedan establecer enlaces desde la Web. En consecuencia, REPOFARMA no
          podrá asumir ningún tipo de responsabilidad por cualquier aspecto
          relativo a cualquier plataforma o página web a la que se pudiera
          establecer un enlace desde la Web, en concreto, a título enunciativo y
          no limitativo, sobre su funcionamiento, acceso, datos, información,
          archivos, calidad y fiabilidad de sus productos y servicios, sus
          propios enlaces y/o cualquiera de sus contenidos, en general.
        </Paragraphs>
        <Paragraphs>
          El USUARIO que quiera introducir un enlace en la Web, deberá cumplir
          con las siguientes condiciones:
        </Paragraphs>
        <ul className='tw-ml-10 tw-mt-5 tw-flex tw-flex-col tw-gap-1 tw-w-2/3 tw-list-disc tw-font-light tw-text-sm'>
          <li>
            No podrán establecerse frames o marcos de cualquier tipo que rodeen
            las Web o permitan visualizarla a través de direcciones de Internet
            distintas o conjuntamente con contenidos ajenos a esta web, de forma
            que produzcan o puedan producir error o confusión sobre la
            procedencia del Servicio o su contenido, impliquen un acto de
            comparación o imitación desleal, sirvan para aprovechar la
            reputación, marca y prestigio de REPOFARMA, sus clientes o
            proveedores o se hagan de cualquier otro modo que esté prohibido por
            la Ley;
          </li>
          <li>
            Desde la página que realice el enlace no podrá efectuarse ningún
            tipo de manifestación falsa o inexacta sobre REPOFARMA, sobre sus
            Servicios o sobre sus clientes, proveedores o empleados;
          </li>
          <li>
            El remitente no podrá utilizar la marca o cualquier otro signo
            distintivo de REPOFARMA dentro de su página web, salvo en los casos
            en que sea autorizado expresamente por REPOFARMA;
          </li>
          <li>
            La página que establezca el enlace deberá observar la legalidad
            vigente y no podrá disponer de o enlazar con contenidos ilícitos,
            nocivos, contrarios a la moral y a las buenas costumbres, que
            produzcan o puedan producir la falsa idea de que REPOFARMA respalda
            o apoya las ideas, manifestaciones o actuaciones del remitente o que
            resulten inadecuados en relación con la actividad desarrollada por
            REPOFARMA y sus clientes, teniendo en cuenta los contenidos y la
            temática general de la página web donde se establezca el enlace.
          </li>
        </ul>
      </div>
      <div className='docs'>
        <Paragraphs weight={'medium'}>
          9. Exclusión de garantías y de responsabilidad.
        </Paragraphs>
        <Paragraphs>
          REPOFARMA no garantiza la fiabilidad, disponibilidad, o continuidad
          del funcionamiento de su Web, por lo que se excluye cualquier
          responsabilidad por los daños y/o perjuicios de toda naturaleza que
          puedan deberse a la falta de disponibilidad, fiabilidad o continuidad
          de su Web. No obstante, REPOFARMA declara que ha adoptado todas las
          medidas necesarias, dentro de sus posibilidades y del estado de la
          técnica, para garantizar el buen funcionamiento de la Web y de los
          Servicios para reducir al mínimo los errores del sistema, tanto desde
          el punto de vista técnico como de los contenidos publicados, así como
          para evitar la existencia y transmisión de virus y demás componentes
          dañinos a los sistemas informáticos de los USUARIOS, por lo que no
          responderá de los daños o perjuicios de cualquier naturaleza que
          pudieran derivarse de los mismos. El USUARIO responderá de los daños
          y/o perjuicios de cualquier naturaleza que REPOFARMA, pudiera sufrir
          como consecuencia del incumplimiento por parte del usuario de la ley o
          de cualquiera de las normas recogidas en estos Términos y Condiciones
          de Uso. El USUARIO reconoce que la utilización de la Web y de los
          Servicios se realiza bajo su exclusiva responsabilidad. En concreto, a
          título meramente enunciativo, REPOFARMA no asume ninguna
          responsabilidad en los siguientes ámbitos:
        </Paragraphs>
        <ul className='tw-ml-10 tw-mt-5 tw-flex tw-flex-col tw-gap-1 tw-w-2/3 tw-list-disc tw-font-light tw-text-sm tw-mb-5'>
          <li>
            La disponibilidad en el funcionamiento de la Web y de los Servicios.
          </li>
          <li>
            La finalidad de que los Servicios sirvan a los objetivos del
            USUARIO.
          </li>
          <li>
            La infracción de la legislación vigente por parte del USUARIO o
            terceros y, en concreto, de los derechos de propiedad intelectual o
            industrial que sean titularidad de otras personas o entidades.
          </li>
          <li>
            La existencia de códigos maliciosos o cualquier otro elemento
            informático dañino que pudiera causar daños al sistema informático
            del USUARIO o de terceros.
          </li>
          <li>
            El acceso fraudulento a los Servicios por terceros no autorizados.
          </li>
          <li>
            Los daños o perjuicios que se deriven de circunstancias acaecidas
            por caso fortuito o fuerza mayor.
          </li>
        </ul>
        <Paragraphs>
          REPOFARMA se reserva la potestad de denegar discrecionalmente a
          cualquier USUARIO el acceso a la Web y/o los Servicios ofrecidos en
          esta, si existen indicios de que aquellos utilizará en contra de los
          presentes Términos y Condiciones de Uso.
        </Paragraphs>
      </div>
      <div className='docs'>
        <Paragraphs weight={'medium'}>
          10. Confidencialidad y protección de datos.
        </Paragraphs>
        <Paragraphs>
          De conformidad con lo dispuesto en la normativa aplicable en materia
          de protección de datos, todos los datos de carácter personal obtenidos
          por el uso de la Web serán tratados de conformidad con lo dispuesto en
          la Política de Privacidad y en la Política de Cookies de REPOFARMA.
        </Paragraphs>
      </div>
      <div className='docs'>
        <Paragraphs weight={'medium'}>11. Generales.</Paragraphs>
        <Paragraphs>
          Asimismo, REPOFARMA podrá dar por terminado, suspender o interrumpir,
          en cualquier momento y sin necesidad de preaviso, el uso y en su caso
          acceso a los contenidos a la Web, sin posibilidad por parte del
          USUARIO de exigir indemnización alguna. Tras dicha extinción, seguirán
          vigentes las prohibiciones de uso de los contenidos expuestos
          anteriormente. Los Términos y Condiciones de Uso podrán ser
          modificados o sustituidos en cualquier momento. Las nuevas condiciones
          sustituirán, completarán o modificarán las actuales desde el mismo
          momento en que sean publicadas en la Web o por cualquier otro medio
          que permita al USUARIO conocerlas. En el caso de que cualquier
          disposición de los Términos y Condiciones de Uso fuese declarada nula
          o inaplicable, en su totalidad o en parte, por cualquier Juzgado,
          Tribunal u órgano administrativo competente, dicha nulidad o
          inaplicación no afectará a las restantes disposiciones de este. El no
          ejercicio o ejecución por parte de REPOFARMA de cualquier derecho o
          disposición contenida en estos Términos y Condiciones de Uso, no
          constituirá una renuncia al mismo.
        </Paragraphs>
      </div>
      <div className='docs'>
        <Paragraphs weight={'medium'}>
          12. Ley aplicable y Jurisdicción competente.
        </Paragraphs>
        <Paragraphs>
          La normativa vigente determinará las leyes que deban regir y la
          jurisdicción que deba conocer de las relaciones entre REPOFARMA y los
          Usuarios de la Web. No obstante, siempre que tal normativa prevea la
          posibilidad para las partes de someterse a un fuero determinado, para
          toda cuestión litigiosa derivada o relacionada con la Web, será de
          aplicación la legislación española vigente en el momento de los
          hechos. Asimismo, REPOFARMA y los Usuarios, con renuncia expresa a
          cualquier otro fuero que pudiera corresponderles, se someten a los
          Juzgados y Tribunales de la ciudad de Madrid (España).
        </Paragraphs>
      </div>
      <div>
        <Paragraphs weight={'medium'}>
          Copyright © REPOFARMA, S.L. Todos los derechos reservados.
        </Paragraphs>
      </div>
    </div>
  )
}

export default AvisoLegal
