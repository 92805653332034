import React from 'react'
import PropTypes from 'prop-types'
import useLabel from './hooks/useLabel'
import Paragraphs from '../Paragraphs'

/**
 * Label component.
 * @author @jstarsky
 * @param {*} props
 * @return {JSX.Element} .
 */
const Label = ({
  id,
  htmlFor,
  className,
  fontFamily,
  size,
  transform,
  weight,
  italic,
  required,
  label,
  color,
  alignment
}) => {
  const { internalClassName, alingmentClassName } = useLabel({
    id,
    color,
    alignment,
    extendClassName: className
  })
  return (
    <Paragraphs
      id={id}
      className={`tw-flex tw-gap-1${alingmentClassName}`}
      fontFamily={fontFamily}
      size={size}
      transform={transform}
      weight={weight}
      italic={italic}
    >
      <>
        <label
          className={`${internalClassName}`}
          htmlFor={htmlFor || undefined}
        >
          {label}
        </label>
        <span
          className={`${
            required ? 'tw-text-alert ' : 'tw-text-transparent '
          }tw-leading-none`}
        >
          *
        </span>
      </>
    </Paragraphs>
  )
}

Label.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  htmlFor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  fontFamily: PropTypes.oneOf(['workSans']),
  size: PropTypes.oneOf(['xxs', 'xs']),
  transform: PropTypes.oneOf(['uppercase', 'lowercase', 'capitalize']),
  weight: PropTypes.oneOf(['bold', 'normal']),
  italic: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  color: PropTypes.oneOf([
    'alert',
    'primary',
    'secondary',
    'tertiary',
    'success',
    'warning',
    'white',
    'green',
    'info',
    'black',
    'required',
    'validate'
  ]),
  alignment: PropTypes.oneOf(['center', 'end', 'start'])
}

Label.defaultProps = {
  id: 'label',
  fontFamily: 'workSans',
  weight: 'bold',
  size: 'xxs'
}

export default Label
