import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Logo from '../Logo'
import Paragraphs from '../Paragraphs'
import { useTranslation } from 'react-i18next'
import Button from '../Button/Button'
import { TEXTS } from './constants/text'
import { INPUTS } from './constants/inputs'
import TextInput from '../TextInput/TextInput'
import { useForm } from 'react-hook-form'
import useMutation from '../../infrastructure/hooks/useMutation'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { FARMACLOUD_URL } from '../../infrastructure/constants/configurations'
import useUser from '../../infrastructure/hooks/useUser'

const FORGOT_PASSWORD = [

  {
    id: 'password',
    type: 'password',
    label: 'Contraseña',
    name: 'password'
  },
  {
    id: 'password_confirmation',
    type: 'password',
    label: 'Confirmar contraseña',
    name: 'password_confirmation'
  }

]

const SEND_EMAIL = [
  {
    id: 'email',
    type: 'text',
    label: 'Correo electrónico',
    name: 'email'
  }

]

/**
 * @author ehernandez
 * @description component for show register or login form
 * @param {function} onSubmitSuccess function to call when the form is successfully submitted
 * @returns
 */
const Login = ({ onSubmitSuccess }) => {
  const timer = useRef()
  const { t } = useTranslation()
  const [mode, setIsMode] = React.useState('login')
  const [fetchPostCodes] = useMutation('postcodes')
  const [postCodes, setPostCodes] = useState([])
  const [startRecovery, setStartRecovery] = useState(false)
  const [textMessage, setTextMessage] = useState('')
  const [recoveryPassword, setRecoveryPassword] = useState(false)
  const [successRecovery, setSuccessRecovery] = useState(false)

  const [visibleCodes, setVisibleCodes] = useState(false)
  const [loading, setLoading] = useState(false)
  // const dispatch = useDispatch()
  const location = useLocation()
  const { modal } = useSelector((state) => state.layoutReducer)
  const navigate = useNavigate()
  const { onForgotPassword, onSendNewPassword } = useUser()

  useEffect(() => {
    setIsMode(modal)
  }, [modal])

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
    setValue
  } = useForm({
    defaultValues: {
      remember_me: true,
      city_id: 0
    }
  })
  useEffect(() => {
    // if exist token in url
    if (location.search) {
      // verify if we have key 'token' in url
      if (location.search.includes('token')) {
        setRecoveryPassword(true)
        setTextMessage('Actualiza tu contraseña')
      }
    }
  }, [location.search])

  /**
   *    @auhtor ehernandez
   * @description reset form
   */
  const changeMode = () => {
    reset()
    setIsMode(mode === 'login' ? 'register' : 'login')
  }

  /**
   *    @auhtor ehernandez
   * @description on submit form
   * @param {object} values
   */
  const onSubmit = async (values) => {
    if (startRecovery) {
      // handle recovery password
      const { error, success } = await onForgotPassword(values)
      if (success) {
        setTextMessage('Ve a tu correo y haz click en el enlace de restablecimiento de contraseña que te hemos enviado.')
        toast.success('Enlace enviado correctamente.')
        setSuccessRecovery(true)
        setStartRecovery(false)
      } else {
        setTextMessage('No se ha podido enviar el correo de restablecimiento de contraseña.')
        if (error) {
          if (error?.errors) {
            Object.keys(error.errors).forEach((i) => {
              setError(i, {
                type: 'alert',
                message: error.errors[i]
              })
            })
          }
        }
      }
      return
    }

    if (recoveryPassword) {
      // get token and email from url
      const token = location.search.split('token=')[1].split('&')[0]
      const email = location.search.split('email=')[1]
      const { error, success } = await onSendNewPassword(values, token, email)
      if (success) {
        toast.success('Contraseña actualizada correctamente.')
        // redirect to login
        navigate('/')
        setStartRecovery(false)
        setRecoveryPassword(false)
        setTextMessage('')
        setSuccessRecovery(false)
        setValue('password', '')
      } else {
        setTextMessage('No se ha podido actualizar la contraseña.')
        if (error) {
          if (error?.errors) {
            Object.keys(error.errors).forEach((i) => {
              setError(i, {
                type: 'alert',
                message: error.errors[i]
              })
            })
          }
        }
      }
      return
    }

    if (onSubmitSuccess) {
      setLoading(true)
      const { error } = await onSubmitSuccess(mode, values)
      navigate(location.pathname)
      setLoading(false)
      if (error) {
        if (mode === 'login') {
          toast('Credenciales invalidas', { type: 'error' })
        }

        if (error?.errors) {
          Object.keys(error.errors).forEach((i) => {
            setError(i, {
              type: 'alert',
              message: error.errors[i]
            })
          })
        }
      }
    }
  }
  const inputs = useMemo(() => {
    if (recoveryPassword) {
      return FORGOT_PASSWORD
    }
    if (startRecovery) {
      return SEND_EMAIL
    } else {
      return INPUTS[mode].filter((i) => i)
    }
  }, [mode, recoveryPassword, startRecovery])

  const onChangePostCodes = async (code) => {
    if (timer?.current) {
      clearTimeout(timer.current)
    }
    setTimeout(async () => {
      const { data } = await fetchPostCodes({
        method: 'POST',
        data: {
          postcode: code
        }
      })
      setPostCodes(data?.result || [])
    }, 800)
  }

  return (
    <form
      data-cy="login-form"
      onSubmit={handleSubmit(onSubmit)}
      className="login  tw-py-6 tw-px-12 tw-flex tw-justify-between tw-flex-col tw-h-full tw-bg-white tw-overflow-auto"
    >
      <div className="login__content tw-flex tw-flex-col">
        <div className="login__logo tw-flex tw-justify-center">
          <Logo
            className="tw-h-[80px]"
            image={
              require('../../infrastructure/assets/images/logos/ic_farmapolis_color_collapsed.svg')
                .default
            }
          />
        </div>
        <div
          className="login__form"
          style={{ overflowY: 'auto', overflowX: 'hidden' }}
        >
          <div className="login__form__title tw-text-center">
            <Paragraphs weight="bold" size="4xl" className="tw-mt-2">
              {t(TEXTS[mode].title)}
            </Paragraphs>

           { !textMessage
             ? <Paragraphs size="sm" weight="light" className="tw-mt-2">
              {t(TEXTS[mode]?.subtitle)}
            </Paragraphs>
             : <Paragraphs size="sm" weight="light" className="tw-mt-2">{textMessage}</Paragraphs>}
             {}
          </div>
         { !successRecovery && <div className="login__form__fields tw-py-4">

             {inputs?.map((input) => {
               return (() => {
                 if (input.type === 'autocomplete') {
                   return (
                    <div className="tw-relative" key={input.id}>
                      <TextInput
                        error={errors?.city_id}
                        key={input.id}
                        label={input.label}
                        onFocus={() => setVisibleCodes(true)}
                        name={input.name}
                        type={input.type}
                        onChange={(e) => onChangePostCodes(e.target.value)}
                      />
                      {visibleCodes && (
                        <div className="tw-absolute tw-z-50 tw-shadow tw-top-[80px] tw-border tw-max-h-[100px] tw-overflow-auto tw-border-border tw-w-full tw-left-0 tw-bg-white">
                          {postCodes.map((i) => {
                            return (
                              <button
                                onClick={() => {
                                  setValue('city_id', i.id)
                                  setVisibleCodes(false)
                                }}
                                type="button"
                                key={i.id}
                                className="tw-p-2 tw-cursor-pointer tw-text-left hover:tw-bg-gray-50 tw-block tw-w-full"
                              >
                                <Paragraphs size="xxs" weight="bold">
                                  {i.name}
                                </Paragraphs>
                                <Paragraphs size="xxs">{i.postcode}</Paragraphs>
                              </button>
                            )
                          })}
                        </div>
                      )}
                    </div>
                   )
                 } else if (input.type === 'password') {
                   return (
                    <div className="password-input-wrapper tw-relative tw-w-full">
                      <TextInput
                        error={errors[input.name]}
                        key={input.id}
                        register={register(input.name)}
                        label={input.label}
                        name={input.name}
                        type={input.type}
                        button={{
                          icon: 'icon-ic_mostrar_contrasea',
                          onClick: () => alert('click on button search'),
                          reverse: false
                        }}
                      />
                    </div>
                   )
                 } else {
                   return (
                    <TextInput
                      error={errors[input.name]}
                      key={input.id}
                      register={register(input.name)}
                      label={input.label}
                      name={input.name}
                      type={input.type}
                    />
                   )
                 }
               })()
             })
              }
            <div onClick={ () => {
              setStartRecovery(true)
              setTextMessage('Introduce tu email para restablecer tu contraseña')
            }}>
              {(!startRecovery && !recoveryPassword) && (
                <Paragraphs
                  className=" tw-text-sky-600 tw-cursor-pointer"
                  size="xs"
                >
                  {t('¿Has olvidado tu contraseña?')}
                </Paragraphs>
              )}
            </div>
          </div>}
        </div>
      </div>
      <div className="login__content__footer">
        {(startRecovery || recoveryPassword) && (
          <div className='tw-flex tw-flex-col tw-gap-5'>
          {!successRecovery && <Button
            cy="login-submit-btn"
            transform="uppercase"
            mode="primary"
            type="submit"
            label={'Enviar'}
            disabled={loading} />}
            <Button
              cy="login-submit-btn"
              transform="uppercase"
              mode="primary"
              onClick={() => {
                setStartRecovery(false)
                setRecoveryPassword(false)
                setTextMessage('')
                setSuccessRecovery(false)
                // clean token from url
                navigate('/')
              }}
              label={recoveryPassword ? 'Volver a inicio' : 'Atrás'}
              disabled={loading} />
          </div>
        )}
        {!startRecovery && !recoveryPassword && (
          <Button
            cy="login-submit-btn"
            transform="uppercase"
            mode="primary"
            type="submit"
            label={t(TEXTS[mode].button)}
            disabled={loading}
          />
        )}
        <div
          onClick={changeMode}
          className="login__content__footer tw-mt-2 tw-cursor-pointer"
        >
        </div>
        {mode === 'login' && !startRecovery && !recoveryPassword && (
          <div className={'tw-pt-1 tw-flex tw-flex-col tw-gap-3'}>
            <Button
              data-cy="reg-btn"
              onClick={
                () => {
                  window.location.href = 'https://app.farmapolis.es/register'
                }
              }
              mode="register"
              uppercase
              label={t('Deseo registrarme')}
            />
            <Button
              cy="login-submit-btn"
              transform="uppercase"
              mode="primary"
              onClick={() => {
                location.href = window.location.href
                window.location.href = FARMACLOUD_URL + 'farmacloud'
              }}
              label={t(TEXTS[mode].farmacloudButton)}
              disabled={loading}
            />
          </div>
        )}
      </div>
    </form>
  )
}

Login.propTypes = {
  onSubmitSuccess: PropTypes.func
}

export default Login
