import React from 'react'
import { CARDS_HOME } from '../../../infrastructure/constants/links'
import Card from '../../Card'
import Paragraphs from '../../Paragraphs'
import { useTheme } from '../../../infrastructure/context/ThemeContext/ThemeContext'

/**
 *@author cperez
 *@return {React.JSX}
 */

const SectionDestacados = () => {
  const { theme } = useTheme()

  return (
    <>
      <div
        id=""
        className="tw-flex tw-mt-20 tw-items-center tw-justify-center tw-flex-col tw-mx-auto tw-max-w-[90%]"
      >
        {!theme?.is_lab && (
          <div className="tw-flex tw-text-center tw-max-w-[90%] tw-justify-center tw-items-center tw-flex-col tw-gap-3">
            <Paragraphs
              weight="medium"
              className="tw-text-black tw-max-w-[90%] "
              size="4xl"
            >
              Accede a toda la información de productos y laboratorios con un
              solo clic
            </Paragraphs>
            <div className="tw-h-[3px] tw-mt-8 tw-w-[100px] tw-bg-gradient-to-r tw-from-primary tw-to-tertiary"></div>
          </div>
        )}
      </div>
      <div className="tw-flex tw-justify-center tw-mt-10 tw-flex-wrap tw-mx-auto tw-gap-8 tw-max-w-[1000px]">
        {CARDS_HOME.map((item, index) => (
          <Card item={item} key={index} />
        ))}
      </div>
    </>
  )
}

export default SectionDestacados
