import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs'

/**
 *  @author ehernandez
 * @param {string} code
 * @returns {React.Component}
 */
const ProductCode = ({ code, size }) => {
  return (
    <div className="product-code">
      <Paragraphs weight="bold" size={size} className="tw-text-primary">
        {code}
      </Paragraphs>
    </div>
  )
}

ProductCode.propTypes = {
  code: PropTypes.string,
  size: PropTypes.oneOf(['xxs', 'xs', 'sm'])
}

ProductCode.defaultProps = {
  size: 'xs'
}

export default ProductCode
