import {
  SET_OPEN_LOGIN,
  SET_CLOSE_LOGIN,
  SET_ORDERBY,
  SET_OPEN_REGISTER
} from '../constants/layoutConstants'

/**
 * @author shernandez
 * @returns {object}
 */
export const actionOpenLogin = () => ({
  type: SET_OPEN_LOGIN
})

export const actionOpenRegister = () => ({
  type: SET_OPEN_REGISTER
})

/**
 * @author shernandez
 * @returns {object}
 */
export const actionCloseLogin = () => ({
  type: SET_CLOSE_LOGIN
})

/**
 * @author shernandez
 * @returns {object}
 * *@description: 'Sets Order By parameters'
 */
export const setOrderByParams = (params) => ({
  type: SET_ORDERBY,
  params
})
