export const INPUTS = [
  {
    id: 1,
    name: 'lab_name',
    label: 'Laboratorio',
    type: 'text',
    disabled: true,
    className: 'tw-w-full md:tw-w-6/12 xl:tw-w-6/12'
  },
  {
    id: 2,
    name: 'cn_codes',
    label: 'CN',
    type: 'text',
    disabled: true,
    className: 'tw-w-full lg:tw-w-3/12 xl:tw-w-2/12'
  },
  {
    id: 3,
    name: 'ean_codes',
    label: 'EAN',
    type: 'text',
    disabled: true,
    className: 'tw-w-full lg:tw-w-9/12 xl:tw-w-4/12'
  },
  {
    id: 4,
    name: 'category.name',
    label: 'Categoría',
    type: 'text',
    disabled: true,
    className: 'tw-w-full md:tw-w-5/12'
  },
  {
    id: 5,
    name: 'category.parent_category.name',
    label: 'Subcategoría',
    type: 'text',
    disabled: true,
    className: 'tw-w-full md:tw-w-5/12'
  },
  {
    id: 6,
    name: 'tax',
    label: 'I.V.A.',
    type: 'text',
    disabled: true,
    className: 'tw-w-full xl:tw-w-2/12 md:tw-w-6/12'
  }
  // {
  //   id: 7,
  //   name: 'long_description',
  //   label: 'Descriptión larga',
  //   type: 'text',
  //   disabled: true,
  //   className: 'tw-w-full'
  // },
  // {
  //   id: 8,
  //   name: 'posology',
  //   label: 'Posología',
  //   type: 'textarea',
  //   disabled: true,
  //   className: 'tw-w-full'
  // },
  // {
  //   id: 9,
  //   name: 'precautions',
  //   label: 'Precauciones',
  //   type: 'textarea',
  //   disabled: true,
  //   className: 'tw-w-full'
  // },
  // {
  //   id: 10,
  //   name: 'indications',
  //   label: 'Indicaciones',
  //   type: 'textarea',
  //   disabled: true,
  //   className: 'tw-w-full'
  // }
]
