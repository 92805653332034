import React from 'react'
import styled from 'styled-components'
import Paragraphs from '../Paragraphs'
import PropTypes from 'prop-types'
import Button from '../Button/Button'
// import icon from '../../infrastructure/assets/images/home/Recurso-7.png'

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.19);
  width: 300px;
  height: 360px;
  border-radius: 20px;
  position: relative;
`

/**
 * @author cperez
 * @returns {React.Component}
 */
const Card = ({ item }) => {
  return (
    <CardContainer>
      <div className="tw-flex tw-flex-col tw-mt-10  tw-w-[200px] tw-gap-3 tw-max-w-[90%] tw-items-center tw-justify-center tw-text-center">
        <img width={80} src={item.img} alt="icon" />
        <Paragraphs weight="medium" size="2xl" className="">
          {item.label}
        </Paragraphs>
        <Paragraphs weight="normal">{item.text}</Paragraphs>
      </div>
      <div className="tw-w-full left-1/2 transform -translate-x-1/2 -translate-y-1/2   tw-absolute tw-bottom-10 tw-flex tw-items-center tw-justify-center">
        <a href={item.href}>
          <Button
            mode="gradient"
            label="Ver más"
            uppercase
          />
        </a>
      </div>
    </CardContainer>
  )
}

export default Card

Card.propTypes = {
  item: PropTypes.object
}
