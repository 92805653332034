/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Paginator from '../Paginator/Paginator'
import EmptyState from '../EmptyState'
import { useTranslation } from 'react-i18next'
// import FilterBtn from '../FilterBtn/FilterBtn'
import SearchInput from '../SearchInput/SearchInput'
// import FilterFavoriteBtn from '../FilterFavoriteBtn'
import FilterOrderBy from '../FilterOrderBy'
import Spinner from '../Spinner/Spinner'
import { useUser } from '../../infrastructure/hooks/useUser/useUser'

const ListingGroups = ({
  children,
  getData,
  paginatorInfo,
  loading,
  className,
  orderBy,
  currentPage,
  search,
  onSubmitSearch,
  filters
}) => {
  const { t } = useTranslation()
  const { user, token } = useUser()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [loading])

  const renderContent = () => {
    if (paginatorInfo?.total_items === 0) {
      return <EmptyState message={t('No se encontraron resultados')} />
    }

    if (loading) {
      return (
        <div className="tw-w-full tw-flex tw-justify-center">
          <Spinner />
        </div>
      )
    }

    return children
  }

  return (
    <div className={`listing-groups tw-w-full ${className}`}>
{filters && <div
        className='product-listing-screen__search-innput md:tw-px-2 tw-relative tw-flex tw-justify-between'
      >
        {onSubmitSearch && (
          <div className={'tw-w-full tw-flex tw-mb-5 tw-mt-5'}>
            <SearchInput
              orderOptions={orderBy?.options}
              onChangeOrder={orderBy?.onChangeOrder}
              onSubmit={onSubmitSearch}
              value={search}
            />
          </div>
        )}
      </div>}
      {token && user?.id && orderBy && (
        <div className="tw-flex tw-pt-4 tw-place-content-end tw-flex-1 tw-opacity-25 lg:tw-hidden">
          <FilterOrderBy
            options={orderBy?.options}
            onChange={orderBy?.onChangeOrder}
          />
        </div>
      )}
      <div className="tw-py-6 tw-flex tw-w-full">
        {renderContent(children)}
      </div>
      {token && user?.id && !loading && paginatorInfo && paginatorInfo?.last_page > 1 && (
        <div className="pagination tw-flex tw-justify-center tw-w-full tw-py-10 tw-px-8">
          <Paginator
            getData={getData}
            currentPage={currentPage}
            id="resources"
            {...paginatorInfo}
          />
        </div>
      )}
    </div>
  )
}

ListingGroups.propTypes = {
  children: PropTypes.node,
  getData: PropTypes.func,
  paginatorInfo: PropTypes.object,
  loading: PropTypes.bool,
  className: PropTypes.string,
  orderBy: PropTypes.object,
  queries: PropTypes.object,
  onSubmitSearch: PropTypes.func,
  filters: PropTypes.object,
  search: PropTypes.string,
  showInDev: PropTypes.bool
}

export default ListingGroups
