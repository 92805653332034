/**
 * useInput
 * @author @jstarsky
 * @param {*} props
 * @return {Object}
 */
const useInput = ({ alignment, fontSize }) => {
  /**
   * setAlingment
   * @author @jstarsky
   * @param {String} input alingment "center", "end", "start"
   * @return {String}
   * https://tailwindcss.com/docs/font-family#header
   */
  const setAlingment = (input) => {
    switch (input) {
      case 'start':
        return ' tw-text-left'
      case 'center':
        return ' tw-text-center'
      case 'end':
        return ' tw-text-right'
      default:
        return ''
    }
  }

  /**
   * setSize
   * @author @jstarsky
   * @param {String} input
   * @return {String}
   * https://tailwindcss.com/docs/font-size#header
   */
  const setSize = (input) => {
    switch (input) {
      case 'xs':
        return ' tw-text-xs'
      case 'xl':
        return ' tw-text-xl'
      default:
        return ''
    }
  }

  return {
    internalClassName: `tw-font-atkinson tw-placeholder-italic tw-leading-none tw-appearance-none tw-bg-transparent tw-outline-none tw-flex tw-w-full${setAlingment(
      alignment
    )}${setSize(fontSize)}`
  }
}
export default useInput
