import React, { useEffect, useState } from 'react'
import ProductCard from '../../../../components/ProductCard/ProductCard'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useProducts from '../../../../infrastructure/hooks/useProducts'
import { useUser } from '../../../../infrastructure/hooks/useUser/useUser'
import { useFavorites } from '../../../../infrastructure/hooks/useFavorites/useFavorites'
import Paginator from '../../../../components/Paginator/Paginator'
import EmptyState from '../../../../components/EmptyState/EmptyState'
import { useTranslation } from 'react-i18next'
import Spinner from '../../../../components/Spinner/Spinner'

const ProductListFavorits = () => {
  const { token } = useUser()
  const [loading, setLoading] = useState(false)
  const { getFavorites, data } = useFavorites()
  const [products, setProducts] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation()
  const page = searchParams.get('page')

  const { downloadAll, addRemoveFav } = useProducts()

  /**
   * @author cperez
   * @description Function that obtains the favorites products
   */
  const getFavoritesProducts = async () => {
    setLoading(true)
    const response = await getFavorites({ mode: 'products', page })
    setProducts(response?.items)
    setLoading(false)
  }

  /**
   * @author cperez
   * @param {string} id
   * @param {boolean} active
   * @description Function that removes a product from favorites
   */
  const unLike = async (id, active) => {
    await addRemoveFav(id, active)
    setProducts(products.filter((i) => i.id !== id))
  }

  useEffect(() => {
    getFavoritesProducts()
  }, [])

  /**
   * @author cperez
   * @param {number} page
   * @description Function that handles the pagination
   */
  const handlePageChange = async (page) => {
    setSearchParams({ page })
    getFavorites({ page })
    window.scrollTo(0, 0)
  }
  const navigate = useNavigate()
  if (products?.length === 0 && !loading) {
    return <EmptyState message={t('No hemos encontrado recursos favoritos')} />
  }
  return (
    <div className="product-list-favorits tw-w-full">
      <div className="product-listing-screen__content tw-py-8 tw-flex tw-flex-wrap">
        <div className="product-listing-screen__content__product tw-flex-1 tw-px-4 tw-flex tw-flex-wrap">

          { loading
            ? <div className="tw-w-full tw-flex tw-justify-center"><Spinner/></div>
            : products?.map((i, index) => (
            <div
              data-cy={`product-card-${index}`}
              key={i.id}
              className="tw-w-full sm:tw-w-6/12 md:tw-w-6/12 lg:tw-w-4/12 xl:tw-w-3/12 tw-p-2"
            >
              <ProductCard
                onClickDownload={() => downloadAll(i.id)}
                onClickFav={(active) => {
                  unLike(i.id, active)
                }}
                onClick={() => token && navigate('/product-list/' + i.id)}
                isLogged={!!token}
                {...i}
                i={i}
                is_favorite={true}
                image={i?.product_images[0]}
                // new ={i?.is_new}
              />
            </div>
            ))}
        </div>
      </div>
      <div className='product-list-paginator tw-w-min tw-mx-auto tw-px-8'>
          <Paginator getData = {handlePageChange} {...data?.result?.data} />
      </div>
    </div>
  )
}

export default ProductListFavorits
