import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs'

/**
 *  @author ehernandez
 * @param {string} code
 * @returns {React.Component}
 */
const ProductName = ({ name, size, manufacturer, onClickManufacturer }) => {
  return (
    <div className="product-code tw-flex tw-gap-1 tw-flex-col">
      <Paragraphs fontFamily={'Trade'} weight="bold" size={size}>
        {name}
      </Paragraphs>
      {
        manufacturer && <div data-cy = 'laboratorio-name-link' onClick={onClickManufacturer}> <Paragraphs weight="light" transform="uppercase" size={'xs'} className="tw-cursor-pointer">
        {manufacturer}
      </Paragraphs></div>
      }

    </div>
  )
}

ProductName.propTypes = {
  name: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'lg', '3xl']),
  manufacturer: PropTypes.string,
  onClickManufacturer: PropTypes.func
}

ProductName.defaultProps = {
  size: '3xl'
}

export default ProductName
