import React from 'react'
import PropTypes from 'prop-types'
/**
 *
 * @auhtor ehernandez
 */
const Logo = ({ image, className }) => {
  return (<div className={`logo tw-h-[50px] tw-flex tw-items-center tw-justify-center ${className}`}>
      <img src={image} className="tw-max-w-[160px] md:tw-max-h-full" />
  </div>)
}

Logo.propTypes = {
  image: PropTypes.string,
  className: PropTypes.string
}

export default Logo
