import useMutation from '../useMutation'

export const useLaboratories = () => {
  const [fetchLabs, { loading }] = useMutation('labs')
  const getLaboratories = async (values = {}) => {
    const { page = 1, id } = values
    const variables = {
      elements: { page, per_page: 12 },
      attributes: {}
    }
    if (id) {
      variables.attributes.id = id
    }
    const { success, data } = await fetchLabs({
      method: 'POST',
      data: variables
    })
    if (success && data.result) {
      if (id) {
        return {
          data: data.result?.items?.[0]
        }
      }
      return {
        data: data.result
      }
    }
  }

  const labDetail = async (id) => {
    const { data, success } = await fetchLabs({
      method: 'GET',
      addToURL: `/${id}/documents`
    })
    if (success) {
      return {
        data
      }
    }

    return {
      error: true
    }
  }

  return {
    getLaboratories,
    loading,
    labDetail
  }
}
