/* eslint-disable no-const-assign */
/* eslint-disable array-callback-return */
import { useContext, useState } from 'react'
import { CATEGORIES, FAVORITES, LABS, PRODUCTS, PRODUCT_DOWNLOAD, HOOKS_VIP, HOOKS_NOVEDADES } from '../../constants/api'
import useMutation from '../useMutation'
import { useUser } from '../useUser/useUser'
import Context from '../../context/UrlParamsContext/UrlParamsContext'

export const useProducts = () => {
  const [fetchProducts] = useMutation(PRODUCTS)
  const [fetchHookVip] = useMutation(HOOKS_VIP)
  const [fetchNovedades] = useMutation(HOOKS_NOVEDADES)
  const [fetchCategories] = useMutation(CATEGORIES)
  const [fetchLaboratories] = useMutation(LABS)
  const [fetchDownload] = useMutation(PRODUCT_DOWNLOAD)
  const [fetchFavorites] = useMutation(FAVORITES + '/product')
  const [products, setProducts] = useState(null)
  const [loader, setLoading] = useState(null)

  const { token, user } = useUser()

  const { setProduct } = useContext(Context)

  const getProducts = async (values = {}) => {
    const { search, laboratories, categories, page = 1, month, order, news } = values
    const variables = {
      elements: { page, per_page: 36 },
      image: { width: 300, height: 300 },
      attributes: {
      }
    }

    if (token) {
      variables.attributes.view = 'FRONT'
      variables.channel = 'FRONT'
    }
    if (month) {
      variables.attributes.month = month
    }

    if (news) {
      variables.attributes.news = news
    }
    // modificar aqui el order que se queire pedir en la bbdd
    if (order && user?.id && token) {
      variables.elements.order_param = order.param
      variables.elements.order_direction = order.direction
    }
    if (search) {
      // search = search.split('=').pop().split('/')[0]
      variables.attributes.query = search
    }

    if (laboratories) {
      variables.attributes.lab_ids = laboratories
    }
    if (categories) {
      variables.attributes.category_ids = categories
    }

    const { success, data } = await fetchProducts({
      method: 'POST',
      data: variables,
      addToURL: !token ? '/guest' : ''
    })
    if (success) {
      // si existe prod_custom_img_1 entonces se carga ese imagen en  product_images
      data?.result?.items?.map(product => {
        product.images.map(image => {
          if (image.type_label === 'prod_custom_img_1') {
            product.product_images = [image.value]
          }
        })
      })
      setProducts(data.result)
      return {
        ...data.results
      }
    } else {
      return {
        items: {},
        data: []
      }
    }
  }

  const getCategories = async () => {
    const { success, data } = await fetchCategories({
      method: 'POST',
      data: {
        attributes: {
          product_count: true
        }
      }
    })
    if (success) {
      return {
        ...data.result
      }
    } else {
      return {
        success: false,
        error: 'Error'
      }
    }
  }

  const getLaboratories = async (page = 1) => {
    const { success, data } = await fetchLaboratories({
      method: 'POST',
      data: {
        attributes: {
          product_count: true
        },
        elements: {
          page
        }
      }
    })
    if (success) {
      return {
        ...data.result
      }
    } else {
      return {
        success: false,
        error: 'Error'
      }
    }
  }

  const getProduct = async (id) => {
    const { success, data, error } = await fetchProducts({
      method: 'POST',
      data: {
        attributes: {
          view: 'FRONT',
          product_id: id
        },
        channel: 'FRONT',
        image: { width: 300, height: 300 }
      }
    })
    if (success) {
      setProduct(data.result)
      data.result.items.map(product => {
        product.images.map(image => {
          if (image.type_label === 'prod_custom_img_1') {
            product.product_images = [image.value]
          }
        })
      })
      if (data.result.items && data.result.items.length > 0) {
        return {
          success: true,
          data: data.result.items[0]
        }
      }
      return {
        success,
        data: {}
      }
    }

    return {
      success: false,
      error
    }
  }

  const downloadAll = async (id) => {
    setLoading(id)
    fetchDownload({
      method: 'POST',
      addToURL: `/${id}`
    }).then(({ data }) => {
      if (!data) {
        setLoading(null)
      }
      const a = document.createElement('a')
      a.href = data?.result?.file
      a.download = 'FILENAME'
      // open in other tab
      a.target = '_blank'
      a.click()
      setLoading(null)
    })
  }

  const addRemoveFav = async (id, active) => {
    const method = active ? 'POST' : 'DELETE'
    return await fetchFavorites({
      method,
      data: {
        id
      }
    })
  }

  const getVipHooks = async () => {
    const { success, data } = await fetchHookVip({
      method: 'POST'
    })
    if (success) {
      return {
        data: data.result.banners
      }
    } else {
      return {
        data: []
      }
    }
  }

  const getNovedades = async () => {
    const { success, data } = await fetchNovedades({
      method: 'POST'
    })
    if (success) {
      return {
        data: data.result.banners
      }
    } else {
      return {
        data: []
      }
    }
  }

  return {
    getProducts,
    products,
    getCategories,
    getLaboratories,
    getProduct,
    downloadAll,
    addRemoveFav,
    getVipHooks,
    getNovedades,
    loader
  }
}
