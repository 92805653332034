/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import Logo from '../Logo'
import Nav from '../Nav'
import { LINKS_HEADER } from '../../infrastructure/constants/links'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useUser } from '../../infrastructure/hooks/useUser/useUser'
import Avatar from '../Avatar'
import { SIZE_NAV } from '../../infrastructure/components/Layout/Layout'
import FilterFavoriteBtn from '../FilterFavoriteBtn'
import Spinner from '../Spinner/Spinner'

/**
 * @author ehernandez
 */
const Header = ({ navigateTo, children, onOpenLogin, image, onOpenMobileMenu, openModalLogin, bgNav, autologinToken }) => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const search = searchParams.get('search')

  const onSubmitSearch = (values) => {
    navigate('product-list?search=' + values.search)
  }
  const { user, token } = useUser()

  return (
    <div className={' header  tw-flex md:tw-flex-col tw-sticky tw-top-0 tw-py-4 tw-z-50   tw-w-full  tw-bg-secondary tw-min-h-[80px] tw-items-center tw-justify-center'}>
      <div className="tw-flex tw-max-w-[1560px] tw-items-center tw-w-full md:tw-gap-0 tw-gap-5  md:tw-justify-between">
        <div
          onClick={onOpenMobileMenu}
          className="tw-header-right tw-flex tw-items-center tw-px-4 tw-cursor-pointer lg:tw-hidden"
        >
          <img
            src={require('../../infrastructure/assets/images/layout/menu.png')}
          />
        </div>
        <a href="/" className="header__logo md:tw-relative md:tw-transform-none  tw-absolute tw-top-1/2 tw-left-1/2 md:tw-left-0 tw-transform -tw-translate-x-1/2 -tw-translate-y-1/2   tw-px-4 tw-cursor-pointer">
          <Logo image={image} />
        </a>
        {token && user?.id && <div className={`tw-items-center ${bgNav} tw-justify-center tw-gap-10 tw-mx-auto tw-hidden lg:tw-flex `}
              style={{ height: SIZE_NAV }}
              >
                <Nav
                  onClick={(i) => navigateTo(i)}
                  color="black"
                  links={LINKS_HEADER}
                  className=''
                  colorHover='black' />
                   <FilterFavoriteBtn size={'tw-h-[40px] tw-w-[40px]'} />
         </div>}

          {token && user?.id && <Avatar
             name={user?.name}
             email={user?.email}
             onClick={
              () => {
                window.location.href = 'https://hub.farmapolis.es'
              }
            }
          />}
          {autologinToken && <Spinner />}
        {!token && <div onClick={onOpenLogin} className='tw-text-xl md:tw-hidden  tw-absolute tw-right-0 tw-cursor-pointer tw-w-[50px] tw-flex tw-items-center tw-justify-center'>
          <span className='icon-ic_usuario tw-font-medium tw-text-6xl ' />
        </div>}
        <div className="header__avatar  tw-flex">{children}</div>
      </div>
    </div>
  )
}

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  image: PropTypes.string,
  onOpenMobileMenu: PropTypes.func,
  navigateTo: PropTypes.func,
  autologinToken: PropTypes.string
}
export default Header
