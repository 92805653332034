import React from 'react'
import PropTypes from 'prop-types'
import useDescription from './hooks/useDescription'
import Paragraphs from '../../../Paragraphs'

/**
 * Description
 * This component is part of WrapperInput.
 * @author @jstarsky
 * @param {*} props
 * @return {JSX.Element}
 */
const Description = ({ id, test, hidden, message, type, alignment }) => {
  const { internalClassName, paragraphsClassName } = useDescription({
    id,
    type,
    alignment
  })
  if (!message) return <></>
  return (
    <Paragraphs
      id={`${id}-paragraphs`}
      className={`tw-absolute tw-flex tw-flex-1 tw-w-full tw-pt-1 ${
        hidden || !message ? 'tw-hidden' : ''
      }${paragraphsClassName}`}
      size="xs"
      weight="bold"
    >
      <p
        data-testid={test ? `${test}` : undefined}
        className={internalClassName}
      >
        {message}
      </p>
    </Paragraphs>
  )
}

Description.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  test: PropTypes.string,
  hidden: PropTypes.bool,
  message: PropTypes.string,
  type: PropTypes.oneOf([
    'info',
    'alert',
    'warning',
    'required',
    'validate',
    'pattern',
    'info'
  ]),
  alignment: PropTypes.oneOf(['center', 'end', 'start'])
}

Description.defaultProps = {
  id: 'wrapperInput-description'
}

export default Description
