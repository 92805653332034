import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs'

/**
 * @author ehernandez
 * @param {string} icon
 * @param {string} text
 * @returns {node}
 */
const IconLink = ({ icon, text, href }) => {
  return (

    <a href={href} className="icon-link tw-block md:tw-flex tw-items-center tw-gap-2 tw-cursor-pointer">
      <div className="icon-link__icon">
        <span className={icon} alt="icon" />
      </div>
      <div className="icon-link__content tw-opacity-60">
        <Paragraphs className="icon-link__content__title__text ">
          {text}
        </Paragraphs>
      </div>
    </a>

  )
}

IconLink.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  href: PropTypes.string
}

export default IconLink
