import React from 'react'
import Paragraphs from '../Paragraphs'

const PoliticaCookies = () => {
  return (
    <div
      id="politica-cookies"
      className="tw-w-[80%] tw-mx-auto tw-flex tw-flex-col tw-gap-5 tw-py-10"
    >
      <Paragraphs className={'tw-text-primary'} fontFamily={'Trade'} size="xl">
        Política de cookies
      </Paragraphs>
      <div className="docs">
        <Paragraphs weight={'medium'}>
          1.- Información General de las cookies.
        </Paragraphs>
        <Paragraphs>
          Las Cookies son ficheros que se descarga en su ordenador al acceder a
          nuestra web www.farmapolis.es. Las cookies permiten reconocer el
          navegador de un Usuario y registrar su actividad en una web
          determinada. La finalidad de las Cookies es la de facilitar al usuario
          un acceso más rápido a los servicios seleccionados. Además, las
          Cookies personalizan los servicios que ofrece la Web, facilitando y
          ofreciendo a cada usuario información que es de su interés o que puede
          ser de su interés, en atención al uso que realiza de los Servicios que
          ofrece www.farmapolis.es
        </Paragraphs>
        <Paragraphs>
          Las Cookies empleadas en www.farmapolis.esasocian únicamente a un
          usuario anónimo y su ordenador y no proporcionan referencias que
          permitan deducir datos personales del usuario. El usuario podrá
          configurar su navegador para que notifique y rechace la instalación
          las Cookies enviadas por la Web, sin que ello perjudique la
          posibilidad del usuario de acceder a los contenidos de nuestra página
          en www.farmapolis.es.
        </Paragraphs>
      </div>
      <div className="docs">
        <Paragraphs weight={'medium'}>2.- Identificación de cookies</Paragraphs>
        <Paragraphs>
          Esta página web utiliza cookies de terceros que son aquellas que se
          envían a tu ordenador o terminal desde un dominio o una página web que
          no es gestionada por nosotros, sino por otra entidad que trata los
          datos obtenidos a través de las cookies. En este caso las Cookies son
          utilizadas con fines estadísticos relacionados con las visitas que
          recibe y las páginas que se consultan, quedando aceptado su uso al
          navegar por ella.
        </Paragraphs>
      </div>
      <div id="table-cookies" className='tw-overflow-auto'>
        <Paragraphs weight={'medium'}>3.- Tabla de cookies</Paragraphs>
        <table className="tw-w-full tw-mt-5 ">
          <thead>
            <tr>
              <th className="tw-border tw-border-border tw-p-4 tw-text-center">
                Cookie (y proveedor)
              </th>
              <th className="tw-border tw-border-border tw-p-4 tw-text-center">
                Duración
              </th>
              <th className="tw-border tw-border-border tw-p-4 tw-text-center">
                Descripción
              </th>
            </tr>
          </thead>
          <tbody className="tw-text-sm">
            <tr>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                __cfduid (notin.es)
              </td>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                Sesión
              </td>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                Publicidad
              </td>
            </tr>
            <tr>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                personalization_id (twitter.com)
              </td>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                Sesión
              </td>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                Twitter
              </td>
            </tr>
            <tr>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                Facebook
              </td>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                Publicidad, estadísticas y mediciones
              </td>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                Coloca Cookies en el ordenador o dispositivo y recibe la
                información almacenada en ellas cuando utilizas o visitas
                servicios prestados por otras empresas que utilizan los
                servicios de Facebook.
              </td>
            </tr>
            <tr>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                _ga (Google)
              </td>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                2 años
              </td>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                Se usa para distinguir a los usuarios.
              </td>
            </tr>
            <tr>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                _gid (Google)
              </td>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                24 horas
              </td>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                Se usa para distinguir a los usuarios.
              </td>
            </tr>
            <tr>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                _gat (Google)
              </td>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                1 minuto
              </td>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                {
                  'Se usa para limitar el porcentaje de solicitudes. Si has implementado Google Analytics mediante Google Tag Manager, esta cookie se llamará _dc_gtm_<property-id>.'
                }
              </td>
            </tr>
            <tr>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                _gali (Google)
              </td>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                30 s
              </td>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                Atribución de enlace mejorada.
              </td>
            </tr>
            <tr>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                _unam (SHARETHIS)
              </td>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                Persistente
              </td>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                Su finalidad es cuantificar el número de Usuarios que comparten
                un determinado contenido y cuántas páginas web son visitadas a
                raíz de esa acción.
              </td>
            </tr>
            <tr>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                WordPress
              </td>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                2 años
              </td>
              <td className="tw-border tw-border-border tw-p-4 tw-text-center">
                Utilizado para el correcto funcionamiento del gestor de
                contenido WordPress.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="docs">
        <Paragraphs weight={'medium'}>
          3. Cómo deshabilitar las cookies.
        </Paragraphs>
        <Paragraphs>
          Los usuarios pueden permitir, bloquear o eliminar las cookies
          instaladas en su equipo mediante la configuración de las opciones de
          su navegador de internet. En el caso en que las bloquee, es posible
          que la calidad el funcionamiento de la página Web o algunos servicios
          puedan verse afectados y/o disminuir su óptimo rendimiento. A fin de
          facilitar a los usuarios que deseen impedir la instalación de cookies
          en su ordenador, se informa a los usuarios los enlaces a las
          instrucciones facilitadas al efecto por navegadores cuyo uso considera
          más extendido:
        </Paragraphs>
        <ul className='tw-ml-10 tw-mt-5 tw-flex tw-flex-col tw-gap-1 tw-w-2/3 tw-list-disc tw-font-light tw-text-sm tw-overflow-auto'>
            <span>{'a) Si utiliza Microsoft Internet Explorer, encontrará la opción en el menú Herramientas > Opciones de Internet > Privacidad > Configuración. Para saber más visite:'}</span>
            <a className='tw-underline' href='http://windows.microsoft.com/es-es/windows-vista/block-or-allow-Cookies' target="_blank" rel="noopener noreferrer">http://windows.microsoft.com/es-es/windows-vista/block-or-allow-Cookies</a>
            <a className='tw-underline' href='http://windows.microsoft.com/es-es/windows-vista/block-or-allow-Cookies' target="_blank" rel="noopener noreferrer">http://windows.microsoft.com/es-es/windows7/how-to-manage-Cookies-in-internet-explorer-9</a>
            <span>{'b) Si utiliza Firefox, encontrará la opción en el menú Herramientas > Opciones > Privacidad > Cookies. Para saber más visite:'}</span>
            <a className='tw-underline' href='http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-Cookies-que-los-sitios-we' target="_blank" rel="noopener noreferrer">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-Cookies-que-los-sitios-we</a>
            <span>{'c) Si utiliza Chrome, en la sección de Opciones > Opciones avanzadas > Privacidad.'}</span>
            <a className='tw-underline' href='https://support.google.com/accounts/answer/61416?hl=es' target="_blank" rel="noopener noreferrer">https://support.google.com/accounts/answer/61416?hl=es</a>
            <span>{'d) Si utiliza Opera, en la opción de Seguridad y Privacidad, podrá configurar el navegador.'}</span>
            <a className='tw-underline' href='http://help.opera.com/Windows/10.20/es-ES/cookies.html' target="_blank" rel="noopener noreferrer">http://help.opera.com/Windows/10.20/es-ES/cookies.html</a>
            <span>{'e) Si utiliza Safari encontrará la opción en el menú Preferencias/Privacidad.'}</span>
            <a className='tw-underline' href='http://support.apple.com/kb/HT1677?viewlocale=es_ES' target="_blank" rel="noopener noreferrer">http://support.apple.com/kb/HT1677?viewlocale=es_ES</a>
        </ul>
      </div>
      <div className='docs'>
        <Paragraphs weight={'medium'}>4. Cambios en la política de cookies.</Paragraphs>
        <Paragraphs>REPOFARMA puede modificar esta Política de Cookies en función de exigencias legislativas. En el caso que se produzcan cambios significativos en esta Política de Cookies, se comunicará a los Usuarios mediante mensajes en la propia web. Si desea contactar con nosotros respecto nuestra política de cookies, puede hacerlo en mediante comunicación a REPOFARMA, S.L. a la dirección de correo electrónico info@repofarma.com o mediante escrito Ref: LOPD, C/ Miguel Angel nº 4, bajo 10, Madrid – 28010.</Paragraphs>

      </div>
      <Paragraphs weight={'medium'}>Copyright © REPOFARMA, S.L. Todos los derechos reservados.</Paragraphs>
    </div>
  )
}

export default PoliticaCookies
