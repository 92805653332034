import React from 'react'
import NewItem from '../NewItem'
import PropTypes from 'prop-types'
import SliderProducts from '../../../../components/SliderProducts'
import IconNotice from '../../../../infrastructure/assets/images/home/news-logo.png'
import TitleLogo from '../../../../components/TitleLogo'
import { useTheme } from '../../../../infrastructure/context/ThemeContext/ThemeContext'
/**
 * @author ehernandez
 * @returns {React.Component}
 */
const BlockNews = ({ data }) => {
  const { theme } = useTheme()

  return (
    <div className="block-news  tw-flex tw-flex-col tw-relative tw-justify-center">
      <div className="tw-flex tw-justify-center ">
        <div className="block-news__title tw-absolute -tw-top-48 tw-items-center tw-flex tw-flex-col tw-gap-5">
          <TitleLogo title={'Noticias'} description={!theme.is_lab ? 'Mantente puntualmente informado con las últimas noticias de los laboratorios y sus productos​' : null} url={IconNotice} />
        </div>
      </div>
      <SliderProducts id='home-new' breakpoints={{
        640: {
          slidesPerView: 1
        },
        768: {
          slidesPerView: 2
        },
        900: {
          slidesPerView: 3
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 10
        }

      }}
       navigation={true}
       hideNavigation={false}
       className="block-news__content tw-flex tw-max-w-[90%]   tw-w-[1200px] tw-items-center tw-mt-28 tw-mx-auto">
        {data?.items?.map((item) => (

            <NewItem className='tw-bg-slate-50 tw-w-[300px] tw-max-w-[98%]' {...item} key={item.id} />

        ))}
      </SliderProducts>
    </div>
  )
}

BlockNews.propTypes = {
  data: PropTypes.object
}

export default BlockNews
