import React from 'react'
import PropTypes from 'prop-types'

/**
 * @author ehernandez
 * @description component for wrapper other components width border or shadow
 * @param {node} chidren component react js
 * @returns
 */
const Content = ({ children, className, onClick, border, shadow, transition }) => {
  const transitionClass = ' hover:-tw-translate-y-1 hover:tw-shadow tw-cursor-pointer tw-transition tw-duration-150 tw-ease-out hover:ease-in'
  return (
    <div
      onClick={onClick}
      className={`content  ${border && 'tw-shadow-lg tw-rounded-lg'} ${
        shadow && 'tw-shadow-lg'
      }  ${transition && transitionClass} ${className}`}
    >
      {children}
    </div>
  )
}

Content.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  border: PropTypes.bool,
  shadow: PropTypes.bool,
  transition: PropTypes.bool
}

Content.defaultProps = {
  border: true
}

export default Content
