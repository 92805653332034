import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs'
import { useTranslation } from 'react-i18next'
import Button from '../Button/Button'

/**
 * @author ehernandez
 * @author accept politica de cookies
 * @returns {React.Component}
 */
const Cookies = ({ onCancel, onAccept }) => {
  const { t } = useTranslation()
  return (
    <div data-cy='cookies' className="cookies tw-p-4 tw-bg-tertiary tw-flex tw-w-full md:tw-justify-between tw-flex-wrap tw-gap-x-8 tw-absolute tw-bottom-0">
      <div className="cookies__text tw-flex-1">
        <Paragraphs weight="bold" className="tw-text-white tw-mb-4 md:tw-mb-0 tw-mb-3 tw-text-sm md:tw-text-sm">
          {t('Configuración de Cookies')}
        </Paragraphs>
        <Paragraphs weight="light" className="tw-text-white tw-text-xs md:tw-text-sm tw-leading-5 tw-mb-4 md:tw-mb-0">
          {t(
            'En Farmapolis utilizamos cookies propias y de terceros para mejorar su experiencia y nuestros servicios analizando la navegación en nuestra web.'
          )}
        </Paragraphs>
        <br className='tw-hidden md:tw-block' />
        <Paragraphs weight="light" className="tw-text-white tw-text-xs tw-leading-5 tw-mb-4 md:tw-mb-0">
          {t(
            ' Pulse “Aceptar” si acepta su uso, por el contrario, pulse “Rechazar”. Para configurar la utilización de cookies u obtener más información, pulse aquí.'
          )}
        </Paragraphs>
      </div>
      <div className="cookies__buttons tw-mb-14 md:tw-mb-0 tw-flex tw-flex-row-reverse md:tw-flex-col tw-justify-center tw-items-center tw-gap-4 tw-w-full md:tw-w-[200px]">
        <Button uppercase cy='cookies-accept' onClick={onAccept} label={t('Aceptar')} />
        <Button uppercase onClick={onCancel} label={t('Rechazar')} mode="white" />
      </div>
    </div>
  )
}

Cookies.propTypes = {
  onCancel: PropTypes.func,
  onAccept: PropTypes.func
}

export default Cookies
