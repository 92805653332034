import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs'
import { useTranslation } from 'react-i18next'

const EmptyState = ({ message }) => {
  const { t } = useTranslation()
  return (
    <div className='empty-state tw-flex tw-flex-col tw-items-center tw-gap-6 tw-flex-1 tw-justify-center'>
        <img className='lg:tw-max-w-[600px] xl:tw-max-w-[600px]' src={require('../../infrastructure/assets/images/empty-state.png')} alt='empty-state' />
        <div className='tw-flex tw-flex-col tw-gap-3 tw-text-center tw-items-center'>
            <Paragraphs data-cy= 'no-products-found' weight='bold' className='tw-text-primary' size='xl'>{t('Lo sentimos...')}</Paragraphs>
            <Paragraphs>{message}</Paragraphs>
            </div>
    </div>
  )
}

EmptyState.propTypes = {
  message: PropTypes.string
}

export default EmptyState
