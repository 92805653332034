import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import EcommerceCard from '../../../../components/EcommerceCard/EcommerceCard'
import { BASE } from '../../../../infrastructure/constants/configurations'

const WrapperTarifasCard = (props) => {
  const { isEcommerce } = props
  const { data } = props
  const { handleCheck } = props

  const [selecteds, setSelecteds] = useState([])

  const { id } = props

  /**
   * @author: ehernandez
   * @description when click on download button, if selected or download all
   */
  const onClickDownloadAll = async () => {
    let links = null
    if (selecteds.length > 0) {
      links = selecteds.join(',')
    } else {
      const ids = []
      data?.documents.forEach(i => {
        ids.push(i.id)
      })
      links = ids.join(',')
    }

    const a = document.createElement('a')
    a.href = BASE + `labs/${id}/documents_download?documents=${links}`
    a.download = 'FILENAME'
    a.target = '_blank'
    a.click()
  }

  /**
   * @author: ehernandez
   * @description when clicked on laboratory
   */
  const onSelected = (item) => {
    const index = selecteds.findIndex(i => item === i)
    if (index === -1) {
      setSelecteds([...selecteds, item])
    } else {
      setSelecteds(selecteds.filter(i => item !== i))
    }
  }

  const onClickDownloadSingle = (idItem) => {
    const a = document.createElement('a')
    a.href = BASE + `labs/${id}/documents_download?documents=${idItem}`
    a.download = 'FILENAME'
    a.target = '_blank'
    a.click()
  }

  useEffect(() => {
    setSelecteds([])
  }, [isEcommerce])

  return (
      <div className=' lg:tw-w-[330px] lg:tw-h-[330px] tw-h-[300px] tw-w-[300px] '>
            <EcommerceCard handleCheck={handleCheck} id={data?.id} isEcommerce={isEcommerce} onClickDownload={onClickDownloadSingle} onSelected={onSelected} onClickDownloadAll={onClickDownloadAll} items={data?.tariff_documents} {...props} />
      </div>

  )
}

WrapperTarifasCard.propTypes = {
  props: PropTypes.object,
  id: PropTypes.number,
  isEcommerce: PropTypes.bool,
  download_csv_url: PropTypes.string,
  data: PropTypes.object,
  onSelectedEcommerce: PropTypes.func,
  handleCheck: PropTypes.func
}

export default WrapperTarifasCard
