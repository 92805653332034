import React from 'react'
import PropTypes from 'prop-types'
import useShow from './hooks/useShow'

/**
 * Show component.
 * This component is part of WrapperInput.
 * @author @jstarsky
 * @param {*} props
 * @return {JSX.Element}
 */
const Show = ({ id, test, hidden, show, type, onClick, size, color }) => {
  const { internalClassName, icon } = useShow({
    id,
    type,
    size,
    show,
    hidden,
    color
  })
  return (
    <div
      data-testid={test ? `${test.replace('.', '_')}` : undefined}
      className={internalClassName}
      onClick={onClick}
    >
      <span className={`${icon} tw-ml-2`}></span>
    </div>
  )
}

Show.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  test: PropTypes.string,
  hidden: PropTypes.bool,
  show: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['eye']),
  size: PropTypes.oneOf(['xs', 'sm']),
  color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'white', 'black'])
}

Show.defaultProps = {
  id: 'wrapperInput-show',
  size: 'xs',
  onClick: () => null,
  color: 'primary'
}

export default Show
