/**
 * useError
 * @author @jstarsky
 * @param {*} input
 * @return {Object}
 */
const useError = ({ id, type, size }) => {
  /**
   * setType
   * @author @jstarsky
   * @param {String} input
   * @return {String}
   * https://tailwindcss.com/docs/font-family#header
   */
  const setIcon = (input) => {
    switch (input) {
      case 'alert':
        return 'icon-ic_info_linea_aviso'
      case 'warning':
        return 'icon-ic_info_rojo'
      case 'required':
        return 'icon-ic_info_linea_aviso'
      case 'validate':
        return 'icon-ic_info_linea_aviso'
      case 'pattern':
        return 'icon-ic_info_linea_aviso'
      default:
        return 'icon-ic_info_linea_aviso'
    }
  }
  /**
   * setColor
   * @author @jstarsky
   * @param {String} input
   * @return {String}
   * https://tailwindcss.com/docs/font-family#header
   */
  const setColor = (input) => {
    switch (input) {
      case 'alert':
        return ' tw-text-alert'
      case 'warning':
        return ' tw-text-warning'
      case 'required':
        return ' tw-text-alert'
      case 'validate':
        return ' tw-text-alert'
      case 'pattern':
        return ' tw-text-alert'
      default:
        return ' tw-text-transparent'
    }
  }

  /**
   * setSize
   * @author @jstarsky
   * @param {String} input
   * @return {String}
   * https://tailwindcss.com/docs/font-size#header
   */
  const setSize = (input) => {
    switch (input) {
      case 'xs':
        return ' tw-text-xs'
      case 'sm':
        return ' tw-text-sm'
      default:
        return ' tw-text-sm'
    }
  }

  return {
    internalClassName: `${id} tw-leading-none tw-cursor-pointer clearfix bshadow0 tw-flex tw-items-center  pbs${setColor(
      type
    )}${setSize(size)}`,
    icon: setIcon(type)
  }
}
export default useError
