import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs'
import { useTranslation } from 'react-i18next'
import SliderProducts from '../SliderProducts'
import SocialCard from '../SocialCard/SocialCard'
import Spinner from '../Spinner/Spinner'
import Modal from '../Modal/Modal'
import Button from '../Button/Button'
import { useFavorites } from '../../infrastructure/hooks/useFavorites/useFavorites'
import useProducts from '../../infrastructure/hooks/useProducts'
import axios from 'axios'
import fileDownload from 'js-file-download'

/**
 * @author ehernandez
 */
const ResourcesContainer = ({ menu, onClickLink, items, id, loading, name, imageToRemplace }) => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState(null)
  const { addRemoveFav } = useFavorites()
  const { loader } = useProducts()

  const activeModal = (item) => {
    if (item.type === 'custom_field') {
      const a = document.createElement('a')
      a.href = item.value
      a.download = 'FILENAME'
      a.target = '_blank'
      a.click()
    } else {
      setOpenModal(item)
    }
  }

  const resources = useMemo(() => {
    return items.filter((item) => {
      if (item.key === 'product_image') {
        item.value = imageToRemplace
      }
      if (item.key.includes('product_image_')) {
        return null
      }
      // replace watch for embed video
      if (item.type === 'video') {
        item.value = item.value.replace('watch?v=', 'embed/')
      }
      return item
    })
  }, [items, imageToRemplace])

  const downloadFile = (item) => {
    axios.get(item.value, {
      responseType: 'blob'
    })
      .then((res) => {
        fileDownload(res.data, item.product_id + '.jpg')
      })
  }

  return (
    <div className="resources-container tw-bg-secondary tw-relative tw-p-8 tw-rounded-[6px]">
       <div className='tw-flex tw-absolute tw-bottom-5 tw-right-5'>
              {loader &&
                <Spinner/>
                  }
              </div>
      <div className="tw-flex tw-items-center tw-justify-between tw-flex-col md:tw-flex-row">
        <Paragraphs size="2xl" weight="bold" className=" tw-py-2 tw-px-5">
          {t('Contenidos asociados')}
        </Paragraphs>
      </div>
      <div className="tw-min-h-[342px]">
        {loading
          ? (
            <div className='tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-py-16'>
          <Spinner />
          </div>
            )
          : items && items.length > 0
            ? (
          <SliderProducts
            breakpoints={{
              640: {
                slidesPerView: 1
              },
              768: {
                slidesPerView: 2
              },
              900: {
                slidesPerView: 3
              },
              1200: {
                slidesPerView: 5
              }
            }}
            id={'slider-resources'}
            className="tw-pt-8 tw-flex tw-items-center"
          >
            {resources?.map((item) => {
              return <div onClick={() => activeModal(item)} key={item.id} className="tw-h-[250px] tw-py-4">
                                {item.type === 'video'
                                  ? <iframe width="100%" height="100%" src={item.value} title="YouTube video player" frameBorder="0" allow=" autoplay; clipboard-write;"></iframe>
                                  : <SocialCard
                                    // hideBadge={(active !== 2)}
                                    type={item.type}
                                    visibleBadge={true}
                                    isLogged={true}
                                    image={item.value}
                                    data={item?.data}
                                    onClickFav={(activ) =>
                                      addRemoveFav(item.id, activ, 'rrss_tem_img')
                                          }
                                    isFav={item?.is_favorite}
                 />
                }
              </div>
            })}
          </SliderProducts>
              )
            : <div className='tw-flex tw-justify-center tw-flex-col tw-items-center'>          <Paragraphs size='xl' className='tw-pt-8  tw-text-primary' weight='bold'>Lo sentimos...</Paragraphs>

          <Paragraphs className='tw-text-primary tw-pt-2 ' weight='bold'>No se encontraron recusos para esta seccion</Paragraphs>
          </div>
          }
      </div>
      {openModal && (
            <Modal
        onRequestClose={() => setOpenModal(null)}
        title={name}
        id="preview-resource"
      >
        <div>
        {
          openModal?.type === 'image' && <img className=' tw-max-w-full sm:tw-max-w-[400px]' src={openModal?.value} />
        }
        {
        openModal?.type === 'video' && <iframe width="560" height="315" src={openModal?.value} title="YouTube video player" frameBorder="0" allow=" autoplay; clipboard-write;"></iframe>
        }
        </div>
      <div className='tw-max-w-[240px] tw-mx-auto tw-py-4'>
        <Button
          label={t('Descargar')}
          uppercase
          mode='gradient'
          onClick={() => {
            downloadFile(openModal)
          }}
        />
        </div>
      </Modal>
      )}
    </div>
  )
}

ResourcesContainer.propTypes = {
  menu: PropTypes.array,
  onClickLink: PropTypes.func,
  items: PropTypes.array,
  loading: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.number,
  imageToRemplace: PropTypes.string
}

ResourcesContainer.defaultProps = {
  menu: [],
  items: []
}

export default ResourcesContainer
