/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import Page from '../../components/Page/Page'
import useMutation from '../../infrastructure/hooks/useMutation'
import WrapperTarifasCard from './components/WrapperTarifasCard'
import PropTypes from 'prop-types'
import ListingGroups from '../../components/ListingGroups/ListingGroups'
import Logo from '../../infrastructure/assets/images/logos/ecommerce.png'
import { BgroundDots } from '../HomeScreen/HomeScreen'
import Paragraphs from '../../components/Paragraphs'
import SectionPartners from '../../components/Homev5/SectionPartners/SectionPartners'
import Arkofarma from '../../infrastructure/assets/images/logosPartner/arkpharma.png'
import faesfarma from '../../infrastructure/assets/images/logosPartner/faesfarma.png'
import bellaaurora from '../../infrastructure/assets/images/logosPartner/bellaaurora.png'
import vichy from '../../infrastructure/assets/images/logosPartner/vichy.png'
import { useTheme } from '../../infrastructure/context/ThemeContext/ThemeContext'

const logos = [
  {
    id: 1,
    logo: Arkofarma,
    href: ''
  },
  {
    id: 2,
    logo: faesfarma,
    href: ''
  },
  {
    id: 3,
    logo: bellaaurora,
    href: ''
  },
  {
    id: 4,
    logo: vichy,
    href: ''
  }

]

const TarifasScreen = ({ isEcommerce }) => {
  const [fetchLabs] = useMutation('labs')
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  // const [selectedEcommerce, setSelectedEcommerce] = useState([])
  const [checked, setChecked] = useState([])
  const { theme } = useTheme()

  const getLabs = async (page = 1, values = {}) => {
    const variables = {
      elements: { page, per_page: 25 },
      attributes: {}
    }

    variables.attributes.type = 'ecommerce'
    variables.attributes.has_ecommerce_documents = true

    setLoading(true)
    const { success, data } = await fetchLabs({
      method: 'POST',
      data: variables
    })
    setLoading(false)
    if (success) {
      setData(data.result)
    }
  }

  useEffect(() => {
    setLoading(true)
    getLabs().then(() => setLoading(false))
  }, [isEcommerce])

  // Add/Remove checked item from list
  const handleCheck = (event) => {
    let updatedList = [...checked]
    if (event.target.checked) {
      updatedList = [...checked, event.target.value]
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1)
    }
    setChecked(updatedList)
  }

  const textEcommerce = true

  return (
    <Page.Container
      removeContainer
      id="tarifas-screen"
      name="tarifas-screen"
      bgLine
      subTitle={`${'Contenido gratuito Premium patrocinado por el laboratorio'}`}
      urlLogo={Logo}
      title={`${'Material eCommerce'}`}
      textEcommerce={textEcommerce}
      description={theme?.is_lab && null }
    >
      <Page.Body className={'tw-mx-auto tw-max-w-[100%] tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center'}>
        <ListingGroups
          loading={loading}
          paginatorInfo={data?.data}
          getData={getLabs}
          className={''}
        >
          <div className={'tarifas-container tw-justify-center tw-items-center tw-flex tw-flex-wrap tw-gap-5 tw-max-w-[100%] tw-mx-auto'}>

            {data?.items?.map((i, index) => (
              <WrapperTarifasCard
                handleCheck={handleCheck}
                checked={checked}
                isEcommerce={true}yh
                data={i}
                key={i.id}
                index={index}
                {...i}
              />
            ))}

          </div>
        </ListingGroups>
      </Page.Body>
      <div className="tw-h-[140px] tw-relative">
        <BgroundDots className=" tw-absolute tw-left-0 tw-h-[200px] -tw-bottom-[30px] -tw-z-20 tw-flex tw-w-full" />
      </div>
      {!theme?.is_lab &&
      <div className="tw-bg-secondary tw-py-10 ">
        <div className="tw-max-w-[1400px] tw-mx-auto tw-px-4 ">
          <div className="lg:tw-max-w-[50%] tw-mx-auto ">
            {/* <Paragraphs weight="medium" size="4xl" className="tw-text-center">
              Más contenido de calidad<br/>que puedes descargar de otros laboratorios
            </Paragraphs> */}
            {/* <div className="tw-h-[3px] tw-my-10  tw-w-[100px]  tw-bg-gradient-to-r  tw-from-primary tw-to-tertiary tw-mx-auto" /> */}
            <Paragraphs size={'lg'} weight={'normal'} className="tw-text-center">
              Más contenido de calidad que puedes descargar de otros laboratorios.
            </Paragraphs>
          </div>
          <div className="tw-py-8 tw-mt-10">
            <SectionPartners id='slider-ecommerce-partners' title="" logos={logos} />
          </div>
        </div>
      </div>}
    </Page.Container>
  )
}

TarifasScreen.propTypes = {
  isEcommerce: PropTypes.bool
}

export default TarifasScreen
