/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
// import { useEffect } from 'react'
import Paragraphs from '../Paragraphs'
// import { useNavigate } from 'react-router-dom'

/**
 * Componente para la paginación de los resultados de la búsqueda
 * @param {Number} totalPages
 * @param {Number} currentPage
 * @param {Function} onPageChange
 * @param {String} id required
 * @constructor
 */
const Paginator = ({
  current_page: currentPage,
  last_page: lastPage,
  id,
  getData
}) => {
  const totalPagesArray = new Array(lastPage).fill(0).map((_, i) => i + 1)
  if (!currentPage) return null
  return (
    <div
      id={`paginator-${id}`}
      className="paginator tw-gap-5   tw-select-none tw-flex tw-justify-between tw-items-center"
    >
      {currentPage > 1 &&
       <div className={'md:tw-flex  tw-flex-wrap'}>
        {currentPage > 1
          ? (
          <div
            onClick={() => getData(currentPage - 1)}
            className={' tw-flex tw-items-center tw-cursor-pointer'}
          >
            <span className="icon-ic_arrow_right  tw-text-black tw-rotate-180 tw-text-[26px]" />
          </div>
            )
          : (
          <div
            onClick={() => getData(currentPage - 1)}
            className={' tw-flex tw-items-center tw-cursor-pointer'}
          >
            <span className="icon-ic_arrow_right tw-rotate-180 tw-text-[26px]" />
          </div>
            )}
       </div>}

      <div className="tw-flex tw-gap-4 tw-flex-wrap  tw-justify-center tw-items-end tw-gap-x-2 tw-cursor-pointer">
        {currentPage > 1 && (
          <div
            data-cy={`paginator-${id}-prev`}
            key={1}
            className={
              'tw-h-[35px] tw-w-[35px] tw-flex tw-items-center tw-justify-center tw-rounded-[6px] tw-bg-gray-100'
            }
            onClick={() => getData(1)}
          >
            <Paragraphs weight="bold">...</Paragraphs>
          </div>
        )}
        {totalPagesArray?.slice(currentPage - 1, currentPage + 4).map((page) => {
          return (
            <div
              data-cy={`paginator-${page}`}
              key={page}
              className={`tw-h-[35px] tw-w-[35px] tw-flex tw-items-center tw-justify-center  tw-rounded-[6px] ${
                page === currentPage
                  ? 'tw-bg-gradient-to-br tw-from-primary tw-to-tertiary tw-text-white'
                  : 'tw-bg-gray-100'
              }`}
              onClick={() => getData(page)}
            >
              <Paragraphs weight="bold">{page}</Paragraphs>
            </div>
          )
        })}
        {currentPage < lastPage - 5 && (
          <div
            data-cy={`paginator-${lastPage - 1}`}
            key={lastPage - 1}
            className={
              'tw-h-[35px] tw-w-[35px] tw-flex tw-items-center tw-justify-center tw-rounded-[6px] tw-bg-gray-100'
            }
            onClick={() => getData(lastPage - 1)}
          >
            <Paragraphs weight="bold">...</Paragraphs>
          </div>
        )}
        {currentPage < lastPage - 4 && (
          <div
            data-cy={`paginator-${lastPage}`}
            key={lastPage}
            className={
              'tw-h-[35px] tw-w-[35px] tw-flex tw-items-center tw-justify-center tw-rounded-[6px] tw-bg-gray-100'
            }
            onClick={() => getData(lastPage - 1)}
          >
            <Paragraphs weight="bold">{lastPage}</Paragraphs>
          </div>
        )}
      </div>

       {currentPage !== lastPage && <div className={''}>
        {currentPage < lastPage
          ? (
          <div
            onClick={() => getData(currentPage + 1)}
            className="tw-w-full  tw-text-primary tw-flex tw-items-center tw-cursor-pointer"
          >
            <span className="icon-ic_arrow_right  tw-text-black tw-text-[26px]" />
          </div>
            )
          : (
          <div
            onClick={() => getData(currentPage + 1)}
            className="tw-w-full tw-flex tw-items-center tw-cursor-pointer"
          >
            <span className="icon-ic_arrow_right tw-text-[26px]" />
          </div>
            )}
      </div>}
    </div>
  )
}

Paginator.propTypes = {
  last_page: PropTypes.number,
  current_page: PropTypes.number,
  id: PropTypes.string,
  onPageChange: PropTypes.func,
  currentPage: PropTypes.number
}

export default Paginator
