import React from 'react'
import Paragraphs from '../../../../components/Paragraphs'
import SliderProducts from '../../../../components/SliderProducts'
import { ContainerItemNewsLaboratory } from '../../styles'
import propTypes from 'prop-types'

const SliderInterests = ({ info }) => {
  return (
        <div className='tw-bg-secondary tw-flex tw-flex-col tw-py-8 tw-justify-center tw-gap-5 tw-w-full lg:tw-w-2/3 tw-p-3  md:tw-p-5'>
        <Paragraphs className={'md:tw-pl-[110px] tw-text-center md:tw-text-left'} weight={'bold'} size= '2xl'>Enlaces de interés</Paragraphs>

        <SliderProducts
      className='tw-flex tw-mx-auto tw-justify-center tw-items-center tw-w-full  md:tw-w-[90%]'
      id='enlaces-interes'
      spaceBetween={10}
       breakpoints={{
         640: {
           slidesPerView: 1
         },
         768: {
           slidesPerView: 1
         },
         900: {
           slidesPerView: 1
         },
         1200: {
           slidesPerView: 2
         }
       }}
       navigation={true}
       hideNavigation={false}
      >
          {info?.newness.map((i) => (
            <div key={i.id} className='tw-bg-white md:tw-w-[350px]tw-mx-auto md:tw-h-[350px] '>
            <a className='tw-max-w-full' href={i.url} target='_blank' rel="noreferrer">
            <ContainerItemNewsLaboratory
             img={i?.image}
             className="laboratory__products__items__detail__img tw-h-[250px] tw-w-full"
             />
             <div className="laboratory__products__items__detail__description tw-max-w-full tw-p-4">
                  <Paragraphs weight="bold" className="tw-text-black">
                    {i?.title}
                  </Paragraphs>
                  <Paragraphs size="sm" className="tw-text-black tw-mt-3">
                    {i?.body?.substring(0, 50)}
                  </Paragraphs>
                </div>
            </a>
            </div>
          ))}
      </SliderProducts>

      </div>
  )
}

export default SliderInterests

SliderInterests.propTypes = {
  info: propTypes.object
}
