import styledComponents, { keyframes } from 'styled-components'

const rotate = keyframes`
    0% {
      top: 0px;
      height: 24px;
    },
    50%{
      top: 4px;
      height: 12px;
    },
    100% {
      top: 14px;
      height: 12px;
    }
`

export const SpinnerContainer = styledComponents.div`
&{
    display: inline-block;
    position: relative;
    width: 60px;
    height: 20px;
}

& div{
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 10px;
    background: #000;
    animation: ${rotate} 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    height: 10px;
}

& div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
 & div:nth-child(2) {
    left: 24px;
    animation-delay: -0.12s;
  }
  & div:nth-child(3) {
    left: 38px;
    animation-delay: 0;
  }


`
