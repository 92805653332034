import { useState } from 'react'
import { FAVORITES } from '../../constants/api'
import useMutation from '../useMutation'

export const useFavorites = () => {
  const [fetchFavorites] = useMutation(FAVORITES)

  const [data, setData] = useState({})
  const getFavorites = async ({ mode = 'products', page = 1 }) => {
    const { success, data } = await fetchFavorites({
      method: mode === 'products' ? 'POST' : 'GET',
      addToURL: `/${mode}`,
      data: {
        elements: { page, per_page: 12 },
        channel: 'FRONT'
      }
    })
    if (success) {
      setData({
        ...data
      })
      return {
        ...data.result
      }
    } else {
      return {
        items: {},
        data: []
      }
    }
  }

  const addRemoveFav = async (id, active, mode) => {
    const method = active ? 'POST' : 'DELETE'
    return await fetchFavorites({
      method,
      addToURL: `/${mode}`,
      data: {
        id
      }
    })
  }
  return {
    getFavorites,
    data,
    addRemoveFav
  }
}
