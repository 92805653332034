/* eslint-disable no-const-assign */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable array-callback-return */
import React, { useEffect, useState, useRef } from 'react'
import BlockAlert from '../../components/BlockAlert'
import Page from '../../components/Page/Page'
import useProducts from '../../infrastructure/hooks/useProducts'
import { useUser } from '../../infrastructure/hooks/useUser/useUser'
import IconNovedades from '../../infrastructure/assets/images/logos/novedades.png'
import Paragraphs from '../../components/Paragraphs'
import { useTheme } from '../../infrastructure/context/ThemeContext/ThemeContext'
import Paginator from '../../components/Paginator/Paginator'
import Spinner from '../../components/Spinner/Spinner'
import { useSearchParams } from 'react-router-dom'
import EmptyState from '../../components/EmptyState'
import { useTranslation } from 'react-i18next'
import { Element, scroller } from 'react-scroll'
import PopUpNews from './components/PopUpNews'
import Modal from '../../components/Modal/Modal'
import Button from '../../components/Button/Button'
/**
 * @author ehernandez
 * @returns
 */
const NewsProductsScreen = () => {
  const { token } = useUser()
  const {
    // addRemoveFav,
    // downloadAll,
    getVipHooks,
    getNovedades
  } = useProducts()
  const { t } = useTranslation()

  const { theme } = useTheme()
  const [productNews, setProductNews] = useState([])
  const [productDestacados, setProductDestacados] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [productsPerPage] = useState(48)
  const [loading, setLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [showAlert, setShowAlert] = useState(false)
  const refScroll = useRef(null)
  const page = searchParams.get('page')
  useEffect(() => {
    if (page) {
      setCurrentPage(page)
    }
  }, [page])

  const getDataPage = (page) => {
    setSearchParams({ page })
    setCurrentPage(page)

    scroller.scrollTo('novedades-group', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -10
    })
  }

  /**
  * @author cperez
  * @description Function that obtains the vip products from the specific hook endpoint
  */

  const getVipHook = async () => {
    setLoading(true)
    const { data, error } = await getVipHooks()
    if (data) {
      setProductDestacados(data)
      setLoading(false)
    }
    if (error) {
      setLoading(false)
      console.log(error)
    }
  }

  /**
   * @author cperez
   * @description Function that obtains the news products from the specific hook endpoint
   */
  const getNovedadesHook = async () => {
    setLoading(true)
    const { data, error } = await getNovedades()
    if (data) {
      setProductNews(data)
      setLoading(false)
    }
    if (error) {
      setLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    getVipHook()
    getNovedadesHook()
  }, [])

  /**
   * @author cperez
   * @description Function that obtains the current products to show in the paginator
   */
  const indexOfLastProduct = currentPage * productsPerPage
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage
  const currentProducts = productNews?.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  )
  const lastPage = Math.ceil(productNews?.length / productsPerPage)
  return (
    <Page.Container
      novedades
      title={'Novedades'}
      urlLogo={IconNovedades}
      description={
        'Conoce las últimas novedades de producto que salen al mercado, haz el pedido y ofrecérselo a tus clientes​'
      }
      name="product-listing-screen"
      id="product-listing-screen"
    >
      { showAlert && showAlert.popup_url && showAlert.popup_title
        ? <PopUpNews
         onRequestClose={() => setShowAlert(false)}
         url = {showAlert.link}
         data = {showAlert}

      />
        : showAlert && <Modal
      show={showAlert}
      onRequestClose={() => setShowAlert(false)}

    >
      <div className='tw-w-full tw-flex tw-flex-col tw-items-center tw-p-3 tw-gap-5'>
        <Paragraphs>Redirección a pagina de laboratorio</Paragraphs>
        <Button
        size={'medium'}
          label='Aceptar'
          onClick={() => {
            window.open(showAlert.link, '_blank')
            setShowAlert(false)
          }}
        />
      </div>
      </Modal>}
      {!theme?.is_lab && (
        loading
          ? <div className="tw-w-full tw-flex tw-justify-center">
        <Spinner />
      </div>
          : <div className="tw-flex tw-items-center tw-flex-wrap tw-p-5 tw-justify-center tw-gap-10 tw-w-full ">
          { productDestacados?.length > 0
            ? productDestacados?.map((item, index) => (
              <div className='tw-cursor-pointer'
                 key = {index}
                 onClick={() => { setShowAlert(item) }}

              >
              <img
                src={item.url}
                alt={item.name}
                className=" tw-w-[300px] tw-object-cover tw-rounded-xl"
              />
              </div>
            ))
            : <EmptyState message={t('No se encontraron resultados')} />}
        </div>
      )}
      <div ref={refScroll} className="tw-mx-auto tw-mt-10">
        <Element name="novedades-group">
          <Paragraphs weight="medium" size="4xl">
            Todas las novedades
          </Paragraphs>
        </Element>
      </div>
      <Page.Body className="tw-relative md:tw-mt-10  tw-mt-22">
        {loading
          ? (
          <div className="tw-w-full tw-flex tw-justify-center">
            <Spinner />
          </div>
            )
          : (
          <>
            { currentProducts?.length > 0 && <div className="product-listing-screen__content__product tw-w-[85%] lg:tw-grid lg:tw-grid-cols-5 tw-max-w-[85%] tw-py-5 tw-mx-auto tw-items-start tw-justify-center  tw-gap-10 tw-flex tw-flex-wrap">
              {currentProducts?.map((i) => (
                <div
                  key={i.id}
                  onClick = {() => {
                    setShowAlert(i)
                  }}
                  className="tw-items-center tw-max-w-[240px] tw-justify-center tw-shadow-md tw-rounded-xl tw-overflow-hidden hover:-tw-translate-y-1 hover:tw-shadow tw-cursor-pointer tw-transition tw-duration-150 tw-ease-out hover:ease-in"
                >
                    <img src={i.url} className='tw-w-full' />
                </div>
              ))}
            </div>}
            {currentProducts?.length > 0
              ? (
              <div className="tw-flex tw-items-center tw-justify-center tw-mt-5">
                <Paginator
                  getData={getDataPage}
                  current_page={Number(currentPage)}
                  last_page={lastPage}
                  id="product-news-screen__paginator"
                />
              </div>
                )
              : <EmptyState message={t('No se encontraron resultados')} />}
            <div className="tw-z-30 tw-bottom-10 tw-left-0 tw-w-full tw-px-4">
              {!token && <BlockAlert />}
            </div>
          </>
            )}
      </Page.Body>
    </Page.Container>
  )
}

NewsProductsScreen.propTypes = {}

export default NewsProductsScreen
