export const LOGIN = 'auth/login'
export const REGISTER = 'auth/register'
export const BRANDING = 'branding'
export const PRODUCTS = 'products'
export const CATEGORIES = 'categories'
export const LABS = 'labs'
export const RSS = 'product-resources'
export const TRAINING = 'trainings'
export const NOTICES = 'notices'
export const PRODUCT_DOWNLOAD = 'products/download'
export const FAVORITES = 'favorites'
export const PRODUCT_RESOURCES = 'product-resources'
export const HOOKS = 'hooks'
export const FORGOT_PASSWORD = 'auth/forgot-password'
export const HOOKS_VIP = 'hooks/NOVEDADES-VIP'
export const HOOKS_NOVEDADES = 'hooks/NOVEDADES-TOP'
