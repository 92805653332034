import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../../../Paragraphs'

const BannerWelcome = ({ logoFarma, logoBackground, vector }) => {
  return (
    <div className="md:tw-h-[70vh]">
    <img src={logoBackground} className = 'tw-absolute -tw-z-10 md:tw-top-[380px] tw-h-[400px] md:tw-h-[600px] tw-w-[1600px] tw-left-1/2 tw-transform -tw-translate-x-1/2 -tw-translate-y-1/2' />
      <div className="tw-flex tw-p-10 tw-justify-center  tw-gap-5  tw-items-center lg:tw-flex-nowrap tw-flex-wrap-reverse ">
          <div id="text-banner" className="sm:tw-p-5  ">
        <img src={logoFarma} alt='farmapolis' className='sm:tw-w-[300px] tw-w-[200px]' />
          <Paragraphs size="2xl" weight={'medium'} className="sm:tw-w-[330px] tw-mt-8">
              La nueva &quot;ciudad virtual&quot; donde la vida se hace fácil
          </Paragraphs>
          </div>
          <div id="vector-banner">
          <img className='xl:tw-w-[900px] tw-w-[500px]' src={vector} alt="vector-city" />
          </div>
      </div>
  </div>
  )
}

export default BannerWelcome

BannerWelcome.propTypes = {
  logoFarma: PropTypes.string,
  logoBackground: PropTypes.string,
  vector: PropTypes.string
}
