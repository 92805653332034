/**
 * useIcon
 * @author @jstarsky
 * @param {*} input
 * @return {Object}
 */
const useIcon = ({ id, icon, size }) => {
  /**
   * setType
   * @author @jstarsky
   * @param {String} input
   * @return {String}
   * https://tailwindcss.com/docs/font-family#header
   */
  const setIcon = (input) => {
    switch (input) {
      case 'search':
        return 'icon-ic_search'
      case 'euro':
        return 'icon-ic_info_liena_euro'
      case 'phone':
        return 'icon-ic_map'
      default:
        return 'icon-ic_search'
    }
  }

  /**
   * setSize
   * @author @jstarsky
   * @param {String} input
   * @return {String}
   * https://tailwindcss.com/docs/font-size#header
   */
  const setSize = (input) => {
    switch (input) {
      case 'sm':
        return ' tw-text-[18px]'
      default:
        return ' tw-text-xl'
    }
  }

  return {
    internalClassName: `${id} tw-w-full tw-leading-none tw-cursor-pointer clearfix bshadow0 pbs${setSize(
      size
    )}`,
    icon: setIcon(icon)
  }
}
export default useIcon
