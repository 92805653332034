/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { createContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { actionOpenLogin, actionOpenRegister } from '../../redux/actions/layoutActions'

const Context = createContext({})

export const UrlContextProvider = ({ children }) => {
  const [queryModeSign, setQueryModeSign] = useState(false)
  const [product, setProduct] = useState(null)
  const [hideBanner, setHideBanner] = useState('')
  const dispatch = useDispatch()
  useEffect(() => {
    if (queryModeSign === 'register') {
      dispatch(actionOpenRegister())
    } else if (queryModeSign === 'login') {
      dispatch(actionOpenLogin())
    }
  }, [queryModeSign])

  return (
    <Context.Provider
      value={{
        queryModeSign,
        setQueryModeSign,
        setProduct,
        product,
        setHideBanner,
        hideBanner
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Context
