import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Page from '../../components/Page/Page'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import BannerLaboratory from '../HomeScreen/components/BannerLaboratory'
import useLaboratories from '../../infrastructure/hooks/useLaboratories'
// import SliderFeatured from './components/SliderFeatured'
import SliderCatalog from './components/SliderCatalog'
import SliderInterests from './components/SliderInterests'
import DocumentsSection from './components/DocumentsSection'
import SliderRrss from './components/SliderRrss'
import SliderTrainings from './components/SliderTrainings'

const LaboratoriesScreen = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const [info, setInfo] = useState(null)

  const { getLaboratories } = useLaboratories()

  // si no hay data de laboratorios se deja el catalogo de laboratorio
  const getLaboratoryInfo = async () => {
    const { data } = await getLaboratories({ id })
    if (data) {
      if (data?.content || data.newness.length > 0 || data?.images.length > 0) {
        setInfo(data)
      } else {
        navigate('/product-list?laboratories=' + id)
      }
    }
  }

  useEffect(() => {
    getLaboratoryInfo()
  }, [])

  useEffect(() => {
    if (info?.content) {
      const el = document.getElementById('items-content')
      if (el?.onclick) {
        el.onclick = (e) => {
          const parent = e.target.closest('.faq-item')

          if (parent?.classList) {
            parent.classList.toggle('open')
          }
        }
      }
    }
  }, [info])

  return (
    <Page.Container id="laboratory-screen" className={'tw-w-full'}>
      <Page.Body className={'tw-w-full tw-p-0 md:tw-p-4'}>
        {info?.logo_url && <div id="logo" className="tw-w-full tw-flex tw-items-center tw-justify-center tw-p-10 tw-mb-5">
          <img width={250} src={info?.logo_url} alt={info?.name} />
        </div>}
        <div id="banner-images" className='tw-w-[90%] tw-mx-auto tw-hidden sm:tw-flex'>
          <BannerLaboratory
            laboratorieScreen
            onSubmitSearch={(e) =>
              navigate(`/product-list?laboratories=${id}&search=${e.search}`)
            }
            onClickAllProducts={() =>
              navigate('/product-list?laboratories=' + id)
            }
            titleAction={t('Ver en todo el catalogo de {{lab}}', {
              lab: info?.name
            })}
            placeholder={t('Buscar en {{lab}}', { lab: info?.name })}
            images={info?.images}
          />
        </div>

        {/*
          * Seccion donde irían ganchos pertenecientes a laboratorio (si existen) actualmente hay imagenes estaticas
         <div id="slider-destacados" className='md:tw-w-[90%] tw-w-full tw-mx-auto'>
          <SliderFeatured info={info} />
        </div> */}
        {info?.featured_products.length > 0 && <div id="slider-catálogo" className='md:tw-w-[90%] tw-mx-auto'>
          <SliderCatalog info={info} />
        </div>}

        <div
          id="slider-interest-documents"
          className="tw-mx-auto tw-py-8 tw-mt-8 tw-gap-10 tw-flex tw-flex-col lg:tw-flex-row md:tw-w-[90%] "
        >
          {info?.newness.length > 0 && <SliderInterests info={info} />}
          <DocumentsSection info={info} />
        </div>
        {info?.rrss_contents.length > 0 && <div id="slider-rrss" className='md:tw-w-[90%] tw-mx-auto'>
          <SliderRrss info={info} />
        </div>}
        {info?.trainings.length > 0 && <div id="slider-trainings" className='md:tw-w-[90%] tw-mx-auto'>
          <SliderTrainings info={info} />
        </div>}
      </Page.Body>
    </Page.Container>
  )
}

export default LaboratoriesScreen
