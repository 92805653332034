import React from 'react'
import PropTypes from 'prop-types'
import useIcon from './hooks/useIcon'

/**
 * Icon component.
 * @author @jstarsky
 * @param {*} props
 * @return {JSX.Element}
 */
const Icon = ({ id, test, icon, size }) => {
  const { internalClassName, icon: i } = useIcon({ id, icon, size })
  return (
    <div
      data-testid={test ? `${test}` : undefined}
      className={internalClassName}
    >
      <div className=" tw-flex tw-flex-1 tw-justify-center">
        <span className={`${i}`}></span>
      </div>
    </div>
  )
}

Icon.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  test: PropTypes.string,
  hidden: PropTypes.bool,
  icon: PropTypes.oneOf(['search', 'euro', 'phone']),
  size: PropTypes.oneOf(['sm'])
}
Icon.defaultProps = {
  id: 'wrapperInput-icon',
  size: 'sm'
}
export default Icon
