export const INPUTS = [
  {
    id: 'name',
    type: 'text',
    label: 'Nombre y apellidos',
    name: 'name'
  },
  {
    id: 'email',
    type: 'text',
    label: 'Correo electrónico',
    name: 'email'
  },
  {
    id: 'company.name',
    type: 'text',
    label: 'Farmacia',
    name: 'company.name'
  },
  {
    id: 'address.city',
    type: 'text',
    label: 'Dirección',
    name: 'address.city'
  },
  {
    id: 'address.postcode',
    type: 'text',
    label: 'Código postal',
    name: 'address.postcode'
  },
  {
    id: 'address.mobile',
    type: 'text',
    label: 'Teléfono',
    name: 'address.mobile'
  }
]
