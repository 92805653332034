import React from 'react'
import Paragraphs from '../Paragraphs'

const PoliticaPrivacidad = () => {
  return (
    <div
      id="polticia-privacidad"
      className="tw-w-[80%] tw-mx-auto tw-flex tw-flex-col tw-gap-5 tw-py-10"
    >
      <Paragraphs className={'tw-text-primary'} fontFamily={'Trade'} size="xl">
        Politica de Privacidad
      </Paragraphs>
      <Paragraphs>
        REPOFARMA, S.L (en adelante REPOFARMA) asume un compromiso permanente
        con la privacidad y le informa que los datos personales serán tratados
        de conformidad con lo dispuesto en el Reglamento (UE) 2016/679 de 27 de
        abril de 2016 (GDPR) relativo a la protección de las personas físicas en
        lo que respecta al tratamiento de datos personales y a la libre
        circulación de estos datos), así como con la Ley 34/2002, de 11 de
        julio, de Servicios de la Sociedad de la Información y Comercio
        Electrónico (LSSICE o LSSI). La presente Política de Privacidad y
        Cookies puede variar, entre otros aspectos, en función de exigencias
        legislativas o de autorregulación, por lo que se aconseja a los Usuarios
        que la visiten periódicamente. Cuando Ud. accede a la Web
        www.farmapolis.es (en adelante, Web) pasa a tener la condición de
        Usuario. La presente Política de Privacidad y Cookies constituye junto
        con los términos y condiciones de uso el conjunto de normas y políticas
        que regulan el uso de la Web, así como la relación entre REPOFARMA y el
        Usuario
      </Paragraphs>
      <div className="docs">
        <Paragraphs weight={'medium'}>
          1. ¿Quién es el responsable del tratamiento de sus datos personales?
        </Paragraphs>
        <Paragraphs>
          El responsable del tratamiento de los datos personales es REPOFARMA,
          S.L. cuyos datos son los siguientes:
          <br />
        </Paragraphs>
        <Paragraphs className={'tw-ml-10'}>
          <span className="tw-font-bold">Domicilio social:</span> C/ Miguel
          Angel nº 4, bajo 10, Madrid - 28010
          <br />
          <span className="tw-font-bold"> C.I.F:</span> B-40638751
          <br />
          <span className="tw-font-bold">Registro Público:</span> Inscrita en el
          Registro Mercantil de Madrid, Tomo 40925, Libro 0 Folio 133, Sección
          8, Hoja M-725934. Inscrita en el Registro Mercantil de Madrid, Tomo
          40925, Libro 0 Folio 133, Sección 8, Hoja M-725934.
          <br />
          <span className="tw-font-bold">Email:</span> info@repofarma.com
        </Paragraphs>
      </div>
      <div className="docs">
        <Paragraphs weight={'medium'}>
          2. Principios aplicables al tratamiento de datos personales.
        </Paragraphs>
        <Paragraphs>
          En el tratamiento de los datos personales de los Usuarios, REPOFARMA
          aplica los siguientes principios:
        </Paragraphs>
        <ul className="tw-ml-10 tw-mt-5 tw-flex tw-flex-col tw-gap-1 tw-w-2/3 tw-list-disc tw-font-light tw-text-sm">
          <li>
            <span className="tw-font-bold">
              Principio de licitud, lealtad y transparencia:
            </span>{' '}
            REPOFARMA siempre requerirá el consentimiento del usuario para el
            tratamiento de sus datos personales, bien sea para uno o varios
            fines específicos, informándose de este extremo a los Usuarios de
            forma previa.
          </li>
          <li>
            <span className="tw-font-bold">
              Principio de minimización de datos:
            </span>{' '}
            REPOFARMA solo solicitará los datos estrictamente necesarios en
            relación con los fines para los que se requiera.
          </li>
          <li>
            <span className="tw-font-bold">
              Principio de limitación del plazo de conservación:
            </span>{' '}
            REPOFARMA conservara los datos no más tiempo del necesario para los
            fines del tratamiento.
          </li>
          <li>
            <span className="tw-font-bold">
              Principio de integridad y confidencialidad:
            </span>{' '}
            Los datos personales de los Usuarios serán tratados de tal manera
            que se garantice una seguridad adecuada de los mismos y se garantice
            su confidencialidad.
          </li>
        </ul>
      </div>
      <div className="docs">
        <Paragraphs weight={'medium'}>
          3. ¿Cómo recogemos los datos personales?
        </Paragraphs>
        <Paragraphs>
          Los datos que nos facilitan los Usuarios tienen carácter voluntario,
          si bien la negativa a facilitar los datos solicitados puede comportar
          la imposibilidad de acceso a los servicios que lo requieran. Durante
          el proceso de recogida de datos, y siempre que se soliciten datos, se
          informa a los Usuarios del carácter obligatorio o voluntario de la
          recogida de datos.
        </Paragraphs>
        <ul className="tw-ml-10 tw-mt-5 tw-flex tw-flex-col tw-gap-1 tw-w-2/3 tw-list-disc tw-font-light tw-text-sm">
          <li>
            <span className="tw-font-bold">
              Datos facilitados de forma voluntaria por los Usuarios:
            </span>{' '}
            Cuando el Usuario contacta con nosotros con un comentario, duda o
            para solicitar información; cuando se da de alta como usuario en la
            Web; cuando se suscribe para recibir boletines informativos y
            newsletters sobre nuestros servicios; y cuando envía el CV si están
            interesado en alguna candidatura.
          </li>
          <li>
            <span className="tw-font-bold">
              Datos facilitados por los Usuarios durante la navegación en la
              web:
            </span>{' '}
            son aquellos datos personales resultantes de la aplicación de
            algoritmos para analizar por ejemplo el interés de nuestros Usuarios
            por determinados servicios, navegación realizada en nuestra Web…etc.
          </li>
        </ul>
      </div>
      <div className="docs">
        <Paragraphs weight={'medium'}>
          4. ¿Qué datos personales tratamos?
        </Paragraphs>
        <ul className="tw-ml-10 tw-mt-5 tw-flex tw-flex-col tw-gap-1 tw-w-2/3 tw-list-disc tw-font-light tw-text-sm">
          <li>
            <span className="tw-font-bold">
              Información personal y de contacto.
            </span>
            Esta información la proporciona o facilita el Usuario a REPOFARMA
            cuando se pone en contacto por cualquier cuestión con nosotros a
            través del formulario de contacto o cuando se suscribe a boletines
            informativos y newsletters sobre nuestros servicios o cuando se da
            de alta como usuario en nuestra Web.
          </li>
          <li>
            <span className="tw-font-bold">
              Información sobre la conexión, navegación, interacción los
              dispositivos y el uso de nuestra Web.
            </span>
            REPOFARMA recoge y trata los datos concernientes a los dispositivos
            desde los que los Usuarios acceden a nuestra Web, es decir si es un
            PC o un móvil, dirección IP, así como la información relativa a la
            navegación en nuestra Web, etc.
          </li>
          <li>
            <span className="tw-font-bold">
              Información sobre carrera profesional
            </span>
            REPOFARMA recoge y trata toda la información personal, información
            académica, profesional y curricular, así como los datos de contacto
            que los Usuarios nos proporcionan cuando solicitan un puesto de
            trabajo o presentan una candidatura a cualquiera de los puestos de
            trabajo o vacantes. Los datos personales, académicos, profesionales,
            curriculares y de contacto que recogemos son aquellos que en cada
            momento nos facilitan los Usuarios a través de su CV como, por
            ejemplo, experiencia laboral previa, formación y datos académicos,
            etc.
          </li>
        </ul>
      </div>
      <div className="docs">
        <Paragraphs weight={'medium'}>
          5. ¿Con qué finalidad tratamos tus datos personales, cuál es la base
          jurídica y plazos de conservación?
        </Paragraphs>
        <Paragraphs>
          REPOFARMA, tratará tus datos personales con las siguientes
          finalidades, base jurídica y plazos de conservación, descritos a
          continuación:
        </Paragraphs>
        <ul className="tw-ml-10 tw-mt-5 tw-flex tw-flex-col tw-gap-1 tw-w-2/3 tw-list-disc tw-font-light tw-text-sm">
          <li>
            <span className="tw-font-bold">
              Gestionar el registro como Usuario en nuestra Web.
            </span>
            REPOFARMA tratara tus datos personales necesarios (información
            personal y de contacto) para identificarte como Usuario y darte
            acceso a las funcionalidades y servicios de nuestra Web. Base
            jurídica: El consentimiento del Usuario. La cumplimentación del
            formulario y la aceptación de la política de privacidad comportará
            su autorización expresa. Plazos de conservación: Trataremos tus
            datos durante el tiempo en que mantengas la condición de usuario
            registrado, hasta que decidas darte de baja.
          </li>
          <li>
            <span className="tw-font-bold">
              Gestionar las consultas planteadas, solicitudes o petición de
              información.
            </span>
            REPOFARMA tratara tus datos personales facilitados a través del
            formulario/s de contacto dispuesto en nuestra Web y para hacer un
            seguimiento posterior de las consultas o solicitudes realizadas.
            Base jurídica: El consentimiento del Usuario. La cumplimentación del
            formulario y la aceptación de la política de privacidad comportará
            su autorización expresa. Plazos de conservación: Trataremos tus
            datos hasta la resolución de su solicitud de información o consulta
            planteada.
          </li>
          <li>
            <span className="tw-font-bold">
              Gestionar procesos de selección de candidatos.
            </span>
            Se utilizarán los datos personales proporcionados por los candidatos
            con el fin de proceder a la comunicación de posibles ofertas de
            trabajo, así como de procesos de selección que puedan interesar al
            candidato. Base jurídica: El consentimiento del Usuario. Plazos de
            conservación: Los datos personales proporcionados se conservarán por
            el tiempo necesario que REPOFARMA considere en atención a las
            aptitudes y cualificaciones del candidato y las necesidades de
            contratación de la empresa o hasta que se solicite la supresión por
            el candidato.
          </li>
          <li>
            <span className="tw-font-bold">
              Marketing y envíos comerciales.
            </span>
            REPOFARMA tratara tus datos personales facilitados a través del
            formulario dispuesto en nuestra Web para el envío de nuestro boletín
            de noticias, así como comunicaciones y novedades de nuestros
            servicios. Para enviar información de nuestros servicios se podrá
            utilizar medios de contacto tradicionales (correo postal y teléfono)
            y/o digitales y automatizados (correo electrónico, SMS y otros
            canales digitales). Base jurídica: El consentimiento del Usuario.
            Plazos de conservación: Mientras no solicites tu baja, puesto que
            hasta ese momento entenderemos que sigues interesado/a en recibir
            nuestras comunicaciones.
          </li>
          <li>
            <span className="tw-font-bold">
              Personalizar y mejorar las herramientas y funcionalidades de la
              Web, así como garantizar el funcionamiento técnico de la misma.
            </span>
            REPOFARMA podrá analizar el perfil del usuario con el motivo de
            optimizar, medir y personalizar el uso del Sitio Web. Base jurídica:
            interés legítimo para garantizar que nuestra Web es segura, y para
            mejorar nuestros servicios.
          </li>
        </ul>
      </div>
      <div className="docs">
        <Paragraphs weight={'medium'}>6. ¿A quién comunicamos los datos?</Paragraphs>
        <Paragraphs>REPOFARMA no comunica a terceros la información personal que nos proporcionan Usuarios a través de la Web, salvo cuando la revelación sea exigida por la legislación vigente, por orden judicial, o por una autoridad competente, así como cuando debamos comunicar los datos indispensables por razón lógica de la prestación del servicio.

Los datos recabados únicamente serán objeto de cesión, en su caso, previo consentimiento de la persona interesada o de acuerdo con lo previsto en la citada legislación.</Paragraphs>
      </div>
      <div className="docs">
        <Paragraphs weight={'medium'}>7. ¿Cuáles son los derechos de los Usuarios?</Paragraphs>
        <ul className="tw-ml-10 tw-mt-5 tw-flex tw-flex-col tw-gap-1 tw-w-2/3 tw-list-disc tw-font-light tw-text-sm">
            <li><span className='tw-font-bold'>Derecho de acceso:</span>Derecho a obtener confirmación sobre si REPOFARMA trata datos personales que le conciernan, o no</li>
            <li><span className='tw-font-bold'>Derecho de portabilidad:</span>Siempre que REPOFARMA procese datos personales a través de medios automatizados en base a su consentimiento, el Usuario tiene derecho a obtener una copia de sus datos en un formato estructurado, de uso común y lectura mecánica transferida a su nombre o a un tercero. En ella se incluirán únicamente los datos personales que hubiera facilitado.</li>
            <li><span className='tw-font-bold'>Derecho de rectificación:</span>Derecho a obtener la rectificación de los datos personales inexactos o incompletos que le conciernan.</li>
            <li><span className='tw-font-bold'>Derecho de supresión o derecho al olvido:</span>Derecho a obtener la supresión de cualquier dato personal que REPOFARMA trate en cualquier momento, excepto tiene algún duda o reclamación pendiente de resolverse y si ha contratado con nosotros algún servicio por lo que conservaremos sus datos personales en relación con la transacción por normativa fiscal y contable.</li>
            <li><span className='tw-font-bold'>Derecho de oposición al tratamiento de datos en base al interés legítimo:</span>REPOFARMA no tratara los datos personales a menos que podamos acreditar motivos legítimos imperiosos para el tratamiento que prevalezcan sobre sus intereses, derechos y libertades, o bien para la formulación, el ejercicio o la defensa de reclamaciones.</li>
        </ul>
        <Paragraphs>Estos derechos podrán ejercitarse mediante comunicación a REPOFARMA, S.L. a la dirección de correo electrónico info@repofarma.com o mediante escrito Ref.: LOPD, C/ Miguel Angel nº 4, bajo 10, Madrid – 28010.</Paragraphs>
        <ul className="tw-ml-10 tw-mt-5 tw-flex tw-flex-col tw-gap-1 tw-w-2/3 tw-list-disc tw-font-light tw-text-sm">
            <li><span className='tw-font-bold'>Derecho de oposición a recibir comunicaciones comerciales y de marketing:</span>Derecho a oponerse a recibir comunicaciones comerciales y de marketing. En cualquier momento los Usuarios pueden darse de baja simplemente siguiendo las instrucciones que se indican en el pie del cuerpo de cada una de las comunicaciones electrónicas remitidas por REPOFARMA</li>
            <li><span className='tw-font-bold'>Derecho a presentar una reclamación ante una autoridad de control:</span>Si considera que REPOFARMA trata sus datos de un modo incorrecto, puede contactar con nosotros. También tiene derecho a presentar una queja ante la autoridad de protección de datos competente.</li>
        </ul>
      </div>
      <div className="docs">
        <Paragraphs weight={'medium'}>8. Modificación de la Política de Privacidad.</Paragraphs>
        <Paragraphs>REPOFARMA se reserva el derecho a modificar de manera unilateral la presente Política de privacidad como consecuencia, entre otras circunstancias, de: (i) cambios en la Web; (ii) modificaciones en los tratamientos o finalidades de datos personales; (iii) modificaciones o novedades legislativas o (iv) el establecimiento de nuevos criterios por parte de la Agencia Española de Protección de Datos o cualquier otra autoridad sobre la materia.</Paragraphs>
      </div>
      <Paragraphs weight={'medium'}>Copyright © REPOFARMA, S.L. Todos los derechos reservados.</Paragraphs>
    </div>
  )
}

export default PoliticaPrivacidad
