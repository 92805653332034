import React from 'react'
import PropTypes from 'prop-types'
import { ContentHeader } from './styles'

/**
 * @auhtor pparra
 * @returns {React.Component}
 */
const Header = ({ className, children }) => {
  return (
        <ContentHeader className={`${className} tw-flex tw-items-center`}>
            {children}
        </ContentHeader>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default Header
