import React, { useEffect, useRef, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useUser } from '../../../../infrastructure/hooks/useUser/useUser'
import { useFavorites } from '../../../../infrastructure/hooks/useFavorites/useFavorites'
import SocialCard from '../../../../components/SocialCard/SocialCard'
import Paginator from '../../../../components/Paginator/Paginator'
import EmptyState from '../../../../components/EmptyState/EmptyState'
import { useTranslation } from 'react-i18next'

const RssList = () => {
  const { token } = useUser()
  const { t } = useTranslation()
  const { getFavorites, data, addRemoveFav } = useFavorites()
  const [heightCard, setHeightCard] = useState(0)
  const refBody = useRef(null)
  const updateSizeCard = () => {
    const { width } = refBody?.current?.getBoundingClientRect()
    let separator = 6
    if (width < 1200) separator = 5
    if (width < 1024) separator = 4
    if (width < 768) separator = 3
    if (width < 576) separator = 2
    setHeightCard(width / separator)
  }

  useEffect(() => {
    updateSizeCard()
    getFavorites({ mode: 'rrss' })
  }, [])
  const navigate = useNavigate()

  if (data?.result?.data?.total_items === 0) {
    return <EmptyState message={t('No hemos encontrado recursos favoritos')} />
  }

  return (
        <div className="product-list-favorits tw-w-full">
      <div className="product-listing-screen__content tw-py-8 tw-flex tw-flex-wrap">
        <article ref={refBody} className="rss-filterSearchRow tw-w-full tw-flex tw-flex-row tw-flex-wrap">
          {data?.result?.items?.map((item) => (
            <div
              key={item?.id}
              className="tw-p-2"
              style={{ height: heightCard, width: heightCard }}
            >
              <SocialCard
              onClickDownload={() => {
                const a = document.createElement('a')
                a.href = item.download_url
                a.download = 'FILENAME'
                a.target = '_blank'
                a.click()
              }}
                onClickFav={(active) => addRemoveFav(item?.id, active, 'rrss')}
                onClick={() => token && navigate('/product-list/' + item.id)}
                key={item?.id}
                image={item?.url}
                name={item?.title}
                manufacturer={item?.subtitle}
                isLogged={!!token}
                isFav={item?.is_favorite}
              />
            </div>
          ))}
        </article>
        <div className='pagination tw-flex tw-justify-center tw-w-full tw-py-10'>
          <Paginator {...data?.result?.data} />
      </div>
      </div>
    </div>
  )
}

export default RssList
