/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useRef, useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '../../context/ThemeContext/ThemeContext'
import { useUser } from '../../hooks/useUser/useUser'
import { LINKS_HEADER } from '../../constants/links'
import Footer from '../../../components/Footer/Footer'
import Offcanvas from '../../../components/OffCanvas/Offcanvas'
import Cookies from '../../../components/Cookies/Cookies'
import Login from '../../../components/Login/Login'
import Header from '../../../components/Header/Header'
import Button from '../../../components/Button/Button'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import InfoUser from '../../../components/InfoUser'
import Paragraphs from '../../../components/Paragraphs'
import { ContainerLink } from './styles'
import Logo from '../../../components/Logo'
import useModalLogin from '../../hooks/useModalLogin'
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb'
import UrlParamsContext from '../../context/UrlParamsContext'
import Context from '../../context/UrlParamsContext/UrlParamsContext'
import FilterFavoriteBtn from '../../../components/FilterFavoriteBtn'
import { format } from 'date-fns'

export const SIZE_HEADER = 80
export const SIZE_NAV = 80
/**
 *
 * @param {*} props
 * @returns
 */
const Layout = () => {
  const {
    onRegister,
    user,
    token,
    cookies,
    onLogin,
    onAutologin,
    getInfoUserOnAutologin,
    setCookies,
    onCloseSession
  } = useUser()

  const { product } = useContext(Context)

  const [openCookies, setOpenCookies] = React.useState(!cookies)
  const { t } = useTranslation()
  const location = useLocation()
  const { modal, openModalLogin, closeModalLogin } = useModalLogin()
  const [page, setPage] = useState(null)
  const [bgNav, setBgNav] = useState('')
  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  const [linkArray, setLinkArray] = useState([{
    name: 'Inicio',
    href: '/'
  }])
  // header
  const [scrollY, setScrollY] = useState(0)
  const [layoutScroll, setLayoutScroll] = useState(false)
  const [autologinToken, setAutologinToken] = useState(null)
  //
  const { setQueryModeSign, setHideBanner } = useContext(UrlParamsContext)

  const ref = useRef(null)
  const id = useParams().id
  const navigate = useNavigate()

  const { theme } = useTheme()

  useEffect(() => {
    if (location?.search) {
      let search = location?.search.includes('?') ? location?.search.split('?')[1] : location?.search
      // if includes token and the url not includes reset-password
      if (search.includes('token=') && location.pathname !== '/reset-password') {
        const token = search.split('token=')[1]
        search = search.replace(`token=${token}`, '')
        window.history.replaceState({}, document.title, `/${search}`)
        setAutologinToken(token)
        autologin(token)
        navigate('/')
      } else if (search.includes('token=') && location.pathname === '/reset-password') {
        window.location.href = 'https://hub.farmapolis.es'
      } else {
        setPage(search)
      }
    }
  }, [location?.search])

  const autologin = async (token) => {
    await onAutologin(token)
    await getInfoUserOnAutologin({ authToken: token })
    setAutologinToken(null)
  }

  const onSubmitUser = async (mode, values) => {
    let response = null
    if (mode === 'login') {
      response = await onLogin(values)
    } else {
      values.password_confirmation = values.password
      response = await onRegister(values)
    }

    if (response.success) {
      closeModalLogin()
      return {
        success: true,
        error: null
      }
    } else {
      return response
    }
  }

  const onAcceptCookies = () => {
    setOpenCookies(false)
    setCookies()
  }

  const onRejectCookies = () => {
    setOpenCookies(false)
    sessionStorage.setItem('cookies', false)
  }

  const navigateTo = (link) => {
    ref.current.scrollTo(0, 0)
    if (link) {
      navigate(link)
    }
  }

  function sublinks (sublink, cA) {
    return (
      <ContainerLink
        key={sublink.id}
        onClick={() => {
          navigate(sublink.href /* sublink.search || '' */)
        }}
        className={`menu_mobile__item tw-text-center tw-p-1  tw-my-1 tw-relative ${cA}`}
      >
        <Paragraphs>{sublink.label}</Paragraphs>
      </ContainerLink>
    )
  }

  useEffect(() => {
    setOpenMobileMenu(false)
  }, [location])

  useEffect(() => {
    LINKS_HEADER.map(link => {
      if (link?.href === location.pathname) {
        if (location.pathname === '/') {
          setLinkArray([{ name: 'Inicio', href: '/' }])
          return
        }

        setLinkArray([{ name: 'Inicio', href: '/' }, { name: link.label, href: link.href }])
      } else if (link.items) {
        link?.items?.map(sublink => {
          if (sublink.href === location.pathname) {
            const subLabel = sublink.label.split(' ').slice(2)
            const lastIndex = sublink.label.lastIndexOf(' ')
            const label = sublink.label.substring(0, lastIndex)
            setLinkArray([{ name: 'Inicio', href: '/' }, { name: label, href: sublink.href }, { name: subLabel, href: sublink.href }])
          }
        })
      } else if (location.pathname === `/product-list/${id}`) {
        setLinkArray([{ name: 'Inicio', href: '/' }, { name: 'Catálogo', href: '/product-list' }, { name: product?.items[0]?.public_name, href: `/product-list/${id}` }])
      } else if (location.pathname === '/redes-sociales') {
        setLinkArray([{ name: 'Inicio', href: '/' }, { name: 'Contenido RRSS', href: '/redes-sociales?month=' + format(new Date(), 'yyyy-MM-01') }])
      }
    })
  }, [location.pathname, product?.items[0]?.public_name])

  // get the current search mode param and save in context, for show modal reg-log , if user is logged , do nothing
  useEffect(() => {
    if (user) {
      return
    }
    const urlParams = new URLSearchParams(window.location.search)
    const mode = urlParams.get('mode')
    setQueryModeSign(mode)
  }, [window.location.search])
  const handleScroll = () => {
    setScrollY(window.pageYOffset)
    if (scrollY > 46) {
      setLayoutScroll(true)
      setHideBanner('md:tw-hidden')
    } else {
      setLayoutScroll(false)
      setHideBanner('')
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [window.pageYOffset, location])

  useEffect(() => {
    if (location) {
      if (location.pathname === '/') {
        setBgNav('')
      } else {
        setBgNav('tw-bg-secondary')
      }
    }
  }, [location])

  return (
    <div
      id="layout"
      ref={ref}
      className="layout tw-min-h-screen"
    >
      {openMobileMenu && (
        <div className="menu_mobile tw-fixed tw-min-h-screen tw-bg-white tw-w-full tw-z-[100]">
          <div className="menu_mobile__header tw-flex tw-items-center tw-justify-around tw-h-[60px] tw-bg-secondary">
            <div
              onClick={() => setOpenMobileMenu(false)}
              className="menu_mobile__close tw-px-4"
            >
              <span
                className="icon-ic_cerrar_grande"
                style={{ fontSize: 20 }}
              />
            </div>
            <a href="/" className="menu_mobile__logo  ">
              <Logo image={theme.logo} />
            </a>
          </div>
          <div className="menu_mobile__content  tw-py-4">
            {LINKS_HEADER.map((link) => {
              const active = location.pathname === link.href
              const classsesActive = active
                ? 'before:tw-w-[30px] before:tw-h-[4px] before:tw-absolute before:tw-bg-primary before:tw-bottom-0'
                : ''
              return (
                <ContainerLink
                  key={link.id}
                  onClick={() => {
                    navigate(link.href)
                  }}
                  className={`menu_mobile__item tw-text-center tw-p-3 tw-relative tw-my-3 ${classsesActive}`}
                >
                  <Paragraphs weight="bold">{t(link.label)}</Paragraphs>
                  {link.items &&
                    link.items.map((sublink) => {
                      return sublinks(sublink, classsesActive)
                    })}
                </ContainerLink>
              )
            })}
          </div>
          <div className='tw-w-[50px] tw-mx-auto tw-items-center  tw-justify-center'>
            <FilterFavoriteBtn size={'tw-h-[40px] tw-w-full'} />
            </div>
        </div>
      )}

      <Header
        image={theme.logo}
        bgNav={bgNav}
        user={user}
        openModalLogin={
          () => {
            window.location.href = 'https://hub.farmapolis.es'
          }
        }
        onOpenMobileMenu={() => setOpenMobileMenu(true)}
        onOpenLogin={
          () => {
            window.location.href = 'https://hub.farmapolis.es'
          }
        }
        logged={!!token}
        navigateTo={ navigateTo }
        autologinToken={ autologinToken }
      >
       {!token && !user?.id && <div className="tw-px-4 tw-gap-2 tw-hidden md:tw-flex" data-cy="login-open-modal-btn-div">
       <Button
              data-cy="reg-btn"
              onClick={
                () => {
                  window.location.href = 'https://hub.farmapolis.es'
                }
              }
              mode="register"
              uppercase
              label={t('Deseo registrarme')}
            />
            <Button
              data-cy="login-open-modal-btn"
              iconRight={
                <span
                  className="icon-ic_usuario tw-pl-6"
                  style={{ fontSize: 20 }}
                />
              }
              onClick={
                () => {
                  window.location.href = 'https://hub.farmapolis.es'
                }
              }
              mode="primary"
              uppercase
              label={t('Iniciar sesión')}
            />
          </div>}
      </Header>
      <div className='tw-max-w-max lg:tw-ml-[260px] tw-mt-5'>
        <Breadcrumb search={page} links = { linkArray } />
      </div>

      <Outlet />

      {!cookies && (
        <Offcanvas
          height="auto"
          align="bottom"
          width={'100%'}
          showIconClose={false}
          open={openCookies}
        >
          <Cookies
            onCancel={() => onRejectCookies()}
            onAccept={onAcceptCookies}
          />
        </Offcanvas>
      )}

      <Offcanvas
        width={350}
        open={modal}
        onRequestClose={() => closeModalLogin()}
      >
        {token && user?.id
          ? (
          <InfoUser user={user} onCloseSession={onCloseSession} />
            )
          : (
          <Login onSubmitSuccess={onSubmitUser} />
            )}
      </Offcanvas>
      <Footer image={theme.logo_footer} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node
}

export default Layout
