import styledComponents from 'styled-components'

export const SocialCardContainer = styledComponents.div`
    background-image: url(${props => props.backgroundImage});
    background-size: cover;
    background-position: center;


    & .social-card__content {
        opacity: ${props => props.visibleBadge ? '1' : '0'};
    }

  

    &:hover .social-card__content {
        opacity: 1;
    }
`

export const SocialBackgorund = styledComponents.div`
        background-image: url(${props => props.backgroundPngImage});
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    

      
        
`
