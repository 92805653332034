
import React, { useEffect, useState } from 'react'
import FormacionCard from '../../components/FormacionCard/FormacionCard'
import Page from '../../components/Page/Page'
import { TRAINING } from '../../infrastructure/constants/api'
import useMutation from '../../infrastructure/hooks/useMutation'
import ListingGroups from '../../components/ListingGroups/ListingGroups'
import IconFormaciones from '../../infrastructure/assets/images/home/formaciones.png'
import { useTheme } from '../../infrastructure/context/ThemeContext/ThemeContext'
import Modal from '../../components/Modal/Modal'
import Button from '../../components/Button/Button'
import Paragraphs from '../../components/Paragraphs'
const FormationScreen = () => {
  const [fetchTraining] = useMutation(TRAINING)
  const [trainings, setTrainings] = useState()
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const { theme } = useTheme()

  const getTraining = async (page = 1) => {
    setLoading(true)
    const { data } = await fetchTraining({
      method: 'POST',
      data: { elements: { page, per_page: 12 } }
    })
    setLoading(false)
    if (data) {
      setTrainings(data.result)
    }
  }
  useEffect(() => {
    getTraining()
  }, [])

  const onClickFormacion = (url) => {
    setShowModal(url)
  }

  return (
    <Page.Container id='formation' className="formation-screen"
    urlLogo={IconFormaciones} title='Formaciones' description={theme?.is_lab ? null : 'Mantente al corriente, de manera ordenada y sencilla, de las próximas formaciones y webinars ofrecidos por los laboratorios, apúntate y forma a tu equipo​'}

    >
      {showModal &&
        <Modal onRequestClose={() => setShowModal(false)}>
              <div className='tw-p-5 tw-flex tw-flex-col tw-gap-5 tw-justify-center tw-items-center'>
                <Paragraphs>Entra y regístrate en la web del laboratorio</Paragraphs>
                <Button
                  className="tw-mt-4"
                  size={'medium'}
                  label="Aceptar"
                  onClick={() => {
                    window.open(showModal, '_blank')
                    setShowModal(false)
                  }}
                />
              </div>
        </Modal>
        }
        <Page.Body className="tw-flex tw-flex-col   tw-gap-y-6">
        <ListingGroups
          className="tw-gap-8 tw-flex tw-flex-col"
          loading={loading}
          getData={getTraining}
          paginatorInfo={trainings?.data}
        >
          <div className="tw-flex tw-flex-col tw-w-full tw-gap-5">
            {trainings?.items?.map((item) => (
              <a key={item.id} onClick={() => onClickFormacion(item.url)}>
                <FormacionCard {...item} />
              </a>
            ))}
          </div>
        </ListingGroups>
      </Page.Body>
    </Page.Container>
  )
}

export default FormationScreen
