import React from 'react'
import FormacionCard from '../../../../components/FormacionCard/FormacionCard'
import Paragraphs from '../../../../components/Paragraphs'
import SliderProducts from '../../../../components/SliderProducts'
import propTypes from 'prop-types'

const SliderTrainings = ({ info }) => {
  return (
        <div id='formaciones' className='tw-mb-8'>
            <div className='tw-mx-auto md:tw-px-5 tw-px-1 tw-py-8 tw-mt-8 tw-gap-5 tw-flex tw-flex-col tw-w-full tw-bg-secondary'>
              <Paragraphs weight={'bold'} size= '2xl' className={'md:tw-pl-[110px] tw-text-center md:tw-text-left'}>Formaciones</Paragraphs>
              <div className='tw-w-full'>
                <SliderProducts
                className='tw-flex tw-mx-auto tw-justify-center tw-items-center md:tw-w-[95%]'
                id='formations'
                breakpoints={{
                  640: {
                    slidesPerView: 1
                  },
                  768: {
                    slidesPerView: 1
                  },
                  900: {
                    slidesPerView: 1
                  },
                  1200: {
                    slidesPerView: 1
                  }
                }}
                navigation={true}
                hideNavigation={false}
               >

                { info?.trainings.map((formation) => (
                  <a key={formation.id} href={formation.url} target="_blank" rel="noreferrer" >
                  <FormacionCard {...formation} />
                  </a>
                ))}

               </SliderProducts>
            </div>
            </div>

          </div>
  )
}

export default SliderTrainings

SliderTrainings.propTypes = {
  info: propTypes.object
}
