import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs'
/**
 * @author ehernandez
 */
const Avatar = ({ onClick, name, email }) => {
  const getInitialsName = () => {
    if (name) {
      const nameSplit = name.split(' ')
      const initials = nameSplit.map(i => i.charAt(0)).join('')
      return initials
    }
    return null
  }
  return (
    <div data-cy='avatar' onClick={onClick} className="avatar tw-flex tw-items-center lg:tw-relative  tw-absolute tw-right-0  tw-cursor-pointer">
      <div className="name-emial tw-text-right tw-hidden md:tw-block">
        <Paragraphs weight='bold'>{name}</Paragraphs>
        <Paragraphs size='xxs'>{email}</Paragraphs>
      </div>
      <div className="avatar__ball tw-px-4">
        <div className="avatar__ball_inner tw-h-[40px] tw-w-[40px] tw-bg-tertiary tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-white tw-border-2 tw-border-white">
          {getInitialsName()}
        </div>
      </div>
    </div>
  )
}

Avatar.propTypes = {
  onClick: PropTypes.func,
  name: PropTypes.string,
  email: PropTypes.string
}

export default Avatar
