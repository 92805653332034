import Modal from '../../../../components/Modal/Modal'
import React from 'react'
import PropTypes from 'prop-types'
import Button from '../../../../components/Button/Button'
import Paragraphs from '../../../../components/Paragraphs'

/**
 * @author: cperez
 * @returns React.Component
 * @description: Componente que muestra el popup de novedades antes de redireccionar a la pagina de laboratorio
 */
const PopUpNews = ({ url, onRequestClose, data }) => {
  return (
    <Modal show={url} onRequestClose={onRequestClose}>
      <div className="tw-flex tw-justify-evenly tw-gap-10 tw-p-10">
        <div className='tw-w-2/4'>
          <img src={data?.popup_url} alt="popup" className='tw-w-[600px]' />
        </div>
        <div className=" tw-flex tw-flex-col tw-w-2/4   tw-gap-2">
          <div className='tw-flex tw-flex-col'>
          <Paragraphs size={'xl'} transform='uppercase' weight={'bold'} className={'tw-text-emerald-800'}>
            {data?.popup_title}
          </Paragraphs>
          <Paragraphs transform='uppercase'>{data?.popup_subtitle}</Paragraphs>
          </div>
          <Paragraphs size={'xs'}>{data?.popup_description}</Paragraphs>
          {(data?.popup_health_need || data.popup_product_line) && <div className="tw-w-[100px] tw-h-[3px] tw-bg-emerald-800 tw-mb-5" />}
          {data?.popup_health_need && <Paragraphs className={'tw-text-emerald-800'}>
            Necesidad de salud:
            <span className="tw-text-black"> {data?.popup_health_need}</span>
          </Paragraphs>}
          {
            data?.popup_product_line && <Paragraphs className={'tw-text-emerald-800 tw-mb-5'}>
            Línea de productos:
            <span className="tw-text-black"> {data?.popup_product_line}</span>
          </Paragraphs>
          }
          <Button
            size={'medium'}
            label="Link laboratorio"
            onClick={() => {
              window.open(url, '_blank')
              onRequestClose()
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

export default PopUpNews

PopUpNews.propTypes = {
  url: PropTypes.string,
  onRequestClose: PropTypes.func,
  data: PropTypes.object
}
