import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Paragraphs from '../../../../components/Paragraphs'
import ProductCard from '../../../../components/ProductCard/ProductCard'
import SliderProducts from '../../../../components/SliderProducts'
import useProducts from '../../../../infrastructure/hooks/useProducts'
import propTypes from 'prop-types'
import { useUser } from '../../../../infrastructure/hooks/useUser/useUser'

const SliderCatalog = ({ info }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { downloadAll, addRemoveFav } = useProducts()
  const { token } = useUser()
  const { id } = useParams()
  return (
        <div className="product-listing-screen__content tw-bg-secondary tw-gap-10 tw-flex tw-flex-col tw-w-full tw-mt-8 tw-mx-auto tw-py-8">
            <div className='tw-flex tw-items-center md:tw-px-32 tw-px-5 tw-mx-auto tw-justify-between tw-w-full'>
                <div className="">
                  <Paragraphs weight="bold" size={'2xl'}>
                    {t('Catálogo')}
                  </Paragraphs>
                </div>
                <div onClick={() => navigate('/product-list?laboratories=' + id)}>
                  <Paragraphs weight={'medium'} className= 'tw-underline tw-cursor-pointer'>Ver todo</Paragraphs>
                </div>
            </div>
              <SliderProducts
              className={'tw-flex tw-items-center tw-w-full  tw-py-2 md:tw-w-[95%] tw-mx-auto tw-justify-center'}
              hideNavigation={false}
              navigation={true}
                          breakpoints={{
                            640: {
                              slidesPerView: 1
                            },
                            768: {
                              slidesPerView: 2
                            },
                            1300: {
                              slidesPerView: 2
                            },
                            1620: {
                              slidesPerView: 4
                            }
                          }}
                          id={'slider-resources'}

              >
              {info?.featured_products?.map((i) => (

                  <ProductCard
                  key={i.id}
                    className= {'tw-h-[430px] tw-bg-white tw-max-w-[90%] tw-mx-auto '}
                    onClickDownload={() => downloadAll(i.cn_codes)}
                    onClickFav={(active) => addRemoveFav(i.id, active)}
                    onClick={() => token && navigate('/product-list/' + i.id)}
                    isLogged={!!token}
                    {...i}
                    image={i?.images?.[0]}

                  />

              ))}
              </SliderProducts >

          </div>
  )
}

export default SliderCatalog

SliderCatalog.propTypes = {
  info: propTypes.object
}
