import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs'
// import CheckBox from '../CheckBox'

const EcommerceCardItem = ({ name, onClickDownload, id, onSelected, extension, size }) => {
  return (

    <div onClick={onClickDownload} className='ecommerce-card-item tw-flex  tw-top-0 hover:tw-bg-gray-100 tw-cursor-pointer  tw-rounded-[6px] tw-justify-between tw-items-center'>

        <div>
        <Paragraphs weight={'medium'} >{name}</Paragraphs>
        <Paragraphs className={'tw-text-slate-500'} size={'xs'} >{`${extension} (${size})`}</Paragraphs>
        </div>
        <div data-cy={`btn-download-tarifa-${id}`} onClick={onClickDownload} className='ecommerce-card-item__icon tw-w-[40px] tw-flex tw-items-center tw-justify-center tw-cursor-pointer'>
            <span className='icon-ic_download tw-text-primary tw-text-xl' />
        </div>
    </div>
  )
}

EcommerceCardItem.propTypes = {
  name: PropTypes.string,
  onClickDownload: PropTypes.func,
  id: PropTypes.number,
  onSelected: PropTypes.func,
  extension: PropTypes.string,
  size: PropTypes.string
}

export default EcommerceCardItem
