import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import { Navigation, Pagination } from 'swiper'

const SliderImages = ({ className, images, onClick, positionPagination, hideNavigation, style }) => {
  const paginationMode = positionPagination === 'inside' ? 'tw-absolute tw-bottom-0 tw-w-full' : 'tw-relative'

  const currentPage = window.location.pathname
  const [showDotsSlider, setShowDotsSlider] = useState(true)
  useEffect(() => {
    hideNavigation = false
    if (currentPage.includes('/product-list')) {
      setShowDotsSlider(false)
    }
  }, [])

  return (
    <div className={`${className} slider-images tw-relative tw-flex tw-flex-col`} style={style}>
    <Swiper
      modules={[Navigation, Pagination]}
      spaceBetween={50}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }}
      slidesPerView={1}
      pagination={{ clickable: true, el: '.swiper-pagination', bulletClass: 'tw-rounded-none tw-h-2 tw-w-2 tw-bg-gray-300 tw-block tw-px-1', bulletActiveClass: 'tw-bg-primary' }}
      scrollbar={{ draggable: true }}
      className="tw-w-full tw-flex-1"
    >

      {images?.map((i, idx) => (
         <SwiperSlide
          key={i.id}
          className="tw-h-full tw-flex tw-items-center tw-justify-center"
          onClick={e => onClick(e, i, idx)}
        >
          <img
            key={i.id}
            src={i.value}
            className={`${i.value.includes('cdn') && 'tw-object-contain tw-h-[80%] tw-my-auto'} `}
            loading="lazy"
            alt={i.public_name}
          />
        </SwiperSlide>
      ))}
    </Swiper>
    {showDotsSlider && <div className={`navigation-bar tw-h-[60px] tw-flex tw-justify-between tw-items-center tw-px-5 ${paginationMode}`}>
{/* {!hideNavigation && <button
    className='swiper-button-prev tw-left-0 tw-relative  hover:-tw-translate-x-2 hover:tw-ease-in tw-transition tw-top-0 tw-text-black after:tw-hidden tw-relative flex items-center justify-center  text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline '
    type='button'
    data-bs-target={'#' + 'product-list'}
    data-bs-slide='prev'
  >
    <span
      style={{ fontSize: 30 }}
      className={
        'icon-ic_arrow_hero inline-block bg-no-repeat tw-text-gray-400 hover:tw-text-gray-500'
      }
      aria-hidden='true'
    ></span>
     <span className='icon-ic_slider tw-rotate-180' style={{ fontSize: 25 }}/>
    </button>} */}
  <div className={'swiper-pagination tw-flex tw-gap-1 tw-relative tw-text-sm tw-text-center tw-text-gray-400 tw-justify-center tw-items-center tw-flex-1 tw-bottom-0'}/>
{/* { !hideNavigation && <button
    className='swiper-button-next tw-right-0 hover:tw-translate-x-2 hover:tw-ease-in tw-transition tw-top-0 tw-h-auto tw-text-black after:tw-hidden tw-relative flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline '
    type='button'
    data-bs-target={'#' + 'product-list'}
    data-bs-slide='next'
  >
    <span
      style={{ fontSize: 30 }}
      className={
        'icon-ic_arrow_hero_right inline-block bg-no-repeat tw-text-gray-400 hover:tw-text-gray-500'
      }
      aria-hidden='true'
    ></span>
    <span className='icon-ic_slider' style={{ fontSize: 25 }}/>
    </button>} */}
  </div>}
  </div>
  )
}

SliderImages.propTypes = {
  className: PropTypes.string,
  images: PropTypes.array,
  onClick: PropTypes.func,
  positionPagination: PropTypes.string,
  hideNavigation: PropTypes.bool,
  style: PropTypes.object
}

SliderImages.defaultProps = {
  images: []
}
export default SliderImages
