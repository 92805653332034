import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
// animation text to show
    from {
        opacity: 0;
        transform: translateY(-10px);
        height: 0;

       
    }
    to {
        opacity: 1;
        transform: translateY(0px);
        height: 100%;
    }
`
export const TextContainer = styled.div`
    overflow: hidden;
    animation: ${fadeIn} 0.5s ease-in-out;
   
`
