import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Input from './components/Input'
import WrapperInput from '../WrapperInput/WrapperInput'
import { Textarea } from './styles'

/**
 * TextInput
 * This component required of WrapperInput.
 * @author @jstarsky
 * @param {*} props
 * @return {JSX.Element}
 */
const TextInput = React.forwardRef(
  (
    {
      id,
      autoFocus,
      disabled,
      flex,
      label,
      labelStyle,
      name,
      onBlur,
      onChange,
      placeholder,
      register,
      required,
      fontSize,
      error,
      description,
      test,
      type,
      alignment,
      hidden,
      button,
      value,
      onFocus
    },
    ref
  ) => {
    const [securityEntry, setSecurityEntry] = useState(false)
    const wrapperref = useRef()
    return (
      <WrapperInput
        ref={wrapperref}
        type={type}
        disabled={disabled}
        flex={flex}
        id={id}
        label={label}
        labelStyle={labelStyle}
        required={required}
        error={error}
        description={description}
        hidden={hidden}
        button={button}
        securityEntry={securityEntry}
        setSecurityEntry={setSecurityEntry}
      >
        {type === 'textarea'
          ? (
          <Textarea
            rows={3}
            ref={ref}
            alignment={alignment}
            autoFocus={autoFocus}
            fontSize={fontSize}
            test={test || `${name || id}_input`}
            disabled={disabled}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            type={type}
            value={value}
            register={register}
            className="tw-outline-none tw-bg-transparent"
          />
            )
          : (
          <Input
            onFocus={onFocus}
            ref={ref}
            alignment={alignment}
            autoFocus={autoFocus}
            fontSize={fontSize}
            test={test || `${name || id}_input`}
            disabled={disabled}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            type={securityEntry ? 'text' : type}
            register={register}
          />
            )}
      </WrapperInput>
    )
  }
)
TextInput.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  flex: PropTypes.oneOf(['between', 'row']),
  label: PropTypes.string,
  labelStyle: PropTypes.shape({
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'tertiary',
      'white',
      'black'
    ]),
    italic: PropTypes.bool,
    transform: PropTypes.oneOf(['uppercase', 'lowercase', 'capitalize']),
    weight: PropTypes.oneOf(['bold', 'normal'])
  }),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  register: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  required: PropTypes.bool,
  fontSize: PropTypes.oneOf(['xs', 'xl']),
  error: PropTypes.shape({
    hidden: PropTypes.bool,
    message: PropTypes.string,
    type: PropTypes.oneOf([
      'alert',
      'warning',
      'required',
      'validate',
      'pattern'
    ])
  }),
  description: PropTypes.shape({
    hidden: PropTypes.bool,
    message: PropTypes.string,
    type: PropTypes.oneOf([
      'info',
      'alert',
      'warning',
      'required',
      'validate',
      'pattern'
    ]),
    alignment: PropTypes.oneOf(['center', 'end', 'start'])
  }),
  test: PropTypes.string,
  type: PropTypes.oneOf(['date', 'email', 'number', 'password', 'tel', 'text']),
  alignment: PropTypes.oneOf(['center', 'end', 'start']),
  value: PropTypes.string,
  hidden: PropTypes.bool,
  button: PropTypes.shape({
    icon: PropTypes.node,
    label: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    reverse: PropTypes.bool,
    className: PropTypes.string
  }),
  onFocus: PropTypes.func
}
TextInput.defaultProps = {
  id: 'textInput',
  type: 'text',
  fontSize: 'xs'
}
TextInput.displayName = 'TextInput'
export default TextInput
