import { combineReducers } from 'redux'
import userReducer from './user'
import cookiesReducer from './cookies'
import { layoutReducer, orderByReducer } from './layoutReducers'

export default combineReducers({
  userReducer,
  cookiesReducer,
  layoutReducer,
  orderByReducer
})
