import React from 'react'
import SliderProducts from '../SliderProducts'
import { LOGOS_PARTNERS } from '../../infrastructure/constants/logosPartners'
import PropTypes from 'prop-types'
const SliderPartner = ({ logos, id }) => {
  return (
        <SliderProducts id={id} breakpoints={{
          640: {
            slidesPerView: 1
          },
          768: {
            slidesPerView: 2
          },
          900: {
            slidesPerView: 3
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 10
          }

        }}
      navigation={true}
      hideNavigation={false}
      className="block-news__content tw-flex  tw-items-center tw-w-[1000px] tw-max-w-[100%]  tw-mx-auto">
       {logos?.map((logo, index) => (
         <img width={150} className='tw-mx-auto ' key={index} src={logo.logo}/>
       ))}
     </SliderProducts>
  )
}

SliderPartner.propTypes = {
  logos: PropTypes.array,
  id: PropTypes.string
}

SliderPartner.defaultProps = {
  logos: LOGOS_PARTNERS
}

export default SliderPartner
