import React, { useEffect, useRef, useState, useMemo } from 'react'
import Page from '../../components/Page/Page'
import SocialCard from '../../components/SocialCard/SocialCard'
import { useMutation } from '../../infrastructure/hooks/useMutation/useMutation'
import PropTypes from 'prop-types'
import { useFavorites } from '../../infrastructure/hooks/useFavorites/useFavorites'
import { useUser } from '../../infrastructure/hooks/useUser/useUser'
// import useProducts from '../../infrastructure/hooks/useProducts'
// import getMonthDifference from '../../infrastructure/hooks/useDate/useDate'
import { useSearchParams } from 'react-router-dom'
import EmptyState from '../../components/EmptyState'
import ListingGroups from '../../components/ListingGroups/ListingGroups'
import LogoRs from '../../infrastructure/assets/images/logos/logo-rs.png'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
const RSSScreen = ({ thematic }) => {
  const [fetchProductResources] = useMutation('rrss-contents')
  const [resources, setResources] = useState([])
  const [filters] = useState()
  const { token, user } = useUser()
  const { addRemoveFav } = useFavorites()
  // const { getLaboratories } = useProducts()
  const [searchParams, setSearchParams] = useSearchParams()
  const [queries, setQueries] = React.useState(null)
  const laboratories = searchParams.get('laboratories')
  // const page = searchParams.get('laboratories')
  const month = searchParams.get('month')
  const [openFilters, setOpenFilter] = React.useState(false)
  const refBody = useRef(null)
  const [heightCard, setHeightCard] = useState(0)
  const [loading, setLoading] = useState(false)
  const getResources = async (page) => {
    setLoading(true)
    const { width } = refBody.current.getBoundingClientRect()
    let separator = 4
    if (width < 1200) separator = 4
    if (width < 1024) separator = 4
    if (width < 768) separator = 3
    if (width < 576) separator = 2
    const values = {
      attributes: {
        view: 'front',
        type_label: thematic ? 'rrss_tem' : 'rrss',
        not_type_label: thematic ? null : 'rrss_tem'
      },
      elements: {
        order_param: thematic ? 'products.rrss_tem_fecha' : null,
        order_direction: thematic ? 'desc' : null,
        page,
        per_page: separator * 3
      }
    }

    if (queries?.laboratories) {
      values.attributes.lab_ids = queries.laboratories
    }
    if (queries?.month) {
      values.attributes.month = queries.month
    }
    const { success, data } = await fetchProductResources({
      method: 'POST',
      data: values
    })
    const queryValues = {
      laboratories: laboratories || '',
      month: month || '',
      page: page || ''
    }
    Object.keys(queryValues).forEach((i) => {
      if (!queryValues[i] || queryValues[i] === 'null') {
        delete queryValues[i]
      }
    })
    setSearchParams(queryValues)

    setLoading(false)
    if (success) {
      setResources(data.result)
      return {
        ...data.result
      }
    } else {
      return {
        success: false,
        error: 'Error'
      }
    }
  }

  const updateSizeCard = () => {
    const { width } = refBody?.current?.getBoundingClientRect()

    if (width) {
      let separator = 4
      if (width < 1200) separator = 4
      if (width < 1024) separator = 4
      if (width < 768) separator = 3
      if (width < 576) separator = 2
      setHeightCard((width - 40) / separator)
    }
  }

  const handleSetFilter = (key, value, typeKey) => {
    let newQueries = queries
    let values = {}

    if (typeKey === 'string') {
      newQueries = queries?.[key] || null
    } else {
      newQueries = queries?.[key] || []
      if (newQueries.includes(value)) {
        newQueries.splice(newQueries.indexOf(value), 1)
      } else {
        newQueries.push(value)
      }
    }
    values = {
      ...queries,
      page: 1 || '',
      laboratories: laboratories || '',
      [key]: typeKey === 'string' ? value : newQueries.join(',')
    }

    Object.keys(values).forEach((i) => {
      if (!values[i] || values[i] === 'null') {
        delete values[i]
      }
    })
    setSearchParams(values)
  }

  useEffect(() => {
    if (user?.id) {
      updateSizeCard()
      // getFilters({})
      window.addEventListener('resize', () => {
        updateSizeCard()
      })
    }
  }, [thematic, user])

  useEffect(() => {
    if (user?.id) {
      setOpenFilter(false)
      const currentPage = 1
      const labs = laboratories
        ? laboratories?.split(',').map((i) => Number(i))
        : []
      setQueries({ laboratories: labs, month, page: currentPage })
    }
  }, [laboratories, user, month, thematic])

  useEffect(() => {
    if (user?.id && queries) {
      setLoading(true)
      getResources().then(() => setLoading(false))
    }
  }, [queries])

  const onResetFilters = () => {
    setSearchParams({})
  }
  const m = useMemo(() => {
    // get name month current width date-fns
    // get month name with param month
    const currentDate = new Date()
    const currentMonth = Number(month?.split('-')[1] || currentDate?.getMonth() + 1)
    const currentYear = currentDate?.getFullYear() || new Date().getFullYear()
    const currentMonthName = format(new Date(currentYear, currentMonth - 1), 'MMMM', { locale: es })
    return currentMonthName
  }, [])

  return (
    <Page.Container id="rss-screen" name="rrss-screen"
    urlLogo={LogoRs} title={'Contenido RRSS'} description= {`Nuestra propuesta de contenidos para el mes de ${m}`}
    >
      <Page.Body reference={refBody} >
        <ListingGroups
         filters={{
           data: filters,
           visible: openFilters,
           handleSetFilter,
           onResetFilters,
           onClose: () => setOpenFilter(false),
           //  getMoreData: getFilters,
           selecteds: queries
         }}
        loading={loading} getData={getResources} paginatorInfo={resources?.data}>
        <div className="rss-cards tw-flex tw-flex-row tw-flex-wrap md:tw-flex-nowrap lg:tw-flex-nowrap">

          <article
            className="rss-filterSearchRow tw-w-full tw-flex tw-flex-row tw-flex-wrap"
          >
            {!loading && (
              <>
                {resources?.data?.total_items !== 0
                  ? (
                      resources?.items?.map((item) => (
                    <div
                      key={item.id}
                      className="tw-p-2"
                      style={{ height: heightCard, width: heightCard }}
                    >
                      <SocialCard
                        onClickDownload={() => {
                          const a = document.createElement('a')
                          a.href = item.download_url
                          a.download = 'FILENAME'
                          a.target = '_blank'
                          a.click()
                        }}
                        onClickFav={(active) => {
                          addRemoveFav(item.id, active, 'rrss')
                        }

                        }
                        isFav={item?.is_favorite}
                        key={item.id}
                        image={item.url}
                        name={item.title}
                        manufacturer={item.subtitle}
                        isLogged={!!token}
                      />
                    </div>
                      ))
                    )
                  : (
                  <EmptyState message={'No se encontraron recursos'} />
                    )}
              </>
            )}
          </article>
        </div>
        </ListingGroups>

      </Page.Body>
    </Page.Container>
  )
}

RSSScreen.propTypes = {
  thematic: PropTypes.bool
}
export default RSSScreen
