import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Badge from '../Badge/Badge'

const BtnFavorite = ({ isFav, onClick }) => {
  const [active, setActive] = useState(isFav)
  const onClickFav = (e) => {
    setActive(!active)
    if (onClick) {
      onClick(!active)
    }
    e.stopPropagation()
  }
  const icon = useMemo(() => {
    return active ? 'icon-ic_favorito_selected' : 'icon-ic_favorito_empty'
  }, [active])
  return (
    <Badge id={'fav'} onClick={onClickFav} className={`btn-favorite ${active && 'tw-text-alert'}`}>
      <span className={icon} />
    </Badge>
  )
}

BtnFavorite.propTypes = {
  isFav: PropTypes.bool,
  onClick: PropTypes.func
}

export default BtnFavorite
