import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs/Paragraphs'
import { useTranslation } from 'react-i18next'
import Content from '../Content'
import { useForm } from 'react-hook-form'
import FilterOrderBy from '../FilterOrderBy'
import FilterFavoriteBtn from '../FilterFavoriteBtn'

/**
 * @author ehernandez
 */
const SearchInput = ({ laboratorieScreen, onSubmit, orderOptions, onChangeOrder, value, onAllProducts, placeholder, titleAction }) => {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      search: value
    }
  })

  useEffect(() => {
    reset({ search: value })
  }, [value])
  const { t } = useTranslation()

  return (
    <div className="search-input tw-w-full tw-flex tw-items-center tw-justify-between tw-flex-wrap ">
      <Content className={`search-input__input-container tw-flex ${laboratorieScreen ? 'tw-w-full' : 'tw-w-[700px]'} tw-items-center tw-h-[60px] tw-bg-gray-100 `}>
        <div data-cy='btn-search' className="search-input__input-container__icon tw-px-4  tw-flex tw-items-center tw-justify-center">
          <span className="icon-ic_buscar_grande" style={{ fontSize: 24 }} />
        </div>
        <Paragraphs className="tw-flex-1 " size="base">
          <form onSubmit={handleSubmit(onSubmit)} className="tw-h-full tw-items-center tw-flex">
            <input
              {...register('search')}
              className="search-input__input tw-flex-1 tw-w-full tw-bg-slate-100 placeholder:tw-text-gray-500 tw-h-full tw-outline-none placeholder:tw-text-sm"
              type="text"
              placeholder={placeholder || t(
                'Busca por nombre de producto, EAN, CN o laboratorio …'
              )}
              data-cy='search'
            />
            <div className="tw-flex tw-pt-4 tw-place-content-end tw-flex-1 lg:tw-hidden">
        </div>
          </form>
        </Paragraphs>
        {
          onAllProducts && <div onClick={onAllProducts} className="search-input__order tw-flex tw-items-center tw-h-full tw-px-8 tw-cursor-pointer">
          <Paragraphs weight={'bold'} size="sm">
            {titleAction || t('Ver todos los productos')}
          </Paragraphs>
        </div>
        }

      </Content>
      <div className=' tw-hidden lg:tw-flex tw-max-w-[90%]'>
        {orderOptions && (
          <Content className=" tw-w-[400px] tw-flex tw-items-center tw-h-[60px]">
            <FilterOrderBy options={orderOptions} onChange={onChangeOrder} />
          </Content>
        )}
        {!laboratorieScreen && <div className='tw-ml-5 '>
            <FilterFavoriteBtn />
        </div>}
        </div>
    </div>
  )
}

SearchInput.propTypes = {
  onSubmit: PropTypes.func,
  onChangeOrder: PropTypes.func,
  orderOptions: PropTypes.array,
  value: PropTypes.string,
  onAllProducts: PropTypes.func,
  placeholder: PropTypes.string,
  titleAction: PropTypes.string,
  laboratorieScreen: PropTypes.bool
}

export default SearchInput
