import React from 'react'
import TitleLogo from '../../../TitleLogo'
import PropTypes from 'prop-types'
import { Container } from './style'
import Paragraphs from '../../../Paragraphs'
import { useTheme } from '../../../../infrastructure/context/ThemeContext/ThemeContext'

const HeaderPage = ({
  url,
  bgLine,
  subTitle,
  title,
  description,
  novedades,
  opacity,
  textEcommerce
}) => {
  const { theme } = useTheme()
  return (
    <>
      <div className={`tw-relatve ${opacity && 'tw-opacity-20'}`}>
        <Container className="tw-w-[100%] tw-h-[200px] md:tw-h-[350px] tw-left-0 tw-absolute -tw-top-[100px] tw-z-[-1]"></Container>
        <div className="tw-mx-auto">
          <TitleLogo
            novedades
            bgLine={bgLine}
            subTitle={subTitle}
            url={url}
            title={title}
            textEcommerce={textEcommerce}
            description={description}
          />
        </div>
      </div>
      {novedades && !theme.is_lab && (
        <><div className="tw-h-[3px] tw-mx-auto tw-mt-2 tw-w-[100px] tw-bg-gradient-to-r tw-from-primary tw-to-tertiary"></div><div className="tw-text-center">
          <Paragraphs
            className="tw-mt-10 text-center tw-mx-auto tw-mb-10"
            weight="medium"
            size="4xl"
          >
            Novedades destacadas
          </Paragraphs>
        </div></>
      )}
    </>
  )
}

export default HeaderPage

HeaderPage.propTypes = {
  url: PropTypes.string,
  opacity: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  bgLine: PropTypes.bool,
  subTitle: PropTypes.string,
  textBanner: PropTypes.string,
  image: PropTypes.string,
  novedades: PropTypes.bool,
  textEcommerce: PropTypes.string
}
