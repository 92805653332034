export const optionsOrder = [
  // {
  //   // id: 1,
  //   // default: true,
  //   // name: 'Relevantes',
  //   // orderBy: {
  //   //   param: 'updated_at',
  //   //   direction: 'desc'
  //   // }
  // },
  {
    id: 1,
    name: 'Nombre (Ascendente)',
    orderBy: {
      param: 'public_name',
      direction: 'asc'
    }
  },
  {
    id: 2,
    name: 'Nombre (Descendente)',
    orderBy: {
      param: 'public_name',
      direction: 'desc'
    }
  }
]
