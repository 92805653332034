/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import Page from '../../components/Page/Page'
import useMutation from '../../infrastructure/hooks/useMutation'
import WrapperTarifasCard from './components/WrapperTarifasCard'
import PropTypes from 'prop-types'
import ListingGroups from '../../components/ListingGroups/ListingGroups'
import Logo from '../../infrastructure/assets/images/logos/ecommerce.png'
import Button from '../../components/Button/Button'

const TarifasScreen = ({ isEcommerce }) => {
  const [fetchLabs] = useMutation('labs')
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  // const [selectedEcommerce, setSelectedEcommerce] = useState([])
  const [checked, setChecked] = useState([])

  const getLabs = async (page = 1, values = {}) => {
    const variables = {
      elements: { page, per_page: 48 },
      attributes: {}
    }
    if (isEcommerce) {
      variables.attributes.type = 'ecommerce'
    } else {
      variables.attributes.type = 'documents'
      variables.attributes.has_tariff_documents = true
    }
    setLoading(true)
    const { success, data } = await fetchLabs({
      method: 'POST',
      data: variables
    })
    setLoading(false)
    if (success) {
      setData(data.result)
    }
  }

  useEffect(() => {
    setLoading(true)
    getLabs().then(() => setLoading(false))
  }, [isEcommerce])

  const onClickDownloadEcommerce = (e) => {
    e.preventDefault()
    checked.forEach((lab) => {
      const a = document.createElement('a')
      a.href = `https://app.farmapolis.es/labs/${lab}/lab-download-csv`
      a.download = 'FILENAME'
      a.target = '_blank'
      a.click()
    })
  }

  // Add/Remove checked item from list
  const handleCheck = (event) => {
    let updatedList = [...checked]
    if (event.target.checked) {
      updatedList = [...checked, event.target.value]
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1)
    }
    setChecked(updatedList)
  }

  return (
    <Page.Container
      bgLine
      subTitle={''}
      urlLogo={Logo}
      title={'Tarifas'}
      description={
        'Dispón  de tarifas PVL actualizadas para tu gestión diaria de producto​'
      }
      id="tarifas-screen"
      name="tarifas-screen"
    >
      <Page.Body

      >
        <ListingGroups
          loading={loading}
          paginatorInfo={data?.data}
          getData={getLabs}
        >
          <div className="tarifas-container tw-grid  md:tw-grid-cols-2 xl:tw-grid-cols-3 2xl:tw-grid-cols-4 tw-max-w-full tw-gap-5 tw-mx-auto ">
            {data?.items?.map((i) => (
              <WrapperTarifasCard
                handleCheck={handleCheck}
                checked={checked}
                isEcommerce={isEcommerce}
                data={i}
                key={i.id}
                {...i}
              />
            ))}
          </div>
        </ListingGroups>
        <div className="tw-flex tw-justify-center tw-p-8 tw-max-w-[400px] tw-mx-auto">
          {isEcommerce && (
            <Button
              label="descargar"
              uppercase
              mode="gradient"
              size="medium"
              onClick={onClickDownloadEcommerce}
            />
          )}
        </div>
      </Page.Body>
    </Page.Container>
  )
}

TarifasScreen.propTypes = {
  isEcommerce: PropTypes.bool
}

export default TarifasScreen
