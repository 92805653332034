import React, { useEffect, useState } from 'react'
import { SocialCardContainer, SocialBackgorund } from './styles'
import BackgroundPng from './background_png.png'
import Paragraphs from '../Paragraphs/Paragraphs'
import PropTypes from 'prop-types'
import Badge from '../Badge/Badge'
import BtnFavorite from '../BtnFavorite'
import CoverVideo from './image-video.jpg'
import CoverFile from './file.jpg'
const SocialCard = ({ image, name, manufacturer, isLogged, onClickFav, isFav, onClickDownload, visibleBadge, type, data, hideBadge }) => {
  const getCover = () => {
    if (type === 'video') return CoverVideo
    if (type === 'custom_field') return CoverFile
    return image
  }
  const [changeBack, setChangeBack] = useState(false)

  useEffect(() => {
    if (image) {
      const n = image.split('.').pop()
      if (n === 'png') {
        setChangeBack(true)
      }
    }
  }, [image])

  return (

    <SocialBackgorund backgroundPngImage = { changeBack ? BackgroundPng : null} className='tw-border-border tw-rounded-[6px] tw-cursor-pointer hover:-tw-translate-y-2 hover:tw-shadow-xl tw-transition tw-duration-150 tw-ease-out hover:ease-in' >
        <SocialCardContainer
          backgroundImage={getCover()}
          visibleBadge={visibleBadge}
          className="social-card  tw-w-full tw-h-full  tw-border tw-border-border tw-rounded-[6px]"
        >

           <div className={`social-card__content tw-flex tw-transition tw-items-end tw-opacity-0  tw-w-full tw-h-full ${!visibleBadge && 'tw-bg-opacity-40 tw-bg-black'} tw-relative`}>
          {isLogged && (
        <div className="tw-absolute product-card__badges hover:tw-text-white tw-text-black  tw-z-10 tw-w-[40px] tw-h-[80px] tw-flex tw-flex-col tw-cursor-pointer tw-right-4 tw-top-4 tw-bg-white hover:tw-bg-primary tw-rounded-[5px]">
        <BtnFavorite onClick={onClickFav} isFav={isFav} />
          <Badge onClick={onClickDownload}>
            <span className="icon-ic_download  " />
          </Badge>
        </div>

          )}

            <div className="social-card__description tw-text-secondary tw-p-6 tw-text-white">
              {
                name && <Paragraphs weight='regular' className='tw-text-white'>{name}</Paragraphs>
              }

            {manufacturer && <Paragraphs weight='light' className='tw-text-white tw-pt-3' size='xs'>{manufacturer}</Paragraphs>}
            </div>
          </div>

        </SocialCardContainer>
        </SocialBackgorund>

  )
}

SocialCard.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  manufacturer: PropTypes.string,
  isLogged: PropTypes.bool,
  onClickFav: PropTypes.func,
  isFav: PropTypes.bool,
  onClickDownload: PropTypes.func,
  visibleBadge: PropTypes.bool,
  type: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  hideBadge: PropTypes.bool
}
export default SocialCard
