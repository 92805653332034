import React from 'react'
import PropTypes from 'prop-types'
import Content from '../../../../components/Content'
import Paragraphs from '../../../../components/Paragraphs'
import { useTranslation } from 'react-i18next'
import { NewImageBackground } from './styles'

/**
 *@author ehernandez
 * @param {string} date
 * @param {string} title
 * @param {string} body
 * @returns
 */
const NewItem = ({ date, title, body, image, url, className }) => {
  const { t } = useTranslation()

  return (
    <Content
      className={`new-item tw-h-[500px] tw-mx-auto tw-cursor-pointer  tw-flex tw-flex-col tw-gap-y-4 tw-border-[1px] ${className} `}
      transition
      border = {false}
    >
      <NewImageBackground img={image} className="block-news__content__image  tw-h-[200px]  tw-overflow-hidden"/>
      <div className="block-news__content__description tw-p-2  tw-flex-col tw-gap-y-2 tw-flex tw-justify-between tw-flex-1">
        <div className="tw-mt-4 tw-gap-y-2 tw-flex tw-flex-col">
          <Paragraphs size="xxs" weight="bold" className="tw-text-primary">
            {date}
          </Paragraphs>
          <Paragraphs size="sm" weight="bold">
            {title}
          </Paragraphs>
          <div className="tw-max-h-[200px] tw-line-clamp-6 tw-text-xs" dangerouslySetInnerHTML={{ __html: body }} />
        </div>
        <a href={url} target='_blank' className="tw-mt-4" rel="noreferrer">
          <Paragraphs className='tw-text-primary tw-w-max tw-border-b-[1px] tw-border-primary' weight="medium" size="xs">
            {t('Leer más')}
          </Paragraphs>
        </a>
      </div>
    </Content>
  )
}

NewItem.propTypes = {
  date: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  className: PropTypes.string
}

export default NewItem
