import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import Paragraphs from '../Paragraphs'

const Modal = ({ id, title, children, onRequestClose }) => {
  const content = (
    <div
      id={id}
      className="Modal tw-p-8 lg:tw-p-0 tw-flex tw-items-center tw-justify-center tw-fixed tw-z-50 tw-bg-black tw-bg-opacity-30 tw-h-screen tw-w-screen tw-left-0 tw-top-0"
    >
      <div className="Modal__content tw-min-w-[300px]  tw-flex tw-flex-col  tw-rounded-lg tw-shadow-lg tw-relative tw-bg-white">
        <div className="Modal__content__title tw-flex tw-items-center tw-justify-between  tw-w-full">
          <Paragraphs weight="bold" size="xs" className="tw-p-2">
            {title}
          </Paragraphs>
          <div
            className="tw-p-2 tw-cursor-pointer tw-z-[9999] tw-absolute tw-right-0 tw-top-0"
            onClick={onRequestClose}
          >
            <span className="icon-ic_cerrar_grande" style={{ fontSize: 16 }} />
          </div>
        </div>
        <div className="Modal__content__body tw-px-3 tw-py-2">{children}</div>
      </div>
    </div>
  )

  return ReactDOM.createPortal(
    content,
    document.getElementById('modal-wrapper')
  )
}

Modal.propTypes = {
  id: PropTypes.string,
  // title = string or object
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
  onRequestClose: PropTypes.func
}

export default Modal
