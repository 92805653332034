import {
  SET_OPEN_LOGIN,
  SET_CLOSE_LOGIN,
  SET_OPEN_REGISTER,
  SET_ORDERBY
} from '../constants/layoutConstants'

const initialState = {
  modal: null,
  orderBy: ''
}

/**
 * @author shernandez
 * @param {object} state state of the application
 * @param {object} action action to be executed
 * @returns {object}
 */
const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OPEN_LOGIN:
      return {
        ...state,
        modal: 'login'
      }

    case SET_OPEN_REGISTER:
      return {
        ...state,
        modal: 'register'
      }
    case SET_CLOSE_LOGIN:
      return {
        ...state,
        modal: false
      }
    default:
      return state
  }
}

/**
 * @author ehernandez
 * @param {object} state state of the application
 * @param {object} action action to be executed
 * @returns {object}
 */
const orderByReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDERBY:
      return {
        ...state,
        orderBy: action
      }

    default:
      return state
  }
}

export { layoutReducer, orderByReducer }
